import React, { useEffect, useState } from 'react';
import './home.css';
import Sidebar from '../Sidebar/Sidebar';
import { FaUser, FaCog, FaChartLine, FaRegFileAlt } from 'react-icons/fa'; // Import icons from react-icons
import ganeshlogo from '../images/ganeshlogo.jpeg';
import axios from 'axios';

const OperatorDashboard = () => {
    const [users, setUsers] = useState([]);
    const [todaysBooking, setTodaysBooking] = useState(0);
    const [todaysAmount, setTodaysAmount] = useState(0);
    const [todaysBookingCount, setTodaysBookingCount] = useState(0);
    const [monthlyBookingCount, setMonthlyBookingCount] = useState(0);
    const [yearlyBookingCount, setYearlyBookingCount] = useState(0);
    const [todaysPurchase, setTodaysPurchase] = useState(0);
    const [monthlyPurchase, setMonthlyPurchase] = useState(0);
    const [yearlyPurchase, setYearlyPurchase] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 

    // API fetch functions
    const fetchTodaysBooking = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/todays-booking');
            setTodaysBooking(response.data.total);  // Set total amount for today's bookings
        } catch (err) {
            setError('Error fetching today\'s booking');
        }
    };

    const fetchTodaysBookingCount = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/count-todays-booking');
            setTodaysBookingCount(response.data.t); // Set today's booking count
        } catch (err) {
            setError('Error fetching today\'s booking count');
        }
    };

    const fetchMonthlyBookingCount = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/monthly-booking');
            setMonthlyBookingCount(response.data.tt); // Set total monthly bookings
        } catch (err) {
            setError('Error fetching monthly booking count');
        }
    };

    const fetchYearlyBookingCount = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/yearly-booking');
            setYearlyBookingCount(response.data.ttt); // Set total yearly bookings
        } catch (err) {
            setError('Error fetching yearly booking count');
        }
    };

    const fetchTodaysPurchase = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/todays-purchase');
            setTodaysPurchase(response.data.total); // Set today's total purchases
        } catch (err) {
            setError('Error fetching today\'s purchase');
        }
    };

    const fetchMonthlyPurchase = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/monthly-purchase');
            setMonthlyPurchase(response.data.mp); // Set monthly purchases
        } catch (err) {
            setError('Error fetching monthly purchase');
        }
    };

    const fetchYearlyPurchase = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/yearly-purchase');
            setYearlyPurchase(response.data.yp); // Set yearly purchases
        } catch (err) {
            setError('Error fetching yearly purchase');
        }
    };

    const fetchTotalProducts = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/total-products');
            setTotalProducts(response.data.t); // Set total products
        } catch (err) {
            setError('Error fetching total products');
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/users');
            setUsers(response.data); // Assuming this returns the user data
        } catch (err) {
            setError('Error fetching users');
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchTodaysBooking();
        fetchTodaysBookingCount();
        fetchMonthlyBookingCount();
        fetchYearlyBookingCount();
        fetchTodaysPurchase();
        fetchMonthlyPurchase();
        fetchYearlyPurchase();
        fetchTotalProducts();
        fetchUsers();
        setLoading(false); // Ensure loading state is stopped after fetching all data
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <>
            <Sidebar />
            <div id="main">
                <div className="dashboard-container">
                    <div className="left-cards">
                        <div className="card">
                            <FaUser className="icon" style={{ fontSize: '40px', color: '#4CAF50', marginBottom: '5px' }} />
                            <h3 style={{ fontSize: '1.2em', margin: '10px 0' }}>Today's Booking</h3>
                           
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL BOOKINGS OF THE MONTH: {monthlyBookingCount}</p>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL BOOKINGS OF THE YEAR: {yearlyBookingCount}</p>
                        </div>

                       
                    </div>

                    <div className="image-container">
                        <img src={ganeshlogo} alt="Dashboard Image" className="dashboard-image" />
                    </div>

                    <div className="right-cards">
                        <div className="card">
                            <FaCog className="icon" style={{ fontSize: '40px', color: '#4CAF50', marginBottom: '5px' }} />
                            <h3 style={{ fontSize: '1.2em', margin: '10px 0' }}>Total Today's Purchase</h3>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL: {todaysPurchase}</p>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL PURCHASES OF THE MONTH: {monthlyPurchase}</p>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL PURCHASES OF THE YEAR: {yearlyPurchase}</p>
                        </div>

                     
                    </div>
                </div>
            </div>
        </>
    );
};

export default OperatorDashboard;


