// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Table, Button, Modal, Form } from 'react-bootstrap';
// import Sidebar from '../Sidebar/Sidebar'
// const PoojaManagement = () => {
//   const [poojas, setPoojas] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [modalData, setModalData] = useState({ id: '', pname: '', pprice: '', pduration: '' });
//   const [newPooja, setNewPooja] = useState({ pname: '', pprice: '', pduration: '' });
//   const [message, setMessage] = useState('');

//   // Fetch Poojas
//   useEffect(() => {
//     axios.get('https://posapi.emedha.in/api/pooja')
//       .then((response) => setPoojas(response.data))
//       .catch((error) => console.error('Error fetching poojas:', error));
//   }, []);

//   // Handle input change for new pooja
//   const handleNewPoojaChange = (e) => {
//     const { name, value } = e.target;
//     setNewPooja((prevState) => ({ ...prevState, [name]: value }));
//   };

//   // Handle input change for modal
//   const handleModalChange = (e) => {
//     const { name, value } = e.target;
//     setModalData((prevState) => ({ ...prevState, [name]: value }));
//   };

//   // Submit new pooja
//   const handleSubmitNewPooja = (e) => {
//     e.preventDefault();
//     axios.post('https://posapi.emedha.in/api/pooja', newPooja)
//       .then((response) => {
//         setMessage(response.data.message);
//         setNewPooja({ pname: '', pprice: '', pduration: '' }); // Clear form
//         axios.get('https://posapi.emedha.in/api/pooja')
//           .then((response) => setPoojas(response.data))
//           .catch((error) => console.error('Error fetching poojas:', error));
//       })
//       .catch((error) => console.error('Error adding pooja:', error));
//   };

//   // Handle update pooja
//   const handleUpdatePooja = (e) => {
//     e.preventDefault();
//     axios.put(`https://posapi.emedha.in/api/pooja/${modalData.id}`, modalData)
//       .then((response) => {
//         setMessage(response.data.message);
//         setShowModal(false);
//         axios.get('https://posapi.emedha.in/api/pooja')
//           .then((response) => setPoojas(response.data))
//           .catch((error) => console.error('Error fetching poojas:', error));
//       })
//       .catch((error) => console.error('Error updating pooja:', error));
//   };

//   // Handle delete pooja
//   const handleDeletePooja = (id) => {
//     if (window.confirm('Do you want to delete this pooja?')) {
//       axios.delete(`https://posapi.emedha.in/api/pooja/${id}`)
//         .then((response) => {
//           setMessage(response.data.message);
//           axios.get('https://posapi.emedha.in/api/pooja')
//             .then((response) => setPoojas(response.data))
//             .catch((error) => console.error('Error fetching poojas:', error));
//         })
//         .catch((error) => console.error('Error deleting pooja:', error));
//     }
//   };

//   // Open modal to edit pooja
//   const openEditModal = (pooja) => {
//     setModalData(pooja);
//     setShowModal(true);
//   };

//   return (
//     <>
//      <Sidebar />
//      <div id="main">
//     <div className="container">
//       <h2>Manage Pooja</h2>

//       {/* Display Success/Error Message */}
//       {message && <div className="alert alert-info">{message}</div>}

//       {/* Create New Pooja */}
//       <form onSubmit={handleSubmitNewPooja}>
//         <Form.Group>
//           <Form.Label>Pooja Name</Form.Label>
//           <Form.Control type="text" name="pname" value={newPooja.pname} onChange={handleNewPoojaChange} required />
//         </Form.Group>
//         <Form.Group>
//           <Form.Label>Price</Form.Label>
//           <Form.Control type="text" name="pprice" value={newPooja.pprice} onChange={handleNewPoojaChange} required />
//         </Form.Group>
//         <Form.Group>
//           <Form.Label>Duration</Form.Label>
//           <Form.Control type="text" name="pduration" value={newPooja.pduration} onChange={handleNewPoojaChange} required />
//         </Form.Group>
//         <Button type="submit">Create Pooja</Button>
//       </form>

//       {/* Pooja Table */}
//       <Table striped bordered hover>
//         <thead>
//           <tr>
//             <th>S.No</th>
//             <th>Pooja Name</th>
//             <th>Price</th>
//             <th>Duration</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {poojas.map((pooja, index) => (
//             <tr key={pooja.pid}>
//               <td>{index + 1}</td>
//               <td>{pooja.pname}</td>
//               <td>{pooja.pprice}</td>
//               <td>{pooja.pduration}</td>
//               <td>
//                 <Button onClick={() => openEditModal(pooja)}>Edit</Button>
//                 <Button onClick={() => handleDeletePooja(pooja.pid)} variant="danger">Delete</Button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Edit Pooja Modal */}
//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Edit Pooja</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleUpdatePooja}>
//             <Form.Group>
//               <Form.Label>Pooja Name</Form.Label>
//               <Form.Control type="text" name="pname" value={modalData.pname} onChange={handleModalChange} required />
//             </Form.Group>
//             <Form.Group>
//               <Form.Label>Price</Form.Label>
//               <Form.Control type="text" name="pprice" value={modalData.pprice} onChange={handleModalChange} required />
//             </Form.Group>
//             <Form.Group>
//               <Form.Label>Duration</Form.Label>
//               <Form.Control type="text" name="pduration" value={modalData.pduration} onChange={handleModalChange} required />
//             </Form.Group>
//             <Button type="submit">Update Pooja</Button>
//           </Form>
//         </Modal.Body>
//       </Modal>
//     </div>
//     </div>
//     </>
//   );
// };

// export default PoojaManagement;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Pagination, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import { FaOm } from "react-icons/fa6";
import { LuTextSearch } from "react-icons/lu";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { GiDuration } from "react-icons/gi";



const PoojaManagement = () => {
  const [poojas, setPoojas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ id: '', pname: '', pprice: '', pduration: '' });
  const [newPooja, setNewPooja] = useState({ pname: '', pprice: '', pduration: '' });
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');  // Add search state

  // Fetch Poojas
  useEffect(() => {
    axios.get('https://posapi.emedha.in/api/pooja')
      .then((response) => setPoojas(response.data))
      .catch((error) => console.error('Error fetching poojas:', error));
  }, []);

  // Handle input change for new pooja
  const handleNewPoojaChange = (e) => {
    const { name, value } = e.target;
    setNewPooja((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle input change for modal
  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Submit new pooja
  const handleSubmitNewPooja = (e) => {
    e.preventDefault();
    axios.post('https://posapi.emedha.in/api/pooja', newPooja)
      .then((response) => {
        setMessage(response.data.message);
        setNewPooja({ pname: '', pprice: '', pduration: '' });
        axios.get('https://posapi.emedha.in/api/pooja')
          .then((response) => setPoojas(response.data))
          .catch((error) => console.error('Error fetching poojas:', error));
      })
      .catch((error) => console.error('Error adding pooja:', error));
  };

  // Handle update pooja
  const handleUpdatePooja = (e) => {
    e.preventDefault();
    axios.put(`https://posapi.emedha.in/api/pooja/${modalData.pid}`, modalData)
      .then((response) => {
        setMessage(response.data.message);
        setShowModal(false);
        axios.get('https://posapi.emedha.in/api/pooja')
          .then((response) => setPoojas(response.data))
          .catch((error) => console.error('Error fetching poojas:', error));
      })
      .catch((error) => console.error('Error updating pooja:', error));
  };

  // Handle delete pooja
  const handleDeletePooja = (id) => {
    if (window.confirm('Do you want to delete this pooja?')) {
      axios.delete(`https://posapi.emedha.in/api/pooja/${id}`)
        .then((response) => {
          setMessage(response.data.message);
          axios.get('https://posapi.emedha.in/api/pooja')
            .then((response) => setPoojas(response.data))
            .catch((error) => console.error('Error fetching poojas:', error));
        })
        .catch((error) => console.error('Error deleting pooja:', error));
    }
  };

  // Open modal to edit pooja
  const openEditModal = (pooja) => {
    setModalData(pooja);
    setShowModal(true);
  };

  // Handle pagination logic
  const totalPages = Math.ceil(poojas.length / itemsPerPage);
  const indexOfLastPooja = currentPage * itemsPerPage;
  const indexOfFirstPooja = indexOfLastPooja - itemsPerPage;

  // Filter poojas based on search query
  const filteredPoojas = poojas.filter(pooja => {
    const query = searchQuery.toLowerCase();
    return (
      pooja.pname.toLowerCase().includes(query)||pooja.pprice.toLowerCase().includes(query)||pooja.pduration.toLowerCase().includes(query)
    );
  });

  const currentPoojas = filteredPoojas.slice(indexOfFirstPooja, indexOfLastPooja);

  // Change items per page
  const handleItemsPerPageChange = (num) => {
    setItemsPerPage(num);
    setCurrentPage(1); // Reset to first page
  };

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Sidebar />
      <div id="main">
        <div className="container">
          <h2>Manage Pooja</h2>

          {/* Display Success/Error Message */}
          {message && <div className="alert alert-info">{message}</div>}

          {/* Search Bar */}
          <InputGroup className="mb-3">
            <InputGroup.Text> <LuTextSearch style={{margin:'5px'}}/> Search</InputGroup.Text>
            <FormControl
              placeholder="Search Pooja Name, Price, Duration"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>

          {/* Create New Pooja */}
          <form onSubmit={handleSubmitNewPooja}>
            <Form.Group>
              <Form.Label>Pooja Name  <MdOutlineDriveFileRenameOutline/></Form.Label>
              <Form.Control type="text" name="pname" value={newPooja.pname} onChange={handleNewPoojaChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Price  <RiMoneyRupeeCircleLine/></Form.Label>
              <Form.Control type="text" name="pprice" value={newPooja.pprice} onChange={handleNewPoojaChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Duration  <GiDuration/></Form.Label>
              <Form.Control type="text" name="pduration" value={newPooja.pduration} onChange={handleNewPoojaChange} required />
            </Form.Group>
            <Button type="submit">Create Pooja</Button>
          </form>

          {/* Items per Page Dropdown */}
          <div className="d-flex justify-content-end my-3">
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Items per page: {itemsPerPage}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleItemsPerPageChange(5)}>5</Dropdown.Item>
                <Dropdown.Item onClick={() => handleItemsPerPageChange(10)}>10</Dropdown.Item>
                <Dropdown.Item onClick={() => handleItemsPerPageChange(20)}>20</Dropdown.Item>
                <Dropdown.Item onClick={() => handleItemsPerPageChange(30)}>30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* Pooja Table */}
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Pooja Name</th>
                <th>Price</th>
                <th>Duration</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentPoojas.map((pooja, index) => (
                <tr key={pooja.pid}>
                 <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                  <td>{pooja.pname}</td>
                  <td>{pooja.pprice}</td>
                  <td>{pooja.pduration}</td>
                  <td>
                    <Button onClick={() => openEditModal(pooja)}>Edit</Button>
                    <Button onClick={() => handleDeletePooja(pooja.pid)} variant="danger">Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination */}
          <Pagination>
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>

          {/* Edit Pooja Modal */}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Pooja</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleUpdatePooja}>
                <Form.Group>
                  <Form.Label>Pooja Name</Form.Label>
                  <Form.Control type="text" name="pname" value={modalData.pname} onChange={handleModalChange} required />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Price</Form.Label>
                  <Form.Control type="text" name="pprice" value={modalData.pprice} onChange={handleModalChange} required />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Duration</Form.Label>
                  <Form.Control type="text" name="pduration" value={modalData.pduration} onChange={handleModalChange} required />
                </Form.Group>
                <Button type="submit">Update Pooja</Button>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default PoojaManagement;
