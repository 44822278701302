// import React, { useState } from 'react';
// import axios from 'axios';
// import { Button, Form, Container } from 'react-bootstrap';
// import swal from 'sweetalert';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Sidebar from '../Sidebar/Sidebar';


// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//   const handleLogin = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post('http://localhost:5000/login', { username, password });
//       const { user_id, username, fullname, role } = response.data;

//       // Store user data in session or context
//       sessionStorage.setItem('user_id', user_id);
//       sessionStorage.setItem('username', username);
//       sessionStorage.setItem('fullname', fullname);
//       sessionStorage.setItem('role', role);

//       swal('Login Success', `Welcome ${role}`, 'success');

//       // Redirect to dashboard
//       window.location.href = '/home';  // Assuming you have a dashboard route
//     } catch (error) {
//       setErrorMessage(error.response ? error.response.data.message : 'Server error');
//       swal('Login Fail', errorMessage, 'error');
//     }
//   };

//   return (

//     <>

//     <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
//       <div className="login-box">
//         <div className="login-logo text-center">
//           <img src="img/colourlogo.jpeg" width="50%" alt="Logo" />
//         </div>
//         <div className="login-box-body">
//           <h3 className="login-box-msg">Admin Sales Login</h3>

//           <Form onSubmit={handleLogin}>
//             <Form.Group controlId="username">
//               <Form.Control
//                 type="text"
//                 placeholder="Username"
//                 value={username}
//                 onChange={(e) => setUsername(e.target.value)}
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId="password">
//               <Form.Control
//                 type="password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 required
//               />
//             </Form.Group>

//             <Button variant="primary" type="submit" block>
//               Log In
//             </Button>
//           </Form>
//         </div>
//       </div>
//     </Container>




//     </>
//   );
// };

// export default Login;


// import React, { useState } from 'react';
// import { Container, Card, Form, Button, Row, Col } from 'react-bootstrap';
// import axios from 'axios';
// import swal from 'sweetalert2';
// import './Login.css'
// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//   const handleLogin = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post('https://posapi.emedha.in/api/login', { username, password });

//       const { user_id, username, fullname, role } = response.data;

//       // Store user data in session storage (or use a context)  https://posapi.emedha.in
//       sessionStorage.setItem('user_id', user_id);
//       sessionStorage.setItem('username', username);
//       sessionStorage.setItem('fullname', fullname);
//       sessionStorage.setItem('role', role);

//       swal.fire({
//         icon: 'success',
//         title: 'Login Success',
//         text: `Welcome ${role}`,
//       }).then(() => {
//         // Redirect to dashboard (or handle with react-router)
//         window.location.href = '/home';
//       });
//     } catch (error) {
//       setErrorMessage(error.response ? error.response.data.message : 'Server error');
//       swal.fire({
//         icon: 'error',
//         title: 'Login Failed',
//         text: errorMessage,
//       });
//     }
//   };

//   return (
//     <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
//       <Card className="login-card" style={{ width: '25rem' }}>
//         <Card.Body>
//           <div className="login-logo text-center">
//             <img src="img/colourlogo.jpeg" width="50%" alt="Logo" />
//           </div>
//           <h3 className="login-box-msg text-center">Admin Sales Login</h3>

//           <Form onSubmit={handleLogin}>
//             <Form.Group controlId="username">
//               <Form.Control
//                 type="text"
//                 placeholder="Username"
//                 value={username}
//                 onChange={(e) => setUsername(e.target.value)}
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId="password">
//               <Form.Control
//                 type="password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 required
//               />
//             </Form.Group>

//             <Row>
//               <Col>
//                 <Button variant="primary" type="submit" block>
//                   Log In
//                 </Button>
//               </Col>
//             </Row>
//           </Form>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default Login;



// import React, { useState } from 'react';
// import { Container, Card, Form, Button, Row, Col } from 'react-bootstrap';
// import axios from 'axios';
// import swal from 'sweetalert2';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
// import './Login.css';

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');
//   const navigate = useNavigate(); // Initialize useNavigate

//   // const handleLogin = async (e) => {
//   //   e.preventDefault();

//   //   try {
//   //     const response = await axios.post('https://posapi.emedha.in/api/login', { username, password });

//   //     const { user_id, username, fullname, role } = response.data;

//   //     // Store user data in session storage
//   //     sessionStorage.setItem('user_id', user_id);
//   //     sessionStorage.setItem('username', username);
//   //     sessionStorage.setItem('fullname', fullname);
//   //     sessionStorage.setItem('role', role);

//   //     swal.fire({
//   //       icon: 'success',
//   //       title: 'Login Success',
//   //       text: `Welcome ${role}`,
//   //     }).then(() => {
//   //       // Redirect to the home page after successful login
//   //       navigate('/');
//   //     });
//   //   } catch (error) {
//   //     setErrorMessage(error.response ? error.response.data.message : 'Server error');
//   //     swal.fire({
//   //       icon: 'error',
//   //       title: 'Login Failed',
//   //       text: errorMessage,
//   //     });
//   //   }
//   // };


//   const handleLogin = async (e) => {
//     e.preventDefault();

//     try {
//         // Send login request to backend
//         const response = await axios.post('https://posapi.emedha.in/api/login', {
//             username,
//             password
//         });

//         const { user_id, username, fullname, role } = response.data;

//         // Store session data in sessionStorage
//         sessionStorage.setItem('user_id', user_id);
//         sessionStorage.setItem('username', username);
//         sessionStorage.setItem('fullname', fullname);
//         sessionStorage.setItem('role', role);

//         // Show success message and navigate to dashboard
//         swal.fire({
//             icon: 'success',
//             title: 'Login Success',
//             text: `Welcome ${role}`,
//         }).then(() => {
//             navigate('/home'); // or the route you want to navigate to after login
//         });
//     } catch (error) {
//         console.error("Login error:", error); // Log the full error object to inspect it

//         // Check if the error is from the response and has the expected structure
//         const message = error.response && error.response.data && error.response.data.message
//             ? error.response.data.message
//             : error.message || 'An unexpected error occurred. Please try again later.';

//         setErrorMessage(message);

//         // Show the error to the user using SweetAlert
//         swal.fire({
//             icon: 'error',
//             title: 'Login Failed',
//             text: message,
//         });
//     }
// };



//   return (
//     <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
//       <Card className="login-card" style={{ width: '25rem' }}>
//         <Card.Body>
//           <div className="login-logo text-center">
//             <img src="img/colourlogo.jpeg" width="50%" alt="Logo" />
//           </div>
//           <h3 className="login-box-msg text-center">Admin Sales Login</h3>

//           <Form onSubmit={handleLogin}>
//             <Form.Group controlId="username">
//               <Form.Control
//                 type="text"
//                 placeholder="Username"
//                 value={username}
//                 onChange={(e) => setUsername(e.target.value)}
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId="password">
//               <Form.Control
//                 type="password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 required
//               />
//             </Form.Group>

//             <Row>
//               <Col>
//                 <Button variant="primary" type="submit" block>
//                   Log In
//                 </Button>
//               </Col>
//             </Row>
//           </Form>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default Login;



// import React, { useState } from 'react';
// import axios from 'axios';
// import swal from 'sweetalert';
// import { Card, Form, Button, Container, Row, Col } from 'react-bootstrap';
// import { FaUser, FaLock } from 'react-icons/fa';  // Import React Icons (FaUser for username, FaLock for password)
// import './Login.css'; // Custom CSS for the additional styling
// import Ganesh from '../images/ganeshlogo.jpeg'


// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [loading, setLoading] = useState(false);

//   // Handle login form submission
//   const handleLogin = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       const response = await axios.post('https://posapi.emedha.in/api/login', { username, password });

//       if (response.data && response.data.username) {
//         sessionStorage.setItem('user_id', response.data.user_id);
//         sessionStorage.setItem('username', response.data.username);
//         sessionStorage.setItem('role', response.data.role);
        
//         swal('Login Success', `Welcome ${response.data.role}`, 'success')
//           .then(() => window.location.href = '/home');
//       } else {
//         swal('Login Failed', 'Username or Password is Incorrect', 'error');
//       }
//     } catch (error) {
//       swal('Login Failed', 'An error occurred during login', 'error');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Container fluid className="d-flex justify-content-center align-items-center full-height">
//       {/* <Row className="w-100">
//         <Col xs={12} sm={8} md={6} lg={4} className="d-flex justify-content-center"> */}
//           <Card className="shadow-lg rounded-lg border-0 w-100">
//             <Card.Body className="p-5">
//               <div className="text-center mb-4">
//                 <img width="60%" src={Ganesh} alt="Logo" />
//               </div>
//               <h4 className="text-center mb-4 font-weight-bold">Admin Sales Login</h4>
//               <Form onSubmit={handleLogin}>
//                 <Form.Group controlId="formUsername">
//                   <div className="input-group">
//                     <div className="input-group-prepend">
//                       <span className="input-group-text">
//                         <FaUser />
//                       </span>
//                     </div>
//                     <Form.Control 
//                       type="text" 
//                       placeholder="Username" 
//                       value={username} 
//                       onChange={(e) => setUsername(e.target.value)} 
//                       required 
//                       className="input-field"
//                     />
//                   </div>
//                 </Form.Group>

//                 <Form.Group controlId="formPassword">
//                   <div className="input-group">
//                     <div className="input-group-prepend">
//                       <span className="input-group-text">
//                         <FaLock />
//                       </span>
//                     </div>
//                     <Form.Control 
//                       type="password" 
//                       placeholder="Password" 
//                       value={password} 
//                       onChange={(e) => setPassword(e.target.value)} 
//                       required 
//                       className="input-field"
//                     />
//                   </div>
//                 </Form.Group>

//                 <Button 
//                   variant="primary" 
//                   type="submit" 
//                   block 
//                   disabled={loading} 
//                   className="btn-login"
//                 >
//                   {loading ? 'Logging in...' : 'Log In'}
//                 </Button>
//               </Form>
//             </Card.Body>
//           </Card>
//         {/* </Col>
//       </Row> */}
//     </Container>
//   );
// };

// export default Login;




import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Card, Form, Button, Container, Row, Col } from 'react-bootstrap';
import { FaUser, FaLock } from 'react-icons/fa';  // Import React Icons (FaUser for username, FaLock for password)
import Ganesh from '../images/ganeshlogo.jpeg'
import { useNavigate } from 'react-router-dom';


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // Handle login form submission
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('https://posapi.emedha.in/api/login', { username, password });

      if (response.data && response.data.username) {
        sessionStorage.setItem('user_id', response.data.user_id);
        sessionStorage.setItem('username', response.data.username);
        sessionStorage.setItem('role', response.data.role);
        
        swal('Login Success', `Welcome ${response.data.role}`, 'success')
          .then(() => {
            if (response.data.role === "Admin") {
              navigate('/home');  // Redirect Admin to /home
            } else if (response.data.role === "Operator") {
              navigate('/operatorDashboard');  // Redirect Operator to /operatorDashboard
            }
          });
      } else {
        swal('Login Failed', 'Username or Password is Incorrect', 'error');
      }
    } catch (error) {
      swal('Login Failed', 'An error occurred during login', 'error');
    } finally {
      setLoading(false);
    }
  };
  console.log('this is a username ' , username);
  console.log('this is a password ' , password );

  return (
    <Container
      fluid
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f7f7f7', // Light background color
      }}
    >
      <Card
        className="shadow-lg rounded-lg border-0 w-100"
        style={{
          maxWidth: '400px',
          width: '100%', // Ensures card takes full width on small screens
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slight transparency
          borderRadius: '10px',
        }}
      >
        <Card.Body
          style={{
            padding: '30px',
          }}
        >
          <div className="text-center mb-4">
            <img width="60%" src={Ganesh} alt="Logo" style={{ borderRadius: '50%', maxWidth: '100%', height: 'auto' }} />
          </div>
          <h4
            className="text-center mb-4 font-weight-bold"
            style={{
              fontSize: '1.5rem',
              color: '#333',
              fontWeight: 'bold',
            }}
          >
            Admin Sales Login
          </h4>
          <Form onSubmit={handleLogin}>
            <Form.Group controlId="formUsername">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{
                      backgroundColor: '#007bff',
                      borderRadius: '50%',
                      color: 'white',
                      padding: '10px',
                      fontSize: '1.2rem',
                    }}
                  >
                    <FaUser />
                  </span>
                </div>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  style={{
                    borderRadius: '25px',
                    padding: '15px',
                    fontSize: '1rem',
                    marginBottom: '15px',
                    border: '1px solid #ccc',
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="formPassword">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{
                      backgroundColor: '#007bff',
                      borderRadius: '50%',
                      color: 'white',
                      padding: '10px',
                      fontSize: '1.2rem',
                    }}
                  >
                    <FaLock />
                  </span>
                </div>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{
                    borderRadius: '25px',
                    padding: '15px',
                    fontSize: '1rem',
                    marginBottom: '15px',
                    border: '1px solid #ccc',
                  }}
                />
              </div>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              block
              disabled={loading}
              style={{
                backgroundColor: '#007bff',
                border: 'none',
                borderRadius: '25px',
                padding: '15px',
                fontSize: '1.1rem',
                textTransform: 'uppercase',
              }}
            >
              {loading ? 'Logging in...' : 'Log In'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Login;
