// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Table, Container, Row, Col, Card } from 'react-bootstrap';
// import Sidebar from '../Sidebar/Sidebar';


// const ViewBooking = () => {
//   const [bookings, setBookings] = useState([]);

//   useEffect(() => {
//     // Fetch booking data from the Express backend
//     axios.get('https://posapi.emedha.in/api/api/bookings')
//       .then(response => {
//         setBookings(response.data);
//       })
//       .catch(error => {
//         console.error('There was an error fetching the bookings:', error);
//       });
//   }, []);

//   return (
//     <>
//     <Sidebar/>
//     <div id='main'>
//     <div className="content-wrapper">
//       <section className="content-header">
//         <h1>View Bookings</h1>
//         <hr />
//       </section>

//       <section className="content container-fluid">
//         <Card className="box box-success">
//           <Card.Header>
//             <h3 className="box-title">View Booking Details</h3>
//           </Card.Header>

//           <Card.Body>
//             <Container fluid>
//               <Row>
//                 <Col>
//                   <Table responsive striped bordered hover>
//                     <thead>
//                       <tr>
//                         <th>S.No</th>
//                         <th>Mobile Number</th>
//                         <th>Customer Name</th>
//                         <th>Gothram</th>
//                         <th>Amount</th>
//                         <th>Pooja Name</th>
//                         <th>Pooja Date</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {bookings.map((row, index) => (
//                         <tr key={row.sno}>
//                           <td>{index + 1}</td>
//                           <td>{row.mobileNumber}</td>
//                           <td>{row.customerName}</td>
//                           <td>{row.gothram}</td>
//                           <td>{row.amount}</td>
//                           <td>{row.pid}</td>
//                           <td>{row.poojadate}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </Table>
//                 </Col>
//               </Row>
//             </Container>
//           </Card.Body>
//         </Card>
//       </section>
//     </div>
//     </div>
//     </>
//   );
// }

// export default ViewBooking;



// import React, { useState, useEffect } from 'react';
// import { Table, Container, Row, Col, Card, Pagination, Dropdown, ButtonGroup } from 'react-bootstrap';
// import Sidebar from '../Sidebar/Sidebar';
// import axios from 'axios';

// const ViewBooking = () => {
//   const [bookings, setBookings] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);

// //   useEffect(() => {
// //     // Simulate fetching booking data (replace this with your actual data fetching)
// //     const fetchData = async () => {
// //       // For now, assuming `bookings` contains an array of 100+ records.
// //       const dummyBookings = Array.from({ length: 100 }, (_, index) => ({
// //         sno: index + 1,
// //         mobileNumber: '1234567890',
// //         customerName: `Customer ${index + 1}`,
// //         gothram: 'GothramName',
// //         amount: Math.floor(Math.random() * 1000) + 1,
// //         pid: `Pooja-${index + 1}`,
// //         poojadate: `2025-01-${index + 1}`,
// //       }));
// //       setBookings(dummyBookings);
// //     };
    
// //     fetchData();
// //   }, []);

// useEffect(() => {
//     // Fetch booking data from the Express backend
//     axios.get('https://posapi.emedha.in/api/api/bookings')
//       .then(response => {
//         setBookings(response.data);
//       })
//       .catch(error => {
//         console.error('There was an error fetching the bookings:', error);
//       });
//   }, []);

//   // Handle page size change (10, 20, 30 records per page)
//   const handlePageSizeChange = (newSize) => {
//     setPageSize(newSize);
//     setCurrentPage(1);  // Reset to the first page when page size changes
//   };

//   // Calculate the index range for current page
//   const indexOfLastBooking = currentPage * pageSize;
//   const indexOfFirstBooking = indexOfLastBooking - pageSize;
//   const currentBookings = bookings.slice(indexOfFirstBooking, indexOfLastBooking);

//   // Pagination logic
//   const totalPages = Math.ceil(bookings.length / pageSize);
//   const pageNumbers = [];
//   for (let i = 1; i <= totalPages; i++) {
//     pageNumbers.push(i);
//   }

//   // Handle page change
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <>
//       <Sidebar />
//       <div id="main">
//         <div className="content-wrapper">
//           <section className="content-header">
//             <h1>View Bookings</h1>
//             <hr />
//           </section>

//           <section >
          
            

//               <Card.Body>
//                 <Container >
//                   <Row>
//                     <Col className="d-flex justify-content-end align-items-center">
//                       {/* Dropdown Button for "Show Entries" */}
//                       <Dropdown as={ButtonGroup}>
//                         <ButtonGroup>
//                           <Dropdown.Toggle variant="secondary" size="sm">
//                             Show Entries
//                           </Dropdown.Toggle>
//                           <Dropdown.Menu>
//                             <Dropdown.Item onClick={() => handlePageSizeChange(5)}>5</Dropdown.Item>
//                             <Dropdown.Item onClick={() => handlePageSizeChange(10)}>10</Dropdown.Item>
//                             <Dropdown.Item onClick={() => handlePageSizeChange(20)}>20</Dropdown.Item>
//                             <Dropdown.Item onClick={() => handlePageSizeChange(30)}>30</Dropdown.Item>
//                             <Dropdown.Item onClick={() => handlePageSizeChange(40)}>40</Dropdown.Item>
//                           </Dropdown.Menu>
//                         </ButtonGroup>
//                       </Dropdown>
//                     </Col>
//                   </Row>

//                   {/* Booking Table */}
//                   <Row>
//                     <Col>
//                       <Table responsive striped bordered hover>
//                         <thead>
//                           <tr>
//                             <th>S.No</th>
//                             <th>Mobile Number</th>
//                             <th>Customer Name</th>
//                             <th>Gothram</th>
//                             <th>Amount</th>
//                             <th>Pooja Name</th>
//                             <th>Pooja Date</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {currentBookings.map((row, index) => (
//                             <tr key={row.sno}>
//                               <td>{indexOfFirstBooking + (index + 1)}</td>
//                               <td>{row.mobileNumber}</td>
//                               <td>{row.customerName}</td>
//                               <td>{row.gothram}</td>
//                               <td>{row.amount}</td>
//                               <td>{row.pid}</td>
//                               <td>{row.poojadate}</td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </Table>

//                       {/* Pagination */}
//                       <Pagination>
//                         <Pagination.Prev
//                           onClick={() => handlePageChange(currentPage - 1)}
//                           disabled={currentPage === 1}
//                         />
//                         {pageNumbers.map(number => (
//                           <Pagination.Item
//                             key={number}
//                             active={number === currentPage}
//                             onClick={() => handlePageChange(number)}
//                           >
//                             {number}
//                           </Pagination.Item>
//                         ))}
//                         <Pagination.Next
//                           onClick={() => handlePageChange(currentPage + 1)}
//                           disabled={currentPage === totalPages}
//                         />
//                       </Pagination>
//                     </Col>
//                   </Row>
//                 </Container>
//               </Card.Body>
          
//           </section>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ViewBooking;




import React, { useState, useEffect } from 'react';
import { Table, Container, Row, Col, Card, Pagination, Dropdown, ButtonGroup } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import axios from 'axios';

const ViewBooking = () => {
  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    // Fetch booking data from the Express backend
    axios.get('https://posapi.emedha.in/api/api/bookings')
      .then(response => {
        setBookings(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the bookings:', error);
      });
  }, []);

  // Handle page size change (10, 20, 30 records per page)
  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1);  // Reset to the first page when page size changes
  };

  // Calculate the index range for current page
  const indexOfLastBooking = currentPage * pageSize;
  const indexOfFirstBooking = indexOfLastBooking - pageSize;
  const currentBookings = bookings.slice(indexOfFirstBooking, indexOfLastBooking);

  // Pagination logic
  const totalPages = Math.ceil(bookings.length / pageSize);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // Group the pages into batches and add ellipsis if necessary
  const getPaginationGroup = () => {
    const pageGroupSize = 5;
    let start = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize;
    let end = start + pageGroupSize;

    if (end >= totalPages) {
      end = totalPages;
    }

    return pageNumbers.slice(start, end);
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Format date as YYYY-MM-DD
  const formatDate = (dateString) => {
    const date = new Date(dateString); // Create Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Format month (01-12)
    const day = String(date.getDate()).padStart(2, '0'); // Format day (01-31)
    return `${year}-${month}-${day}`; // Return as YYYY-MM-DD
  };
  return (
    <>
      <Sidebar />
      <div id="main">
        <div className="content-wrapper">
          <section className="content-header">
            <h1>View Bookings</h1>
            <hr />
          </section>

          <section className="content container-fluid">
          

              <Card.Body>
                <Container fluid>
                  <Row>
                    <Col className="d-flex justify-content-end align-items-center">
                      {/* Dropdown Button for "Show Entries" */}
                      <Dropdown as={ButtonGroup}>
                        <ButtonGroup>
                          <Dropdown.Toggle variant="secondary" size="sm">
                            Show Entries
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handlePageSizeChange(10)}>10</Dropdown.Item>
                            <Dropdown.Item onClick={() => handlePageSizeChange(20)}>20</Dropdown.Item>
                            <Dropdown.Item onClick={() => handlePageSizeChange(30)}>30</Dropdown.Item>
                          </Dropdown.Menu>
                        </ButtonGroup>
                      </Dropdown>
                    </Col>
                  </Row>

                  {/* Booking Table */}
                  <Row>
                    <Col>
                      <Table responsive striped bordered hover>
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Mobile Number</th>
                            <th>Customer Name</th>
                            <th>Gothram</th>
                            <th>Amount</th>
                            <th>Pooja Name</th>
                            <th>Pooja Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentBookings.map((row, index) => (
                            <tr key={row.sno}>
                              <td>{indexOfFirstBooking + (index + 1)}</td>
                              <td>{row.mobileNumber}</td>
                              <td>{row.customerName}</td>
                              <td>{row.gothram}</td>
                              <td>{row.amount}</td>
                              <td>{row.poojaname}</td>
                              <td>{formatDate(row.poojadate)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      {/* Pagination */}
                      <Pagination>
                        <Pagination.Prev
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        />

                        {/* Conditionally render pagination items */}
                        {getPaginationGroup().map((number) => (
                          <Pagination.Item
                            key={number}
                            active={number === currentPage}
                            onClick={() => handlePageChange(number)}
                          >
                            {number}
                          </Pagination.Item>
                        ))}

                        {/* Ellipsis if there are more pages to skip */}
                        {totalPages > 5 && currentPage < totalPages - 1 && (
                          <Pagination.Ellipsis />
                        )}

                        <Pagination.Next
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        />
                      </Pagination>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
           
          </section>
        </div>
      </div>
    </>
  );
};

export default ViewBooking;
