// src/Components/ProtectedRoute.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = sessionStorage.getItem('user_id'); // Check if user is logged in (stored in sessionStorage)

  return isAuthenticated ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
