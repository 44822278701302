// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Sidebar from '../Sidebar/Sidebar';


// //     const [purchaseDetail, setPurchaseDetail] = useState(null);
// //     const billNo = match.params.billNo;

// //     useEffect(() => {
// //         // Fetch the purchase details from the API based on billNo
// //         axios.get(`https://posapi.emedha.in/api/api/purchases/${billNo}`)
// //             .then(response => {
// //                 setPurchaseDetail(response.data);
// //             })
// //             .catch(error => console.error('Error fetching purchase detail:', error));
// //     }, [billNo]);

// //     return (
// //         <>
// //             <Sidebar />
// //             <div id='main'>
// //                 <div className="container mt-5">
// //                     {purchaseDetail ? (
// //                         <div>
// //                             <h1>Purchase Detail - {purchaseDetail.billno}</h1>
// //                             <hr />
// //                             <p><strong>Bill Date:</strong> {new Date(purchaseDetail.billdate).toLocaleDateString()}</p>
// //                             <p><strong>Supplier Name:</strong> {purchaseDetail.spname}</p>
// //                             <p><strong>Bill Amount:</strong> Rs. {purchaseDetail.billamount.toLocaleString()}</p>
// //                             <p><strong>Paid:</strong> Rs. {purchaseDetail.paid.toLocaleString()}</p>
// //                             <p><strong>Balance:</strong> Rs. {purchaseDetail.balamount.toLocaleString()}</p>
// //                         </div>
// //                     ) : (
// //                         <p>Loading...</p>
// //                     )}
// //                 </div>
// //             </div>
// //         </>
// //     );
// // };




// const OrderDetail = ({ match }) => {


// const [categories, setCategories] = useState([]);
// const [units, setUnits] = useState([]);
// const [products, setProducts] = useState([{ productId: "", quantity: "", unit: "", amount: "" }]);
// const [orderData, setOrderData] = useState({
//   billNumber: "",
//   supplierName: "",
//   amount: 0,
// });

// useEffect(() => {
//     // Fetch categories
//     axios.get("https://posapi.emedha.in/api/categories")
//       .then((response) => setCategories(response.data))
//       .catch((error) => console.error('Error fetching categories:', error));
  
//     // Fetch units
//     axios.get('https://posapi.emedha.in/api/units')
//       .then((response) => {
//         console.log(response.data);  // Handle the response here
//         setUnits(response.data);  // Update the units state
//       })
//       .catch((error) => {
//         console.error('Error fetching units:', error);
//       });
//   }, []);
  

// console.log(categories)
// console.log(units)
// const handleAddProduct = () => {
//   setProducts([...products, { productId: "", quantity: "", unit: "", amount: "" }]);
// };

// const handleRemoveProduct = (index) => {
//   const updatedProducts = [...products];
//   updatedProducts.splice(index, 1);
//   setProducts(updatedProducts);
//   calculateAmount();
// };

// const handleProductChange = (e, index) => {
//   const updatedProducts = [...products];
//   updatedProducts[index][e.target.name] = e.target.value;
//   setProducts(updatedProducts);
//   calculateAmount();
// };

// const calculateAmount = () => {
//   let totalAmount = 0;
//   products.forEach((product) => {
//     totalAmount += parseFloat(product.amount || 0);
//   });
//   setOrderData({ ...orderData, amount: totalAmount });
// };

// const handleSubmit = (e) => {
//   e.preventDefault();
//   const productData = products.map((product) => ({
//     productId: product.productId,
//     quantity: product.quantity,
//     unit: product.unit,
//     amount: product.amount,
//   }));

//   axios
//     .post("https://posapi.emedha.in/api/save-order", {
//       billNumber: orderData.billNumber,
//       orderDate: new Date().toISOString().slice(0, 10),
//       supplierName: orderData.supplierName,
//       amount: orderData.amount,
//       productData,
//     })
//     .then((response) => {
//       alert(response.data.message);
//     })
//     .catch((error) => {
//       console.error(error);
//       alert("Error saving order");
//     });
// };

// return (
//     <>
//     <Sidebar/>
//       <div id='main'>
//     <h1>Transaction</h1>
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>Bill Number</label>
//         <input
//           type="text"
//           name="billNumber"
//           value={orderData.billNumber}
//           onChange={(e) => setOrderData({ ...orderData, billNumber: e.target.value })}
//           required
//         />
//       </div>
//       <div>
//         <label>Supplier Name</label>
//         <input
//           type="text"
//           name="supplierName"
//           value={orderData.supplierName}
//           onChange={(e) => setOrderData({ ...orderData, supplierName: e.target.value })}
//           required
//         />
//       </div>
//       <div>
//         <label>Amount</label>
//         <input type="text" name="amount" value={orderData.amount} readOnly />
//       </div>

//       <table>
//         <thead>
//           <tr>
//             <th>Select Product</th>
//             <th>Quantity</th>
//             <th>Units</th>
//             <th>Value (INR)</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {products.map((product, index) => (
//             <tr key={index}>
//               <td>
//                 <select
//                   name="productId"
//                   value={product.productId}
//                   onChange={(e) => handleProductChange(e, index)}
//                   required
//                 >
//                   <option value="">--Select Product--</option>
//                   {categories.map((category) => (
//                     <option key={category.cat_id} value={category.cat_id}>
//                       {category.cat_name}
//                     </option>
//                   ))}
//                 </select>
//               </td>
//               <td>
//                 <input
//                   type="number"
//                   name="quantity"
//                   value={product.quantity}
//                   onChange={(e) => handleProductChange(e, index)}
//                   required
//                 />
//               </td>
//               <td>
//                 <select
//                   name="unit"
//                   value={product.unit}
//                   onChange={(e) => handleProductChange(e, index)}
//                   required
//                 >
//                   <option value="">--Select Unit--</option>
//                   {units.map((unit) => (
//                     <option key={unit.id_unit} value={unit.id_unit}>
//                       {unit.nm_unit}
//                     </option>
//                   ))}
//                 </select>
//               </td>
//               <td>
//                 <input
//                   type="number"
//                   name="amount"
//                   value={product.amount}
//                   onChange={(e) => handleProductChange(e, index)}
//                   required
//                 />
//               </td>
//               <td>
//                 <button type="button" onClick={() => handleRemoveProduct(index)}>
//                   Remove
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <button type="button" onClick={handleAddProduct}>
//         Add Product
//       </button>

//       <button type="submit">Save Order</button>
//     </form>
//   </div>
//   </>

// );
// };
// export default OrderDetail;



// const OrderDetail = ({ match }) => {

//     const [products, setProducts] = useState([]);
//     const [units, setUnits] = useState([]);
//     const [orderData, setOrderData] = useState({
//         cashierName: '',
//         orderDate: new Date().toLocaleDateString(),
//         orderTime: new Date().toLocaleTimeString(),
//         billNumber: '',
//         supplierName: '',
//         amount: 0,
//     });
//     const [orderItems, setOrderItems] = useState([
//         { productId: '', quantity: '', unit: '', amount: '' },
//     ]);

//     useEffect(() => {
//         // Fetch categories (products) and units
//         axios.get('https://posapi.emedha.in/api/categories')
//             .then((response) => {
//                 setProducts(response.data);
//             })
//             .catch((error) => console.error('Error fetching categories:', error));

//         axios.get('https://posapi.emedha.in/api/units')
//             .then((response) => {
//                 setUnits(response.data);
//             })
//             .catch((error) => console.error('Error fetching units:', error));
//     }, []);

//     const handleAddProduct = () => {
//         setOrderItems([...orderItems, { productId: '', quantity: '', unit: '', amount: '' }]);
//     };

//     const handleRemoveProduct = (index) => {
//         const updatedItems = orderItems.filter((_, i) => i !== index);
//         setOrderItems(updatedItems);
//         calculateAmount(updatedItems);
//     };

//     const handleProductChange = (e, index) => {
//         const { name, value } = e.target;
//         const updatedItems = [...orderItems];
//         updatedItems[index][name] = value;
//         setOrderItems(updatedItems);
//         calculateAmount(updatedItems);
//     };

//     const calculateAmount = (items) => {
//         let totalAmount = 0;
//         items.forEach((item) => {
//             totalAmount += parseFloat(item.amount || 0);
//         });
//         setOrderData({ ...orderData, amount: totalAmount });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         const productData = orderItems.map((item) => ({
//             productId: item.productId,
//             quantity: item.quantity,
//             unit: item.unit,
//             amount: item.amount,
//         }));

//         axios.post('https://posapi.emedha.in/api/save-order', {
//             billNumber: orderData.billNumber,
//             orderDate: orderData.orderDate,
//             orderTime: orderData.orderTime,
//             supplierName: orderData.supplierName,
//             amount: orderData.amount,
//             productData,
//         })
//             .then((response) => {
//                 alert(response.data.message);
//                 // Redirect or reset form
//             })
//             .catch((error) => {
//                 console.error(error);
//                 alert('Error saving order');
//             });
//     };

//     return (
//         <>
//             <Sidebar />
//             <div id='main'>
//             <div className="container">
//                 <h1>Transaction</h1>
//                 <form onSubmit={handleSubmit}>
//                     <div className="form-row">
//                         <div className="form-group col-md-4">
//                             <label>Officer Name</label>
//                             <input
//                                 type="text"
//                                 className="form-control"
//                                 name="cashier_name"
//                                 value={orderData.cashierName}
//                                 readOnly
//                             />
//                         </div>

//                         <div className="form-group col-md-4">
//                             <label>Bill Date</label>
//                             <input
//                                 type="text"
//                                 className="form-control"
//                                 name="orderdate"
//                                 value={orderData.orderDate}
//                                 readOnly
//                             />
//                         </div>

//                         <div className="form-group col-md-4">
//                             <label>Time of Purchase</label>
//                             <input
//                                 type="text"
//                                 className="form-control"
//                                 name="timeorder"
//                                 value={orderData.orderTime}
//                                 readOnly
//                             />
//                         </div>
//                     </div>

//                     <div className="form-row">
//                         <table className="table">
//                             <thead>
//                                 <tr>
//                                     <th>Select Product</th>
//                                     <th>Quantity</th>
//                                     <th>Units</th>
//                                     <th>Value (INR)</th>
//                                     <th>
//                                         <button
//                                             type="button"
//                                             className="btn btn-success btn-sm"
//                                             onClick={handleAddProduct}
//                                         >
//                                             <i className="fa fa-plus"></i> Add Product
//                                         </button>
//                                     </th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {orderItems.map((item, index) => (
//                                     <tr key={index}>
//                                         <td>
//                                             <select
//                                                 className="form-control"
//                                                 name="productId"
//                                                 value={item.productId}
//                                                 onChange={(e) => handleProductChange(e, index)}
//                                             >
//                                                 <option value="">--Select Product--</option>
//                                                 {products.map((product) => (
//                                                     <option key={product.cat_id} value={product.cat_id}>
//                                                         {product.cat_name}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                         </td>
//                                         <td>
//                                             <input
//                                                 type="number"
//                                                 className="form-control"
//                                                 name="quantity"
//                                                 value={item.quantity}
//                                                 onChange={(e) => handleProductChange(e, index)}
//                                             />
//                                         </td>
//                                         <td>
//                                             <select
//                                                 className="form-control"
//                                                 name="unit"
//                                                 value={item.unit}
//                                                 onChange={(e) => handleProductChange(e, index)}
//                                             >
//                                                 <option value="">--Select Unit--</option>
//                                                 {units.map((unit) => (
//                                                     <option key={unit.id_unit} value={unit.id_unit}>
//                                                         {unit.nm_unit}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                         </td>
//                                         <td>
//                                             <input
//                                                 type="number"
//                                                 className="form-control"
//                                                 name="amount"
//                                                 value={item.amount}
//                                                 onChange={(e) => handleProductChange(e, index)}
//                                             />
//                                         </td>
//                                         <td>
//                                             <button
//                                                 type="button"
//                                                 className="btn btn-danger btn-sm"
//                                                 onClick={() => handleRemoveProduct(index)}
//                                             >
//                                                 <i className="fa fa-remove"></i>
//                                             </button>
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>

//                     <div className="form-row">
//                         <div className="col-md-12">
//                             <label>Bill Number</label>
//                             <input
//                                 type="text"
//                                 className="form-control"
//                                 name="billNumber"
//                                 value={orderData.billNumber}
//                                 onChange={(e) => setOrderData({ ...orderData, billNumber: e.target.value })}
//                                 required
//                             />
//                         </div>

//                         <div className="col-md-12">
//                             <label>Supplier Name</label>
//                             <input
//                                 type="text"
//                                 className="form-control"
//                                 name="supplierName"
//                                 value={orderData.supplierName}
//                                 onChange={(e) => setOrderData({ ...orderData, supplierName: e.target.value })}
//                                 required
//                             />
//                         </div>

//                         <div className="col-md-12">
//                             <label>Amount</label>
//                             <input
//                                 type="text"
//                                 className="form-control"
//                                 name="amount"
//                                 value={orderData.amount}
//                                 readOnly
//                             />
//                         </div>
//                     </div>

//                     <div className="form-group text-center">
//                         <button type="submit" className="btn btn-success">Save Order</button>
//                     </div>
//                 </form>
//             </div>
//             </div>
//         </>
//     );
// };

// export default OrderDetail;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap';
// import Sidebar from '../Sidebar/Sidebar';

// const OrderDetail = ({ match }) => {
//   const [categories, setCategories] = useState([]);
//   const [units, setUnits] = useState([]);
//   const [products, setProducts] = useState([{ productId: "", quantity: "", unit: "", amount: "" }]);
//   const [orderData, setOrderData] = useState({
//     billNumber: "",
//     supplierName: "",
//     amount: 0,
//   });

//   useEffect(() => {
//     // Fetch categories
//     axios.get("https://posapi.emedha.in/api/categories")
//       .then((response) => setCategories(response.data))
//       .catch((error) => console.error('Error fetching categories:', error));

//     // Fetch units
//     axios.get('https://posapi.emedha.in/api/units')
//       .then((response) => setUnits(response.data))
//       .catch((error) => console.error('Error fetching units:', error));
//   }, []);

//   const handleAddProduct = () => {
//     setProducts([...products, { productId: "", quantity: "", unit: "", amount: "" }]);
//   };

//   const handleRemoveProduct = (index) => {
//     const updatedProducts = [...products];
//     updatedProducts.splice(index, 1);
//     setProducts(updatedProducts);
//     calculateAmount();
//   };

//   const handleProductChange = (e, index) => {
//     const updatedProducts = [...products];
//     updatedProducts[index][e.target.name] = e.target.value;
//     setProducts(updatedProducts);
//     calculateAmount();
//   };

//   const calculateAmount = () => {
//     let totalAmount = 0;
//     products.forEach((product) => {
//       totalAmount += parseFloat(product.amount || 0);
//     });
//     setOrderData({ ...orderData, amount: totalAmount });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const productData = products.map((product) => ({
//       productId: product.productId,
//       quantity: product.quantity,
//       unit: product.unit,
//       amount: product.amount,
//     }));

//     axios
//       .post("https://posapi.emedha.in/api/save-order", {
//         billNumber: orderData.billNumber,
//         orderDate: new Date().toISOString().slice(0, 10),
//         supplierName: orderData.supplierName,
//         amount: orderData.amount,
//         productData,
//       })
//       .then((response) => {
//         alert(response.data.message);
//       })
//       .catch((error) => {
//         console.error(error);
//         alert("Error saving order");
//       });
//   };

//   return (
//     <>
//       <Sidebar />
//       <Container id="main" className="my-4">
//         <h1 className="mb-4">Transaction</h1>
//         <Form onSubmit={handleSubmit}>
//           <Row className="mb-3">
//             <Col xs={12} sm={6}>
//               <Form.Group controlId="billNumber">
//                 <Form.Label>Bill Number</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="billNumber"
//                   value={orderData.billNumber}
//                   onChange={(e) => setOrderData({ ...orderData, billNumber: e.target.value })}
//                   required
//                 />
//               </Form.Group>
//             </Col>
//             <Col xs={12} sm={6}>
//               <Form.Group controlId="supplierName">
//                 <Form.Label>Supplier Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="supplierName"
//                   value={orderData.supplierName}
//                   onChange={(e) => setOrderData({ ...orderData, supplierName: e.target.value })}
//                   required
//                 />
//               </Form.Group>
//             </Col>
//           </Row>

//           <Row className="mb-3">
//             <Col xs={12} sm={6}>
//               <Form.Group controlId="amount">
//                 <Form.Label>Amount</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="amount"
//                   value={orderData.amount}
//                   readOnly
//                 />
//               </Form.Group>
//             </Col>
//           </Row>

//           <Table responsive="sm">
//             <thead>
//               <tr>
//                 <th>Select Product</th>
//                 <th>Quantity</th>
//                 <th>Units</th>
//                 <th>Value (INR)</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {products.map((product, index) => (
//                 <tr key={index}>
//                   <td>
//                     <Form.Control
//                       as="select"
//                       name="productId"
//                       value={product.productId}
//                       onChange={(e) => handleProductChange(e, index)}
//                       required
//                     >
//                       <option value="">--Select Product--</option>
//                       {categories.map((category) => (
//                         <option key={category.cat_id} value={category.cat_id}>
//                           {category.cat_name}
//                         </option>
//                       ))}
//                     </Form.Control>
//                   </td>
//                   <td>
//                     <Form.Control
//                       type="number"
//                       name="quantity"
//                       value={product.quantity}
//                       onChange={(e) => handleProductChange(e, index)}
//                       required
//                     />
//                   </td>
//                   <td>
//                     <Form.Control
//                       as="select"
//                       name="unit"
//                       value={product.unit}
//                       onChange={(e) => handleProductChange(e, index)}
//                       required
//                     >
//                       <option value="">--Select Unit--</option>
//                       {units.map((unit) => (
//                         <option key={unit.id_unit} value={unit.id_unit}>
//                           {unit.nm_unit}
//                         </option>
//                       ))}
//                     </Form.Control>
//                   </td>
//                   <td>
//                     <Form.Control
//                       type="number"
//                       name="amount"
//                       value={product.amount}
//                       onChange={(e) => handleProductChange(e, index)}
//                       required
//                     />
//                   </td>
//                   <td>
//                     <Button variant="danger" onClick={() => handleRemoveProduct(index)}>
//                       Remove
//                     </Button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>

//           <Row className="mb-3">
//             <Col xs={12} sm={6}>
//               <Button variant="primary" type="button" onClick={handleAddProduct} block>
//                 Add Product
//               </Button>
//             </Col>
//             <Col xs={12} sm={6}>
//               <Button variant="success" type="submit" block>
//                 Save Order
//               </Button>
//             </Col>
//           </Row>
//         </Form>
//       </Container>
//     </>
//   );
// };

// export default OrderDetail;




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';

const OrderDetail = ({ match }) => {
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [products, setProducts] = useState([{ productId: "", quantity: "", unit: "", amount: "" }]);
  const [orderData, setOrderData] = useState({
    billNumber: "",
    supplierName: "",
    amount: 0,
  });

  useEffect(() => {
    // Fetch categories
    axios.get("https://posapi.emedha.in/api/categories")
      .then((response) => setCategories(response.data))
      .catch((error) => console.error('Error fetching categories:', error));

    // Fetch units
    axios.get('https://posapi.emedha.in/api/units')
      .then((response) => setUnits(response.data))
      .catch((error) => console.error('Error fetching units:', error));
  }, []);

  const handleAddProduct = () => {
    setProducts([...products, { productId: "", quantity: "", unit: "", amount: "" }]);
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
    calculateAmount();
  };

  const handleProductChange = (e, index) => {
    const updatedProducts = [...products];
    updatedProducts[index][e.target.name] = e.target.value;
    setProducts(updatedProducts);
    calculateAmount();
  };

  const calculateAmount = () => {
    let totalAmount = 0;
    products.forEach((product) => {
      totalAmount += parseFloat(product.amount || 0);
    });
    setOrderData({ ...orderData, amount: totalAmount });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const productData = products.map((product) => ({
      productId: product.productId,
      quantity: product.quantity,
      unit: product.unit,
      amount: product.amount,
    }));

    axios
      .post("https://posapi.emedha.in/api/save-order", {
        billNumber: orderData.billNumber,
        orderDate: new Date().toISOString().slice(0, 10),
        supplierName: orderData.supplierName,
        amount: orderData.amount,
        productData,
      })
      .then((response) => {
        alert(response.data.message);
        setOrderData({
            billNumber: "",
            supplierName: "",
            amount: 0,
          });
          setProducts([{ productId: "", quantity: "", unit: "", amount: "" }]); // Reset product data
      
        
      })
      .catch((error) => {
        console.error(error);
        alert("Error saving order");
      });
  };

  return (
    <>
      <Sidebar />
      <Container id="main" className="my-5 p-4 bg-light shadow-sm rounded">
        <h1 className="mb-4 text-center text-primary">Transaction Details</h1>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col xs={12} sm={6}>
              <Form.Group controlId="billNumber">
                <Form.Label>Bill Number</Form.Label>
                <Form.Control
                  type="text"
                  name="billNumber"
                  value={orderData.billNumber}
                  onChange={(e) => setOrderData({ ...orderData, billNumber: e.target.value })}
                  required
                  placeholder="Enter bill number"
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId="supplierName">
                <Form.Label>Supplier Name</Form.Label>
                <Form.Control
                  type="text"
                  name="supplierName"
                  value={orderData.supplierName}
                  onChange={(e) => setOrderData({ ...orderData, supplierName: e.target.value })}
                  required
                  placeholder="Enter supplier name"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} sm={6}>
              <Form.Group controlId="amount">
                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  value={orderData.amount}
                  readOnly
                  placeholder="Total amount"
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="table-responsive mb-4">
            <Table responsive="sm" className="table-bordered table-hover">
              <thead className="table-light">
                <tr>
                  <th>Select Product</th>
                  <th>Quantity</th>
                  <th>Units</th>
                  <th>Value (INR)</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        as="select"
                        name="productId"
                        value={product.productId}
                        onChange={(e) => handleProductChange(e, index)}
                        required
                      >
                        <option value="">--Select Product--</option>
                        {categories.map((category) => (
                          <option key={category.cat_id} value={category.cat_id}>
                            {category.cat_name}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="quantity"
                        value={product.quantity}
                        onChange={(e) => handleProductChange(e, index)}
                        required
                        placeholder="Quantity"
                      />
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        name="unit"
                        value={product.unit}
                        onChange={(e) => handleProductChange(e, index)}
                        required
                      >
                        <option value="">--Select Unit--</option>
                        {units.map((unit) => (
                          <option key={unit.id_unit} value={unit.id_unit}>
                            {unit.nm_unit}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="amount"
                        value={product.amount}
                        onChange={(e) => handleProductChange(e, index)}
                        required
                        placeholder="Amount"
                      />
                    </td>
                    <td>
                      <Button variant="danger" onClick={() => handleRemoveProduct(index)} size="sm">
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <Row className="mb-3">
            <Col xs={12} sm={6}>
              <Button variant="primary" type="button" onClick={handleAddProduct} block>
                Add Product
              </Button>
            </Col>
            <Col xs={12} sm={6}>
              <Button variant="success" type="submit" block>
                Save Order
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default OrderDetail;
