// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Swal from 'sweetalert2';

// const Profile = () => {
//     const [user, setUser] = useState(null); 
//     const [oldPass, setOldPass] = useState('');
//     const [newPass, setNewPass] = useState('');
//     const [confPass, setConfPass] = useState('');

//     useEffect(() => {
//         axios.get('https://posapi.emedha.in/api/user-profile')
//             .then(response => setUser(response.data))
//             .catch(error => console.error("Error fetching user data", error));
//     }, []);

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (newPass !== confPass) {
//             Swal.fire("Warning", "Confirm Password Is Wrong", "warning");
//             return;
//         }

//         try {
//             const response = await axios.post('https://posapi.emedha.in/api/update-password', {
//                 oldpass: oldPass,
//                 newpass: newPass,
//             });

//             if (response.data.success) {
//                 Swal.fire("Success", "Password Updated", "success");
//             } else {
//                 Swal.fire("Oops", "Password Is Not Updated", "error");
//             }
//         } catch (error) {
//             Swal.fire("Error", "Something went wrong", "error");
//         }
//     };



//     return (
//         <div className="container">
//             {/* Profile Section */}
//             <div className="profile">
//                 <h3>User Profile</h3>
//                 <p><strong>Username:</strong> {user.username}</p>
//                 <p><strong>Full Name:</strong> {user.fullname}</p>
//                 <p><strong>Role:</strong> {user.role}</p>
//             </div>

//             {/* Change Password Section */}
//             <div className="change-password">
//                 <h3>Change Password</h3>
//                 <form onSubmit={handleSubmit}>
//                     <div className="form-group">
//                         <label>Old Password</label>
//                         <input
//                             type="password"
//                             className="form-control"
//                             value={oldPass}
//                             onChange={(e) => setOldPass(e.target.value)}
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label>New Password</label>
//                         <input
//                             type="password"
//                             className="form-control"
//                             value={newPass}
//                             onChange={(e) => setNewPass(e.target.value)}
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label>Confirm Password</label>
//                         <input
//                             type="password"
//                             className="form-control"
//                             value={confPass}
//                             onChange={(e) => setConfPass(e.target.value)}
//                             required
//                         />
//                     </div>
//                     <button type="submit" className="btn btn-primary">Update Password</button>
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default Profile;

// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import { Container, Row, Col, Card, Button } from 'react-bootstrap';
// import { FaUserCircle, FaEdit } from 'react-icons/fa';
// import Sidebar from '../Sidebar/Sidebar';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap styles

// const Profile = () => {
//   const [userData, setUserData] = useState(null);
//   const [error, setError] = useState(null);
//   const sessionUserId = sessionStorage.getItem('user_id');  // Get user_id from session storage
  
//   const fetchUsers = async () => {
//     try {
//       const response = await axios.get('https://posapi.emedha.in/api/users');
//       setUserData(response.data); // Assuming this returns the array of users
//     } catch (err) {
//       setError('Error fetching users');
//     }
//   };

//   useEffect(() => {
//     fetchUsers();
//   }, [sessionUserId]);

//   // Filter users by sessionUserId
//   const filteredUser = userData?.filter(user => user.user_id === Number(sessionUserId));

//   return (
//     <>
//       <Sidebar />
//       <div id="main">
//         <Container className="mt-5">
//           <h1 className="text-center mb-4">Profile</h1>

//           {error && <p>{error}</p>} {/* Display error if exists */}

//           {filteredUser && filteredUser.length > 0 ? (
//             filteredUser.map((user) => (
//               <Row className="justify-content-center" key={user.user_id}>
//                 <Col sm={12} md={8} lg={6}>
//                   <Card className="shadow-lg">
//                     <Card.Body className="text-center">
//                       <FaUserCircle size={100} color="#6c757d" className="mb-3" />
//                       <Card.Title>{user.fullname}</Card.Title>
//                       <Card.Subtitle className="mb-2 text-muted">{user.username}</Card.Subtitle>
//                       <Card.Text>
//                         <strong>Role:</strong> {user.role}
//                       </Card.Text>
//                       <Card.Text>
//                         <strong>Status:</strong> {user.is_active ? 'Active' : 'Inactive'}
//                       </Card.Text>
//                       <Button variant="primary" className="mr-2">
//                         <FaEdit className="mr-2" />
//                         change Password
//                       </Button>
//                     </Card.Body>
//                   </Card>
//                 </Col>
//               </Row>
//             ))
//           ) : (
//             <p>No user found or data is still loading.</p> 
//           )}
//         </Container>
//       </div>
//     </>
//   );
// };

// export default Profile;





import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { FaUserCircle, FaEdit } from 'react-icons/fa';
import Sidebar from '../Sidebar/Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap styles
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Required for accessibility

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to control modal visibility
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false); // Toggle visibility of current password
  const [showNewPassword, setShowNewPassword] = useState(false); // Toggle visibility of new password
  const sessionUserId = sessionStorage.getItem('user_id');  // Get user_id from session storage

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://posapi.emedha.in/api/users');
      setUserData(response.data); // Assuming this returns the array of users
    } catch (err) {
      setError('Error fetching users');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [sessionUserId]);

  // Filter users by sessionUserId
  const filteredUser = userData?.filter(user => user.user_id === Number(sessionUserId));

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setCurrentPassword('');
    setNewPassword('');
  };

//   const handlePasswordChange = async (e) => {
//     e.preventDefault();

//     try {
//       // Make sure the user provides a valid current password and new password
//       const response = await axios.put(`https://posapi.emedha.in/api/users/${sessionUserId}`, {
//         currentPassword,
//         newPassword
//       });

//       if (response.data.success) {
//         alert('Password updated successfully');
//         handleCloseModal(); // Close modal after successful update
//       } else {
//         alert('Failed to change password');
//       }
//     } catch (error) {
//       console.error('Error changing password:', error);
//       alert('Error changing password');
//     }
//   };

  return (
    <>
      <Sidebar />
      <div id="main">
        <Container className="mt-5">
          <h1 className="text-center mb-4">Profile</h1>

          {error && <p>{error}</p>} {/* Display error if exists */}

          {filteredUser && filteredUser.length > 0 ? (
            filteredUser.map((user) => (
              <Row className="justify-content-center" key={user.user_id}>
                <Col sm={12} md={8} lg={6}>
                  <Card className="shadow-lg">
                    <Card.Body className="text-center">
                      <FaUserCircle size={100} color="#6c757d" className="mb-3" />
                      <Card.Title>{user.fullname}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">{user.username}</Card.Subtitle>
                      <Card.Text>
                        <strong>Role:</strong> {user.role}
                      </Card.Text>
                      <Card.Text>
                        <strong>Status:</strong> {user.is_active ? 'Active' : 'Inactive'}
                      </Card.Text>
                      {/* <Button variant="primary" className="mr-2" onClick={handleOpenModal}>
                        <FaEdit className="mr-2" />
                        Change Password
                      </Button> */}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ))
          ) : (
            <p>No user found or data is still loading.</p> 
          )}
        </Container>

        {/* Modal for Changing Password */}
        {/* <Modal
        id='main'
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Change Password"
        
          overlayClassName="modal-overlay"
          closeTimeoutMS={200} // Smooth closing animation
        >
          <h2 className="text-center mb-4">Change Password</h2>
          <Form onSubmit={handlePasswordChange}>
  
            <Form.Group controlId="currentPassword">
              <Form.Label>Current Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={showCurrentPassword ? 'text' : 'password'}
                  placeholder="Enter your current password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
                <Button
                  variant="link"
                  className="position-absolute top-50 end-0 translate-middle-y"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                >
                  {showCurrentPassword ? 'Hide' : 'Show'}
                </Button>
              </div>
            </Form.Group>

            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={showNewPassword ? 'text' : 'password'}
                  placeholder="Enter a new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <Button
                  variant="link"
                  className="position-absolute top-50 end-0 translate-middle-y"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? 'Hide' : 'Show'}
                </Button>
              </div>
            </Form.Group>

            <Button variant="primary" type="submit">
              Change Password
            </Button>
            <Button variant="secondary" onClick={handleCloseModal} className="ml-2">
              Cancel
            </Button>
          </Form>
        </Modal> */}
      </div>
    </>
  );
};

export default Profile;
