// Header.js
import React from 'react';
import headertemple from '../images/headertemple.jpeg'; // Make sure to adjust the path to the image
import './Header.css';
import Sidebar from '../Sidebar/Sidebar';

const  Footer = () => {
  return (
    <>
  
    <div id='main'>
    <div className="footer" 
    style={{
        backgroundColor:'white',
        position:'relative',
        bottom: '0', // Ensures it sticks to the bottom
        left: '0',
        width: '100%', // Make the footer span the entire width
        color:'black',
        textAlign:'center',
        padding:'20px 0',
        borderTop:'2px solid #444',
        boxShadow:'0 -4px 6px rgba(0,0,0,0.1'}}>
        <p style={{margin:'0',fontSize:'1em'}}>© 2025 Emedha Technologies POS Ver. 1.0. All rights reserved.</p>
      </div>

    </div>
    
    </>
  );
}

export default Footer;
