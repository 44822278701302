// import React, { useEffect, useState } from 'react';
// import '../Sidebar/Sidebar.css';
// import Sidebar from '../Sidebar/Sidebar';
// import Header from '../Header/Header';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import axios from 'axios';
// import './PC.css'



// const ProductCategory = () => {



//     const [categoryName, setCategoryName] = useState('');
//     const [categories, setCategories] = useState([]);



//     const [entriesPerPage, setEntriesPerPage] = useState(10);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [loading, setLoading] = useState(true); 
//     const [error, setError] = useState(null); 




//          const fetchCategory = async () => {
//                 try {
//                   const response = await axios.get('https://posapi.emedha.in/api/category');
//                   setCategories(response.data);  // Set users data from API response
//                   setLoading(false);         // Stop loading once data is fetched
//                 } catch (err) {
//                   setError('Error fetching users');  // Handle errors
//                   setLoading(false);
//                 }
//               };

//     console.log(categories)

//         useEffect(() => {
//             fetchCategory();

//         }, []);


//     const handleCategoryChange = (event) => {
//         setCategoryName(event.target.value);
//     };

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         if (categoryName) {
//             const newCategory = { id: categories.length + 7, name: categoryName };
//             setCategories([...categories, newCategory]);
//             setCategoryName('');
//         }
//     };

//     const handleSearchChange = (event) => {
//         setSearchTerm(event.target.value);
//     };

//     const filteredCategories = categories.filter((category) =>
//         category.cat_name && category.cat_name.trim().toLowerCase().includes(searchTerm.toLowerCase())
//       );







//     return (
//         <>
//             <Sidebar />
//             <div id="main">






//                 <div className="container mt-4">
//                     <div className="row">
//                         {/* Category Input */}
//                         <div className="col-md-4">
//                             <div className="card">
//                                 <div className="card-header">Category Name</div>
//                                 <div className="card-body">
//                                     <form onSubmit={handleSubmit}>
//                                     <div className="floating-label-container">
//                                         <input
//                                             type="text"
//                                             className=" floating-input"
//                                             id="categoryName"
//                                             value={categoryName}
//                                             onChange={handleCategoryChange}
//                                             placeholder=" "
//                                         />
//                                         <label htmlFor="categoryName" className="floating-label">
//                                             Enter Category
//                                         </label>
//                                     </div>
//                                     <button type="submit" className="btn btn-primary">
//                                         Submit
//                                     </button>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>

//                     {/* Category List */}
//                     <div className="col-md-8">
//                         <div className="card">
//                             <div className="card-header">Category List</div>
//                             <div className="card-body">
//                                 <div className="row mb-3">
//                                     <div className="col-md-6">
//                                         <label>
//                                             Show
//                                             <select
//                                                 className="custom-select custom-select-sm form-control form-control-sm"
//                                                 style={{ width: 'auto', display: 'inline-block' }}
//                                                 value={entriesPerPage}
//                                                 onChange={(e) => setEntriesPerPage(Number(e.target.value))}
//                                             >
//                                                 <option value="10">10</option>
//                                                 <option value="25">25</option>
//                                                 <option value="50">50</option>
//                                                 <option value="100">100</option>
//                                             </select>
//                                             entries
//                                         </label>
//                                     </div>
//                                     <div className="col-md-6 text-right">
//                                         <label>
//                                             Search:
//                                             <input
//                                                 type="search"
//                                                 className="form-control form-control-sm"
//                                                 value={searchTerm}
//                                                 onChange={handleSearchChange}
//                                             />
//                                         </label>
//                                     </div>
//                                 </div>

//                                 <table className="table table-bordered table-hover">
//                                     <thead>
//                                         <tr>
//                                             <th>No</th>
//                                             <th>Category Name</th>
//                                             <th>Action</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                     {filteredCategories.map((category, index) => ( 
//                                             <tr key={category.id}>
//                                                 <td>{index+1}</td>
//                                                 <td>{category.cat_name}</td>
//                                                 <td>
//                                                     <button className="btn btn-info btn-sm">
//                                                         <i className="fas fa-edit"></i>
//                                                     </button>
//                                                     <button className="btn btn-danger btn-sm ml-2">
//                                                         <i className="fas fa-trash"></i>
//                                                     </button>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>

//                                 <div className="row">
//                                     <div className="col-md-6">
//                                         <p>
//                                             Showing 1 to {filteredCategories.slice(0, entriesPerPage).length} of{' '}
//                                             {filteredCategories.length} entries
//                                         </p>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <nav>
//                                             <ul className="pagination">
//                                                 <li className="page-item disabled">
//                                                     <a className="page-link" href="#" tabIndex="-1">
//                                                         Previous
//                                                     </a>
//                                                 </li>
//                                                 <li className="page-item active">
//                                                     <a className="page-link" href="#">
//                                                         1
//                                                     </a>
//                                                 </li>
//                                                 <li className="page-item">
//                                                     <a className="page-link" href="#">
//                                                         Next
//                                                     </a>
//                                                 </li>
//                                             </ul>
//                                         </nav>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div >
//     </>
//   );
// };

// export default ProductCategory;



// import React, { useEffect, useState } from 'react';
// import '../Sidebar/Sidebar.css';
// import Sidebar from '../Sidebar/Sidebar';
// import Header from '../Header/Header';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import axios from 'axios';
// import './PC.css';

// const ProductCategory = () => {
//     const [categoryName, setCategoryName] = useState('');
//     const [categories, setCategories] = useState([]);
//     const [entriesPerPage, setEntriesPerPage] = useState(5); // Set to 5 entries per page
//     const [searchTerm, setSearchTerm] = useState('');
//     const [loading, setLoading] = useState(true);
//     const [message, setMessage] = useState(null);
//     const [error, setError] = useState(null);
//     const [currentPage, setCurrentPage] = useState(1); // Track the current page

//     const fetchCategory = async () => {
//         try {
//             const response = await axios.get('https://posapi.emedha.in/api/category');
//             setCategories(response.data);
//             setLoading(false);
//         } catch (err) {
//             setError('Error fetching categories');
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchCategory();
//     }, []);

//     const handleCategoryChange = (event) => {
//         setCategoryName(event.target.value);
//     };



//     const handleSearchChange = (event) => {
//         setSearchTerm(event.target.value);
//     };

//     // Filter categories based on search term
//     const filteredCategories = categories.filter((category) =>
//         category.cat_name && category.cat_name.trim().toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     // Get total pages based on filtered categories and entries per page
//     const totalPages = Math.ceil(filteredCategories.length / entriesPerPage);

//     // Get the categories to display for the current page
//     const currentCategories = filteredCategories.slice(
//         (currentPage - 1) * entriesPerPage,
//         currentPage * entriesPerPage
//     );

//     // Handle page click
//     const handlePageClick = (pageNumber) => {
//         setCurrentPage(pageNumber);
//     };




//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         if (!categoryName.trim()) {
//             alert('Category name is required');
//             return;
//         }

//         try {
//             // Send POST request to the backend to insert a new category
//             const response = await axios.post('https://posapi.emedha.in/api/post/category', {
//                 cat_name: categoryName.trim(), // Send the category name as a POST parameter
//             });

//             // Handle successful response
//             setMessage(response.data.message); // Display success message
//             setCategories([...categories, { cat_name: categoryName.trim() }]); // Update categories list

//             // Clear the input field
//             setCategoryName('');
//         } catch (err) {
//             console.error('Error adding category:', err);
//             setError('Error adding category'); // Display error message if request fails
//         }
//     };


//     const handleDelete = async (catId) => {
//         try {
//             const response = await axios.delete(`https://posapi.emedha.in/api/delete/category/${catId}`);
//             alert(response.data.message);  // Show success message
//             fetchCategory();  // Reload the categories
//         } catch (err) {
//             alert('Error deleting category');
//             console.error(err);
//         }
//     };
//     const handleUpdate = async (catId, updatedCategoryName) => {
//         try {
//             const response = await axios.put(`https://posapi.emedha.in/api/update/category/${catId}`, {
//                 cat_name: updatedCategoryName,
//             });
//             alert(response.data.message);  // Show success message
//             fetchCategory();  // Reload the categories
//         } catch (err) {
//             alert('Error updating category');
//             console.error(err);
//         }
//     };



//     return (
//         <>
//             <Sidebar />
//             <div id="main">
//                 <div className="container mt-4">
//                     <div className="row">
//                         {/* Category Input */}
//                         <div className="col-md-4">
//                             <div className="card">
//                                 <div className="card-header">Category Name</div>
//                                 <div className="card-body">
//                                     <form onSubmit={handleSubmit}>
//                                         <div className="floating-label-container">
//                                             <input
//                                                 type="text"
//                                                 className="floating-input"
//                                                 id="categoryName"
//                                                 value={categoryName}
//                                                 onChange={handleCategoryChange}
//                                                 placeholder=" "
//                                             />
//                                             <label htmlFor="categoryName" className="floating-label">
//                                                 Enter Category
//                                             </label>
//                                         </div>
//                                         <button type="submit" className="btn btn-primary">
//                                             Submit
//                                         </button>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>

//                         {/* Category List */}
//                         <div className="col-md-8">
//                             <div className="card">
//                                 <div className="card-header">Category List</div>
//                                 <div className="card-body">
//                                     <div className="row mb-3">
//                                         <div className="col-md-6">
//                                             <label>
//                                                 Show
//                                                 <select
//                                                     className="custom-select custom-select-sm form-control form-control-sm"
//                                                     style={{ width: 'auto', display: 'inline-block' }}
//                                                     value={entriesPerPage}
//                                                     onChange={(e) => setEntriesPerPage(Number(e.target.value))}
//                                                 >
//                                                     <option value="5">5</option>
//                                                     <option value="10">10</option>
//                                                     <option value="25">25</option>
//                                                     <option value="50">50</option>
//                                                 </select>
//                                                 entries
//                                             </label>
//                                         </div>
//                                         <div className="col-md-6 text-right">
//                                             <label>
//                                                 Search:
//                                                 <input
//                                                     type="search"
//                                                     className="form-control form-control-sm"
//                                                     value={searchTerm}
//                                                     onChange={handleSearchChange}
//                                                 />
//                                             </label>
//                                         </div>
//                                     </div>

//                                     <table className="table table-bordered table-hover">
//                                         <thead>
//                                             <tr>
//                                                 <th>No</th>
//                                                 <th>Category Name</th>
//                                                 <th>Action</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             {currentCategories.map((category, index) => (
//                                                 <tr key={category.id}>
//                                                     <td>{(currentPage - 1) * entriesPerPage + index + 1}</td>
//                                                     <td>{category.cat_name}</td>
//                                                     <td>
//                                                         <button
//                                                             className="btn btn-info btn-sm"
//                                                             onClick={() => handleUpdate(category.cat_id, 'New Category Name')}
//                                                         >
//                                                             <i className="fas fa-edit"></i>
//                                                         </button>
//                                                         <button
//                                                             className="btn btn-danger btn-sm ml-2"
//                                                             onClick={() => handleDelete(category.cat_id)}
//                                                         >
//                                                             <i className="fas fa-trash"></i>
//                                                         </button>
//                                                     </td>
//                                                 </tr>
//                                             ))}
//                                         </tbody>
//                                     </table>

//                                     {/* Pagination Controls */}
//                                     <div className="row">
//                                         <div className="col-md-6">
//                                             <p>
//                                                 Showing {(currentPage - 1) * entriesPerPage + 1} to{' '}
//                                                 {Math.min(currentPage * entriesPerPage, filteredCategories.length)} of {filteredCategories.length} entries
//                                             </p>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <nav>
//                                                 <ul className="pagination">
//                                                     <li
//                                                         className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
//                                                     >
//                                                         <a
//                                                             className="page-link"
//                                                             href="#"
//                                                             onClick={() => handlePageClick(currentPage - 1)}
//                                                         >
//                                                             Previous
//                                                         </a>
//                                                     </li>
//                                                     {[...Array(totalPages)].map((_, index) => (
//                                                         <li
//                                                             key={index + 1}
//                                                             className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
//                                                         >
//                                                             <a
//                                                                 className="page-link"
//                                                                 href="#"
//                                                                 onClick={() => handlePageClick(index + 1)}
//                                                             >
//                                                                 {index + 1}
//                                                             </a>
//                                                         </li>
//                                                     ))}
//                                                     <li
//                                                         className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
//                                                     >
//                                                         <a
//                                                             className="page-link"
//                                                             href="#"
//                                                             onClick={() => handlePageClick(currentPage + 1)}
//                                                         >
//                                                             Next
//                                                         </a>
//                                                     </li>
//                                                 </ul>
//                                             </nav>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default ProductCategory;




import React, { useEffect, useState } from 'react';
import '../Sidebar/Sidebar.css';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';
import './PC.css';

const ProductCategory = () => {
    const [categoryName, setCategoryName] = useState('');
    const [categories, setCategories] = useState([]);
    const [entriesPerPage, setEntriesPerPage] = useState(5); // Set to 5 entries per page
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Track the current page
    const [showModal, setShowModal] = useState(false); // Track modal visibility
    const [editCategoryId, setEditCategoryId] = useState(null); // Track the category ID being edited
    const [editCategoryName, setEditCategoryName] = useState(''); // Track the category name for editing

    const fetchCategory = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/category');
            setCategories(response.data);
            setLoading(false);
        } catch (err) {
            setError('Error fetching categories');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategory();
    }, []);

    const handleCategoryChange = (event) => {
        setCategoryName(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredCategories = categories.filter((category) =>
        category.cat_name && category.cat_name.trim().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredCategories.length / entriesPerPage);

    const currentCategories = filteredCategories.slice(
        (currentPage - 1) * entriesPerPage,
        currentPage * entriesPerPage
    );

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!categoryName.trim()) {
            alert('Category name is required');
            return;
        }

        try {
         
            const response = await axios.post('https://posapi.emedha.in/api/post/category', {
                cat_name: categoryName.trim(),
            });

      
            setMessage(response.data.message); 
            setCategoryName('');
            fetchCategory()
        } catch (err) {
            console.error('Error adding category:', err);
            setError('Error adding category'); 
        }
    };

    const handleDelete = async (catId) => {
        try {
            const response = await axios.delete(`https://posapi.emedha.in/api/delete/category/${catId}`);
            alert(response.data.message);  
            fetchCategory();  
        } catch (err) {
            alert('Error deleting category');
            console.error(err);
        }
    };

    const handleUpdate = async (catId, updatedCategoryName) => {
        try {
            const response = await axios.put(`https://posapi.emedha.in/api/update/category/${catId}`, {
                cat_name: updatedCategoryName,
            });
            alert(response.data.message);  
            fetchCategory();  
            setShowModal(false);  
        } catch (err) {
            alert('Error updating category');
            console.error(err);
        }
    };

    const openEditModal = (catId, catName) => {
        setEditCategoryId(catId);
        setEditCategoryName(catName);
        setShowModal(true);
    };

    const closeEditModal = () => {
        setShowModal(false);
        setEditCategoryId(null);
        setEditCategoryName('');
    };

    return (
        <>
            <Sidebar />
            <div id="main">
                <div className="container mt-4">
                    <div className="row">
                    
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header">Category Name</div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="floating-label-container">
                                            <input
                                                type="text"
                                                className="floating-input"
                                                id="categoryName"
                                                value={categoryName}
                                                onChange={handleCategoryChange}
                                                placeholder=" "
                                            />
                                            <label htmlFor="categoryName" className="floating-label">
                                                Enter Category
                                            </label>
                                        </div>
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">Category List</div>
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <label>
                                                Show
                                                <select
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    style={{ width: 'auto', display: 'inline-block' }}
                                                    value={entriesPerPage}
                                                    onChange={(e) => setEntriesPerPage(Number(e.target.value))}
                                                >
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                </select>
                                                entries
                                            </label>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <label>
                                                Search:
                                                <input
                                                    type="search"
                                                    className="form-control form-control-sm"
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Category Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentCategories.map((category, index) => (
                                                <tr key={category.cat_id}>
                                                    <td>{(currentPage - 1) * entriesPerPage + index + 1}</td>
                                                    <td>{category.cat_name}</td>
                                                    <td>
                                                        <button
                                                            className="btn btn-info btn-sm"
                                                            onClick={() => openEditModal(category.cat_id, category.cat_name)}
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-danger btn-sm ml-2"
                                                            onClick={() => handleDelete(category.cat_id)}
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <p>
                                                Showing {(currentPage - 1) * entriesPerPage + 1} to{' '}{Math.min(currentPage * entriesPerPage, filteredCategories.length)} of {filteredCategories.length} entries
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <nav>
                                                <ul className="pagination">
                                                    <li
                                                        className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                                                    >
                                                        <a
                                                            className="page-link"
                                                            href="#"
                                                            onClick={() => handlePageClick(currentPage - 1)}
                                                        >
                                                            Previous
                                                        </a>
                                                    </li>
                                                    {[...Array(totalPages)].map((_, index) => (
                                                        <li
                                                            key={index + 1}
                                                            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                                        >
                                                            <a
                                                                className="page-link"
                                                                href="#"
                                                                onClick={() => handlePageClick(index + 1)}
                                                            >
                                                                {index + 1}
                                                            </a>
                                                        </li>
                                                    ))}
                                                    <li
                                                        className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                                                    >
                                                        <a
                                                            className="page-link"
                                                            href="#"
                                                            onClick={() => handlePageClick(currentPage + 1)}
                                                        >
                                                            Next
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={closeEditModal}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}> {/* Stop propagation here */}
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Category</h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={closeEditModal}
                                >
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Category Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={editCategoryName}
                                        onChange={(e) => setEditCategoryName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={closeEditModal}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleUpdate(editCategoryId, editCategoryName)}
                                >
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};

export default ProductCategory;
