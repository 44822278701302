
// import React, { useState } from 'react';
// import axios from 'axios';
// import { Button, Table, Form, Container, Row, Col } from 'react-bootstrap';
// import Sidebar from '../Sidebar/Sidebar';

// function StockReport() {
//     const [date_1, setDate1] = useState('');
//     const [date_2, setDate2] = useState('');
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);

//     const handleDateSubmit = async () => {
//         if (date_1 && date_2) {
//             setLoading(true);
//             try {
//                 const response = await axios.post('https://posapi.emedha.in/api/getPurchasesWithStock', {
//                     date_1,
//                     date_2
//                 });
//                 setData(response.data);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//             setLoading(false);
//         } else {
//             alert('Please select both dates');
//         }
//     };

//     const handleGeneratePDF = () => {
//         const printContent = document.getElementById('printable-table').innerHTML;  // Get the table HTML content
//         const printWindow = window.open('', '', 'height=600,width=800');  // Open a new print window
//         printWindow.document.write('<html><head><title>Print</title><style>');
//         printWindow.document.write(`
//             body { font-family: Arial, sans-serif; }
//             table { width: 100%; border-collapse: collapse; }
//             table, th, td { border: 1px solid black; }
//             th, td { padding: 8px; text-align: left; }
//         `);
//         printWindow.document.write('</style></head><body>');
//         printWindow.document.write(printContent);  // Insert the table HTML into the print window
//         printWindow.document.write('</body></html>');
//         printWindow.document.close();
//         printWindow.print();  // Trigger the print dialog
//     };

//     return (
//         <>
//             <Sidebar />
//             <div id="main">
//                 <Container>
//                     <h3>Panthullu Scanning QR Report</h3>
//                     <Row className="mt-3">
//                         <Col md={5}>
//                             <Form.Group>
//                                 <Form.Label>From Date</Form.Label>
//                                 <Form.Control
//                                     type="date"
//                                     value={date_1}
//                                     onChange={(e) => setDate1(e.target.value)}
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col md={5}>
//                             <Form.Group>
//                                 <Form.Label>To Date</Form.Label>
//                                 <Form.Control
//                                     type="date"
//                                     value={date_2}
//                                     onChange={(e) => setDate2(e.target.value)}
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col md={2} className="d-flex align-items-end">
//                             <Button variant="success" onClick={handleDateSubmit}>
//                                 Submit
//                             </Button>
//                         </Col>
//                     </Row>

//                     <Row className="mt-3">
//                         <Col>
//                             {/* Wrap the table with the ID 'printable-table' */}
//                             <div id="printable-table">
//                                 <Table striped bordered hover>
//                                     <thead>
//                                         <tr>

//                                             <th>QR Image</th>
//                                             <th>product Name</th>
//                                             <th>Units</th>
//                                             <th>Quantity</th>
//                                             <th>Price</th>




//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {loading ? (
//                                             <tr>
//                                                 <td colSpan="4">Loading...</td>
//                                             </tr>
//                                         ) : (
//                                             data.map((row, index) => (
//                                                 <tr key={index}>


//                                                     <td>
//                                                         <img src={row.qrimg} alt="QR Code" width="100" height="100" />
//                                                     </td>

//                                                     <td>{row.cat_name}</td>
//                                                     <td>{row.units}</td>
//                                                     <td>{row.quantity}</td>
//                                                     <td>{row.pricee}</td>




//                                                 </tr>
//                                             ))
//                                         )}
//                                     </tbody>
//                                 </Table>
//                             </div>

//                             <div className="text-center">
//                                 <Button variant="primary" onClick={handleGeneratePDF}>
//                                     Print
//                                 </Button>
//                             </div>
//                         </Col>
//                     </Row>
//                 </Container>
//             </div>
//         </>
//     );
// }

// export default StockReport;


// import React, { useState } from 'react';
// import axios from 'axios';
// import { Button, Table, Form, Container, Row, Col } from 'react-bootstrap';
// import Sidebar from '../Sidebar/Sidebar';

// function StockReport() {
//     const [date_1, setDate1] = useState('');
//     const [date_2, setDate2] = useState('');
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [groupedData, setGroupedData] = useState([]);

//     const handleDateSubmit = async () => {
//         if (date_1 && date_2) {
//             setLoading(true);
//             try {
//                 const response = await axios.post('https://posapi.emedha.in/api/getPurchasesWithStock', {
//                     date_1,
//                     date_2
//                 });
//                 setData(response.data);
//                 groupDataByPurchasesid(response.data); // Group data by purchasesid
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//             setLoading(false);
//         } else {
//             alert('Please select both dates');
//         }
//     };

//     const groupDataByPurchasesid = (data) => {
//         const grouped = data.reduce((result, item) => {
//             const { purchasesid } = item;
//             if (!result[purchasesid]) {
//                 result[purchasesid] = [];
//             }
//             result[purchasesid].push(item);
//             return result;
//         }, {});

//         const result = Object.keys(grouped).map((purchasesid) => {
//             const items = grouped[purchasesid];
//             const totalPrice = items.reduce((acc, item) => acc + parseFloat(item.pricee || 0), 0);
//             const totalScannedPrice = items.reduce((acc, item) => item.flag === 0 ? acc + parseFloat(item.pricee || 0) : acc, 0);
//             const totalBillAmount = items.length > 0 ? parseFloat(items[0].billamount || 0) : 0;
//             const remainingPrice = totalBillAmount - totalScannedPrice;

//             return {
//                 purchasesid,
//                 totalPrice,
//                 totalScannedPrice,
//                 remainingPrice,
//                 items,
//             };
//         });

//         setGroupedData(result); // Update state with grouped data
//     };

//     const handleGeneratePDF = () => {
//         const printContent = document.getElementById('printable-table').innerHTML;
//         const printWindow = window.open('', '', 'height=600,width=800');
//         printWindow.document.write('<html><head><title>Print</title><style>');
//         printWindow.document.write(`
//             body { font-family: Arial, sans-serif; }
//             table { width: 100%; border-collapse: collapse; }
//             table, th, td { border: 1px solid black; }
//             th, td { padding: 8px; text-align: left; }
//         `);
//         printWindow.document.write('</style></head><body>');
//         printWindow.document.write(printContent);
//         printWindow.document.write('</body></html>');
//         printWindow.document.close();
//         printWindow.print();
//     };

//     return (
//         <>
//             <Sidebar />
//             <div id="main">
//                 <Container>
//                     <h3>Panthullu Scanning QR Report</h3>
//                     <Row className="mt-3">
//                         <Col md={5}>
//                             <Form.Group>
//                                 <Form.Label>From Date</Form.Label>
//                                 <Form.Control
//                                     type="date"
//                                     value={date_1}
//                                     onChange={(e) => setDate1(e.target.value)}
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col md={5}>
//                             <Form.Group>
//                                 <Form.Label>To Date</Form.Label>
//                                 <Form.Control
//                                     type="date"
//                                     value={date_2}
//                                     onChange={(e) => setDate2(e.target.value)}
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col md={2} className="d-flex align-items-end">
//                             <Button variant="success" onClick={handleDateSubmit}>
//                                 Submit
//                             </Button>
//                         </Col>
//                     </Row>

//                     <Row className="mt-3">
//                         <Col>
//                             <div id="printable-table">
//                                 {loading ? (
//                                     <div>Loading...</div>
//                                 ) : (
//                                     groupedData.map((group, index) => (
//                                         <div key={index}>
//                                             <h4>Purchases ID: {group.purchasesid}</h4>
//                                             <Table striped bordered hover>
//                                                 <thead>
//                                                     <tr>
//                                                         <th>QR Image</th>
//                                                         <th>Product Name</th>
//                                                         <th>Units</th>
//                                                         <th>Quantity</th>
//                                                         <th>Price</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {group.items.map((row, idx) => (
//                                                         <tr key={idx}>
//                                                             <td>
//                                                                 <img
//                                                                     src={row.qrimg}
//                                                                     alt="QR Code"
//                                                                     width="100"
//                                                                     height="100"
//                                                                 />
//                                                             </td>
//                                                             <td>{row.cat_name}</td>
//                                                             <td>{row.units}</td>
//                                                             <td>{row.quantity}</td>
//                                                             <td>{row.pricee}</td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </Table>

//                                             <div className="text-center mt-4">
//                                                 <h5>Total Price: {group.totalPrice}</h5>
//                                                 <h5>Total Scanned Price (flag 0): {group.totalScannedPrice}</h5>
//                                                 <h5>Remaining Price: {group.remainingPrice}</h5>
//                                             </div>
//                                         </div>
//                                     ))
//                                 )}
//                             </div>

//                             <div className="text-center mt-4">
//                                 <Button variant="primary" onClick={handleGeneratePDF}>
//                                     Print
//                                 </Button>
//                             </div>
//                         </Col>
//                     </Row>
//                 </Container>
//             </div>
//         </>
//     );
// }

// export default StockReport;



import React, { useState } from 'react';
import axios from 'axios';
import { Button, Table, Form, Container, Row, Col } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';

function StockReport() {
    const [date_1, setDate1] = useState('');
    const [date_2, setDate2] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [groupedData, setGroupedData] = useState([]);

    const handleDateSubmit = async () => {
        if (date_1 && date_2) {
            setLoading(true);
            try {
                const response = await axios.post('https://posapi.emedha.in/api/getPurchasesWithStock', {
                    date_1,
                    date_2
                });
                setData(response.data);
                groupDataByPurchasesid(response.data); // Group data by purchasesid
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            setLoading(false);
        } else {
            alert('Please select both dates');
        }
    };

    const groupDataByPurchasesid = (data) => {
        const grouped = data.reduce((result, item) => {
            const { purchasesid } = item;
            if (!result[purchasesid]) {
                result[purchasesid] = [];
            }
            result[purchasesid].push(item);
            return result;
        }, {});

        const result = Object.keys(grouped).map((purchasesid) => {
            const items = grouped[purchasesid];
            const totalPrice = items.reduce((acc, item) => acc + parseFloat(item.pricee || 0), 0);
            const totalScannedPriceFlag0 = items.reduce((acc, item) => item.flag === 0 ? acc + parseFloat(item.pricee || 0) : acc, 0);
            const totalScannedPriceFlag1 = items.reduce((acc, item) => item.flag === 1 ? acc + parseFloat(item.pricee || 0) : acc, 0);
            const totalBillAmount = items.length > 0 ? parseFloat(items[0].billamount || 0) : 0;

            let remainingPrice = totalBillAmount;

            // Adjust remaining price based on flag 0 and flag 1 logic
            if (totalScannedPriceFlag0 === 0) {
                // If no scanned price for flag 0, show remaining as total bill amount
                remainingPrice = totalBillAmount;
            } else if (totalScannedPriceFlag1 > 0) {
                // If flag 1 exists, subtract from total bill amount
                remainingPrice = totalBillAmount - totalScannedPriceFlag1;
            }

            return {
                purchasesid,
                totalPrice,
                totalScannedPriceFlag0,
                totalScannedPriceFlag1,
                remainingPrice,
                items,
            };
        });

        setGroupedData(result); // Update state with grouped data
    };

    const handleGeneratePDF = () => {
        const printContent = document.getElementById('printable-table').innerHTML;
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>Print</title><style>');
        printWindow.document.write(`
            body { font-family: Arial, sans-serif; }
            table { width: 100%; border-collapse: collapse; }
            table, th, td { border: 1px solid black; }
            th, td { padding: 8px; text-align: left; }
        `);
        printWindow.document.write('</style></head><body>');
        printWindow.document.write(printContent);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <>
            <Sidebar />
            <div id="main">
                <Container>
                    <h3>Stock QR Report</h3>
                    <Row className="mt-3">
                        <Col md={5}>
                            <Form.Group>
                                <Form.Label>From Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={date_1}
                                    onChange={(e) => setDate1(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={5}>
                            <Form.Group>
                                <Form.Label>To Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={date_2}
                                    onChange={(e) => setDate2(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2} className="d-flex align-items-end">
                            <Button variant="success" onClick={handleDateSubmit}>
                                Submit
                            </Button>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <div id="printable-table">
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    groupedData.map((group, index) => (
                                        <div key={index}>
                                            <h4>Purchases ID: {group.purchasesid}</h4>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>QR Image</th>
                                                        <th>Product Name</th>
                                                        <th>Units</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                        <th>Scanned</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {group.items.map((row, idx) => (
                                                        <tr key={idx}>
                                                            <td>
                                                                <img
                                                                    src={row.qrimg}
                                                                    alt="QR Code"
                                                                    width="100"
                                                                    height="100"
                                                                />
                                                            </td>
                                                            <td>{row.cat_name}</td>
                                                            <td>{row.units}</td>
                                                            <td>{row.quantity}</td>
                                                            <td>{row.pricee}</td>
                                                            <td>
                                                                {row.flag === 0 ? (
                                                                    <i className="fa fa-qrcode" style={{ color: 'red', textDecoration: 'line-through' }}></i>  // Strikethrough red QR code if flag is 0
                                                                ) : (
                                                                    <i className="fa fa-qrcode" style={{ color: 'green' }}></i>  // Green QR code if flag is 1
                                                                )}
                                                            </td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>

                                            <div className="text-center mt-4">
                                            <h5>Total Stock Price from {group.items[0].cat_name}: {group.totalPrice}</h5>

                                                <h5 style={{color:'red'}}>Not Scanned Price from {group.items[0].cat_name} : {group.totalScannedPriceFlag0}</h5>
                                                <h5 style={{color:'green'}}> Scanned Price from {group.items[0].cat_name}: {group.totalScannedPriceFlag1}</h5>

                                                {group.totalScannedPriceFlag0 === 0 && (
                                                    <h5> Total Remaining Price from {group.items[0].cat_name}: {group.remainingPrice}</h5>
                                                )}

                                                {group.totalScannedPriceFlag1 > 0 && (
                                                    <h5>Total Remaining Price from {group.items[0].cat_name}: {group.remainingPrice}</h5>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>

                            <div className="text-center mt-4">
                                <Button variant="primary" onClick={handleGeneratePDF}>
                                    Print
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default StockReport;
