import React, { useEffect, useState } from 'react';
import '../Sidebar/Sidebar.css';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';
// import './PC.css'



const ProductUnit = () => {

    const [unitName, setUnitName] = useState('');
    const [unit, setUnit] = useState([]);
    const [entriesPerPage, setEntriesPerPage] = useState(5); // Set to 5 entries per page
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Track the current page
    const [showModal, setShowModal] = useState(false); // Track modal visibility
    const [editCategoryId, setEditCategoryId] = useState(null); // Track the category ID being edited
    const [editCategoryName, setEditCategoryName] = useState(''); // Track the category name for editing

    const fetchUnit = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/unit');
            setUnit(response.data);
            setLoading(false);
        } catch (err) {
            setError('Error fetching Unit');
            setLoading(false);
        }
    };

    useEffect (() => {
        fetchUnit();
    }, []);

    const handleUnitChange = (event) => {
        setUnitName(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredUnit = unit.filter((unit) =>
        unit.nm_unit && unit.nm_unit.trim().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredUnit.length / entriesPerPage);

    const currentUnit = filteredUnit.slice(
        (currentPage - 1) * entriesPerPage,
        currentPage * entriesPerPage
    );

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!unitName.trim()) {
            alert('Unit name is required');
            return;
        }

        try {
         
            const response = await axios.post('https://posapi.emedha.in/api/post/unit', {
                nm_unit: unitName.trim(),
            });

      
            setMessage(response.data.message); 
            setUnitName('');
            fetchUnit()
        } catch (err) {
            console.error('Error adding category:', err);
            setError('Error adding category'); 
        }
    };

    const handleDelete = async (unitId) => {
        try {
            const response = await axios.delete(`https://posapi.emedha.in/api/delete/unit/${unitId}`);
            alert(response.data.message);  
            fetchUnit();  
        } catch (err) {
            alert('Error deleting category');
            console.error(err);
        }
    };

    const handleUpdate = async (unitId, updatedUnitName) => {
        try {
            const response = await axios.put(`https://posapi.emedha.in/api/update/unit/${unitId}`, {
                nm_unit: updatedUnitName,
            });
            alert(response.data.message);  
            fetchUnit();  
            setShowModal(false);  
        } catch (err) {
            alert('Error updating category');
            console.error(err);
        }
    };

    const openEditModal = (unitId, catName) => {
        setEditCategoryId(unitId);
        setEditCategoryName(catName);
        setShowModal(true);
    };

    const closeEditModal = () => {
        setShowModal(false);
        setEditCategoryId(null);
        setEditCategoryName('');
    };

    return (
        <>
            <Sidebar />
            <div id="main">
                <div className="container mt-4">
                    <div className="row">
                    
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header">Unit Name</div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="floating-label-container">
                                            <input
                                                type="text"
                                                className="floating-input"
                                                id="unitName"
                                                value={unitName}
                                                onChange={handleUnitChange}
                                                placeholder=" "
                                            />
                                            <label htmlFor="unitName" className="floating-label">
                                                Enter Unit
                                            </label>
                                        </div>
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">Unit List</div>
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <label>
                                                Show
                                                <select
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    style={{ width: 'auto', display: 'inline-block' }}
                                                    value={entriesPerPage}
                                                    onChange={(e) => setEntriesPerPage(Number(e.target.value))}
                                                >
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                </select>
                                                entries
                                            </label>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <label>
                                                Search:
                                                <input
                                                    type="search"
                                                    className="form-control form-control-sm"
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Unit Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentUnit.map((unit, index) => (
                                                <tr key={unit.id_unit}>
                                                    <td>{(currentPage - 1) * entriesPerPage + index + 1}</td>
                                                    <td>{unit.nm_unit}</td>
                                                    <td>
                                                        <button
                                                            className="btn btn-info btn-sm"
                                                            onClick={() => openEditModal(unit.id_unit, unit.nm_unit)}
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-danger btn-sm ml-2"
                                                            onClick={() => handleDelete(unit.id_unit)}
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <p>
                                                Showing {(currentPage - 1) * entriesPerPage + 1} to{' '}{Math.min(currentPage * entriesPerPage, filteredUnit.length)} of {filteredUnit.length} entries
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <nav>
                                                <ul className="pagination">
                                                    <li
                                                        className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                                                    >
                                                        <a
                                                            className="page-link"
                                                            href="#"
                                                            onClick={() => handlePageClick(currentPage - 1)}
                                                        >
                                                            Previous
                                                        </a>
                                                    </li>
                                                    {[...Array(totalPages)].map((_, index) => (
                                                        <li
                                                            key={index + 1}
                                                            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                                        >
                                                            <a
                                                                className="page-link"
                                                                href="#"
                                                                onClick={() => handlePageClick(index + 1)}
                                                            >
                                                                {index + 1}
                                                            </a>
                                                        </li>
                                                    ))}
                                                    <li
                                                        className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                                                    >
                                                        <a
                                                            className="page-link"
                                                            href="#"
                                                            onClick={() => handlePageClick(currentPage + 1)}
                                                        >
                                                            Next
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="modal fade show" style={{ display: 'block' }} onClick={closeEditModal}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()}> {/* Stop propagation here */}
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Unit</h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={closeEditModal}
                                >
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Unit Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={editCategoryName}
                                        onChange={(e) => setEditCategoryName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={closeEditModal}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleUpdate(editCategoryId, editCategoryName)}
                                >
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};
export default ProductUnit;
