// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Sidebar from '../Sidebar/Sidebar';

// function PoojaBooking() {
//   const [poojaList, setPoojaList] = useState([]);
//   const [bookingData, setBookingData] = useState({
//     mobileNumber: '',
//     customerName: '',
//     gothram: '',
//     poojaDate: '',
//     poojaDetails: [],
//   });

//   const [count, setCount] = useState(0);

//   useEffect(() => {
//     // Fetch pooja names from the backend
//     axios.get('https://posapi.emedha.in/api/pooja')
//       .then(response => {
//         setPoojaList(response.data);
//       })
//       .catch(error => {
//         console.error("Error fetching pooja data", error);
//       });
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setBookingData({
//       ...bookingData,
//       [name]: value,
//     });
//   };

//   const handlePoojaChange = (index, e) => {
//     const { name, value } = e.target;
//     const updatedPoojaDetails = [...bookingData.poojaDetails];
//     updatedPoojaDetails[index] = { ...updatedPoojaDetails[index], [name]: value };
//     setBookingData({ ...bookingData, poojaDetails: updatedPoojaDetails });
//   };

//   const addPoojaDetail = () => {
//     setCount(count + 1);
//     setBookingData({
//       ...bookingData,
//       poojaDetails: [
//         ...bookingData.poojaDetails,
//         { poojaName: '', amount: '' }
//       ]
//     });
//   };

//   const removePoojaDetail = (index) => {
//     const updatedPoojaDetails = bookingData.poojaDetails.filter((_, i) => i !== index);
//     setBookingData({ ...bookingData, poojaDetails: updatedPoojaDetails });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     axios.post('https://posapi.emedha.in/api/create', bookingData)
//       .then(response => {
//         toast.success("Receipt generated successfully");
//         setBookingData({
//           mobileNumber: '',
//           customerName: '',
//           gothram: '',
//           poojaDate: '',
//           poojaDetails: [],
//         });
//       })
//       .catch(error => {
//         toast.error("Error generating receipt");
//         console.error("Error:", error);
//       });
//   };

//   return (
//     <>
//     <Sidebar />
//     <div id='main' >
//       <h1>Pooja Master - Add Bookings</h1>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Mobile Number:</label>
//           <input
//             type="text"
//             name="mobileNumber"
//             value={bookingData.mobileNumber}
//             onChange={handleInputChange}
//             required
//           />
//         </div>

//         <div>
//           <label>Customer Name:</label>
//           <input
//             type="text"
//             name="customerName"
//             value={bookingData.customerName}
//             onChange={handleInputChange}
//             required
//           />
//         </div>

//         <div>
//           <label>Gothram:</label>
//           <input
//             type="text"
//             name="gothram"
//             value={bookingData.gothram}
//             onChange={handleInputChange}
//             required
//           />
//         </div>

//         <div>
//           <label>Pooja Date:</label>
//           <input
//             type="date"
//             name="poojaDate"
//             value={bookingData.poojaDate}
//             onChange={handleInputChange}
//             required
//           />
//         </div>

//         <h3>Select Pooja</h3>
//         {bookingData.poojaDetails.map((pooja, index) => (
//           <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
//             <select
//               name="poojaName"
//               value={pooja.poojaName}
//               onChange={(e) => handlePoojaChange(index, e)}
//               required
//             >
//               <option value="">--Select Pooja--</option>
//               {poojaList.map(pooja => (
//                 <option key={pooja.pid} value={pooja.pname}>
//                   {pooja.pname}
//                 </option>
//               ))}
//             </select>
//             <input
//               type="text"
//               name="amount"
//               value={pooja.amount}
//               onChange={(e) => handlePoojaChange(index, e)}
//               placeholder="Enter Amount"
//               required
//             />
//             <button type="button" onClick={() => removePoojaDetail(index)}>Remove</button>
//           </div>
//         ))}
//         <button type="button" onClick={addPoojaDetail}>Add Pooja</button>
//         <button type="submit">Generate Receipt</button>
//       </form>
//       <ToastContainer />
//     </div>
//     </>
//   );
// }

// export default PoojaBooking;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Swal from 'react-sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';
// // import './PoojaBooking.css';

// import Sidebar from '../Sidebar/Sidebar'

// export default function PoojaBooking() {
//   const [mobileNumber, setMobileNumber] = useState('');
//   const [customerName, setCustomerName] = useState('');
//   const [gothram, setGothram] = useState('');
//   const [poojadate, setPoojadate] = useState('');
//   const [poojaDetails, setPoojaDetails] = useState([{ poojaname: '', amount: '' }]);
//   const [poojaOptions, setPoojaOptions] = useState([]);

//   // Fetch pooja options on mount
//   useEffect(() => {
//     axios.get('http://localhost:5000/api/pooja')
//       .then(response => setPoojaOptions(response.data))
//       .catch(error => console.error(error));
//   }, []);

//   // Fetch customer details when mobile number is entered
//   const fetchCustomerDetails = (mobile) => {
//     if (mobile.length === 10) {
//       axios.get(`http://localhost:5000/api/customer?mobile=${mobile}`)
//         .then(response => {
//           const data = response.data;
//           setCustomerName(data.customerName || '');
//           setGothram(data.gothram || '');
//         })
//         .catch(error => console.error(error));
//     }
//   };

//   const handleMobileChange = (e) => {
//     setMobileNumber(e.target.value);
//     fetchCustomerDetails(e.target.value);
//   };

//   // Fetch amount when pooja is selected
//   const handlePoojaChange = (e, index) => {
//     const { name, value } = e.target;
//     const newPoojaDetails = [...poojaDetails];
//     newPoojaDetails[index][name] = value;
//     setPoojaDetails(newPoojaDetails);

//     if (name === 'poojaname') {
//       axios.get(`http://localhost:5000/api/pooja/amount?pid=${value}`)
//         .then(response => {
//           const amount = response.data.pprice || '';
//           newPoojaDetails[index].amount = amount;
//           setPoojaDetails(newPoojaDetails);
//         })
//         .catch(error => console.error(error));
//     }
//   };

//   const handleAddOrder = () => {
//     setPoojaDetails([...poojaDetails, { poojaname: '', amount: '' }]);
//   };

//   const handleRemoveOrder = (index) => {
//     const newPoojaDetails = poojaDetails.filter((_, i) => i !== index);
//     setPoojaDetails(newPoojaDetails);
//   };

//   const handleSubmit = () => {
//     const requestData = {
//       mobileNumber,
//       customerName,
//       gothram,
//       poojadate,
//       poojaDetails
//     };

//     axios.post('http://localhost:5000/api/pooja', requestData)
//       .then(response => {
//         if (response.data.success) {
//           Swal.fire({
//             title: 'Success',
//             text: 'Receipt generated',
//             icon: 'success',
//             confirmButtonText: 'Continue',
//           });
//         }
//       })
//       .catch(error => console.error(error));
//   };

//   return (
//     <>
//     <Sidebar />
//     <div id='main'>
//       <h1>Pooja Master</h1>

//       <div>
//         <label>Mobile Number</label>
//         <input 
//           type="text" 
//           value={mobileNumber} 
//           onChange={handleMobileChange} 
//           placeholder="Enter Mobile Number"
//         />
//       </div>

//       <div>
//         <label>Customer Name</label>
//         <input 
//           type="text" 
//           value={customerName} 
//           disabled 
//           placeholder="Customer Name"
//         />
//       </div>

//       <div>
//         <label>Gothram</label>
//         <input 
//           type="text" 
//           value={gothram} 
//           disabled 
//           placeholder="Gothram"
//         />
//       </div>

//       <div>
//         <label>Pooja Date</label>
//         <input 
//           type="date" 
//           value={poojadate} 
//           onChange={(e) => setPoojadate(e.target.value)} 
//         />
//       </div>

//       <div>
//         <h3>Add Bookings</h3>
//         {poojaDetails.map((item, index) => (
//           <div key={index}>
//             <select
//               name="poojaname"
//               value={item.poojaname}
//               onChange={(e) => handlePoojaChange(e, index)}
//             >
//               <option value="">--Select Pooja--</option>
//               {poojaOptions.map((pooja) => (
//                 <option key={pooja.pid} value={pooja.pname}>
//                   {pooja.pname}
//                 </option>
//               ))}
//             </select>
//             <input
//               type="text"
//               name="amount"
//               value={item.amount}
//               onChange={(e) => handlePoojaChange(e, index)}
//               placeholder="Amount"
//               disabled
//             />
//             <button className="remove-btn" onClick={() => handleRemoveOrder(index)}>Remove</button>
//           </div>
//         ))}
//         <button onClick={handleAddOrder}>Add Order</button>
//       </div>

//       <button onClick={handleSubmit}>Generate Receipt</button>
//     </div>
//     </>
//   );
// }



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// import 'sweetalert2/dist/sweetalert2.min.css';
// import Sidebar from '../Sidebar/Sidebar'

// export default function PoojaBooking() {
//   const [mobileNumber, setMobileNumber] = useState('');
//   const [customerName, setCustomerName] = useState('');
//   const [gothram, setGothram] = useState('');
//   const [poojadate, setPoojadate] = useState('');
//   const [poojaDetails, setPoojaDetails] = useState([{ poojaname: '', amount: '', pid: '' }]);
//   const [poojaOptions, setPoojaOptions] = useState([]);
//   const [isExistingCustomer, setIsExistingCustomer] = useState(true); 

//   useEffect(() => {
//     axios.get('https://posapi.emedha.in/api/pooja/booking')
//       .then(response => setPoojaOptions(response.data))
//       .catch(error => console.error(error));
//   }, []);

//   const fetchCustomerDetails = (mobile) => {
//     if (mobile.length === 10) {
//       axios.get(`https://posapi.emedha.in/api/customer/booking?mobile=${mobile}`)
//         .then(response => {
//           const data = response.data;
//           if (data) {
//             setCustomerName(data.customerName || '');
//             setGothram(data.gothram || '');
//             setIsExistingCustomer(true);  
//           } else {
//             setIsExistingCustomer(false); 
//             setCustomerName('');
//             setGothram('');
//           }
//         })
//         .catch(error => console.error(error));
//     }
//   };

//   const handleMobileChange = (e) => {
//     setMobileNumber(e.target.value);
//     fetchCustomerDetails(e.target.value);
//   };

//   const handlePoojaChange = (e, index) => {
//     const { name, value } = e.target;
//     const newPoojaDetails = [...poojaDetails];
//     newPoojaDetails[index][name] = value;
//     setPoojaDetails(newPoojaDetails);

//     if (name === 'poojaname') {
//       const selectedPooja = poojaOptions.find(pooja => pooja.pname === value);

//       if (selectedPooja) {
//         newPoojaDetails[index].amount = selectedPooja.pprice; 
//         newPoojaDetails[index].pid = selectedPooja.pid;
//       } else {
//         newPoojaDetails[index].amount = '';
//         newPoojaDetails[index].pid = '';
//       }
//     }

//     setPoojaDetails(newPoojaDetails);
//   };

//   const handleAddOrder = () => {
//     setPoojaDetails([...poojaDetails, { poojaname: '', amount: '', pid: '' }]);
//   };

//   const handleRemoveOrder = (index) => {
//     const newPoojaDetails = poojaDetails.filter((_, i) => i !== index);
//     setPoojaDetails(newPoojaDetails);
//   };


//   const handleSubmit = () => {
//     poojaDetails.forEach((pooja) => {
//       const requestData = {
//         mobileNumber,
//         customerName,
//         gothram,
//         poojadate,
//         amount: pooja.amount, 
//         poojaname: pooja.poojaname,  
//       };

//       // Send the data to backend
//       axios.post('https://posapi.emedha.in/api/pooja/booking', requestData)
//         .then(response => {
//           if (response.data.success) {

//             alert('Success: Booking successfully added.');

//           setMobileNumber('');
//           setCustomerName('');
//           setGothram('');
//           setPoojadate('');
//           setPoojaDetails([{ poojaname: '', amount: '', pid: '' }]);  

//           }
//         })
//         .catch(error => {
//           console.error(error);

//           alert('Error: There was an error processing your request.');
//         });
//     });
//   };


//   return (
//     <>
//       <Sidebar />
//       <div id='main'>
//         <h1>Pooja Master</h1>

//         {/* Mobile Number Section */}
//         <div>
//           <label>Mobile Number</label>
//           <input
//             type="text"
//             value={mobileNumber}
//             onChange={handleMobileChange}
//             placeholder="Enter Mobile Number"
//           />
//         </div>

//         <div>
//           <label>Customer Name</label>
//           <input
//             type="text"
//             value={customerName}
//             onChange={(e) => setCustomerName(e.target.value)}
//             placeholder="Enter Customer Name"

//           />
//         </div>

//         <div>
//           <label>Gothram</label>
//           <input
//             type="text"
//             value={gothram}
//             onChange={(e) => setGothram(e.target.value)}
//             placeholder="Enter Gothram"

//           />
//         </div>
//         <div>
//           <label>Pooja Date</label>
//           <input
//             type="date"
//             value={poojadate}
//             onChange={(e) => setPoojadate(e.target.value)}
//           />
//         </div>
//         <div>
//           <h3>Add Bookings</h3>
//           {poojaDetails.map((item, index) => (
//             <div key={index}>
//               <select
//                 name="poojaname"
//                 value={item.poojaname}
//                 onChange={(e) => handlePoojaChange(e, index)}
//               >
//                 <option value="">--Select Pooja--</option>
//                 {poojaOptions.map((pooja) => (
//                   <option key={pooja.pid} value={pooja.pname}>
//                     {pooja.pname}
//                   </option>
//                 ))}
//               </select>
//               <input
//                 type="text"
//                 name="amount"
//                 value={item.amount}
//                 onChange={(e) => handlePoojaChange(e, index)}
//                 placeholder="Amount"
//                 disabled
//               />
//               <button className="remove-btn" onClick={() => handleRemoveOrder(index)}>Remove</button>
//             </div>
//           ))}
//           <button onClick={handleAddOrder}>Add Order</button>
//         </div>
//         <button onClick={handleSubmit}>Generate Receipt</button>
//       </div>
//     </>
//   );
// }



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import ReactSelect from 'react-select';



export default function PoojaBooking() {
  const [mobileNumber, setMobileNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [gothram, setGothram] = useState('');
  const [poojadate, setPoojadate] = useState('');
  const [poojaDetails, setPoojaDetails] = useState([{ poojaname: '', amount: '', pid: '' }]);
  const [poojaOptions, setPoojaOptions] = useState([]);
  const [isExistingCustomer, setIsExistingCustomer] = useState(true);

  useEffect(() => {
    axios.get('https://posapi.emedha.in/api/pooja/booking')
      .then(response => setPoojaOptions(response.data))
      .catch(error => console.error(error));
  }, []);

  const fetchCustomerDetails = (mobile) => {
    if (mobile.length === 10) {
      axios.get(`https://posapi.emedha.in/api/customer/booking?mobile=${mobile}`)
        .then(response => {
          const data = response.data;
          if (data) {
            setCustomerName(data.customerName || '');
            setGothram(data.gothram || '');
            setIsExistingCustomer(true);
          } else {
            setIsExistingCustomer(false);
            setCustomerName('');
            setGothram('');
          }
        })
        .catch(error => console.error(error));
    }
  };

  const handleMobileChange = (e) => {
    setMobileNumber(e.target.value);
    fetchCustomerDetails(e.target.value);
  };

  const handlePoojaChange = (e, index) => {
    const { name, value } = e.target;
    const newPoojaDetails = [...poojaDetails];
    newPoojaDetails[index][name] = value;
    setPoojaDetails(newPoojaDetails);

    if (name === 'poojaname') {
      const selectedPooja = poojaOptions.find(pooja => pooja.pname === value);

      if (selectedPooja) {
        newPoojaDetails[index].amount = selectedPooja.pprice;
        newPoojaDetails[index].pid = selectedPooja.pid;
      } else {
        newPoojaDetails[index].amount = '';
        newPoojaDetails[index].pid = '';
      }
    }

    setPoojaDetails(newPoojaDetails);
  };

  const handleAddOrder = () => {
    setPoojaDetails([...poojaDetails, { poojaname: '', amount: '', pid: '' }]);
  };

  const handleRemoveOrder = (index) => {
    const newPoojaDetails = poojaDetails.filter((_, i) => i !== index);
    setPoojaDetails(newPoojaDetails);
  };

  const handleSubmit = () => {
    poojaDetails.forEach((pooja) => {
      const requestData = {
        mobileNumber,
        customerName,
        gothram,
        poojadate,
        amount: pooja.amount,
        poojaname: pooja.poojaname,
      };

      axios.post('https://posapi.emedha.in/api/pooja/booking', requestData)
        .then(response => {
          if (response.data.success) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Booking successfully added.',
            }).then(() => {
              setMobileNumber('');
              setCustomerName('');
              setGothram('');
              setPoojadate('');
              setPoojaDetails([{ poojaname: '', amount: '', pid: '' }]);
            });
          }
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an error processing your request.',
          });
        });
    });
  };

  return (
    <>
      <Sidebar />
      <Container id="main" className="mt-4">
        <h1 className="text-center mb-4">Pooja Master</h1>

        <Form>
          {/* Mobile Number Section */}
          <Form.Group as={Row} controlId="formMobile">
            <Form.Label column sm={2}>Mobile Number</Form.Label>
            <Col sm={8} md={6} lg={4}>
              <Form.Control
                type="text"
                style={{padding:'5px'}}
                value={mobileNumber}
                onChange={handleMobileChange}
                placeholder="Enter Mobile Number"
              />
            </Col>
          </Form.Group>

          {/* Customer Name Section */}
          <Form.Group as={Row} controlId="formCustomerName">
            <Form.Label column sm={2}>Customer Name</Form.Label>
            <Col sm={10} md={6} lg={4}>
              <Form.Control
                type="text" style={{padding:'5px'}}
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                placeholder="Enter Customer Name"
              />
            </Col>
          </Form.Group>

          {/* Gothram Section */}
          <Form.Group as={Row} controlId="formGothram">
            <Form.Label column sm={2}>Gothram</Form.Label>
            <Col sm={10} md={6} lg={4}>
              <Form.Control
                type="text" style={{padding:'5px'}}
                value={gothram}
                onChange={(e) => setGothram(e.target.value)}
                placeholder="Enter Gothram"
              />
            </Col>
          </Form.Group>

          {/* Pooja Date Section */}
          <Form.Group as={Row} controlId="formPoojaDate">
            <Form.Label column sm={2}>Pooja Date</Form.Label>
            <Col sm={10} md={6} lg={4}>
              <Form.Control
                type="date" style={{padding:'5px'}}
                value={poojadate}
                onChange={(e) => setPoojadate(e.target.value)}
              />
            </Col>
          </Form.Group>

          {/* Add Bookings Section */}
          <h3>Add Bookings</h3>
          {poojaDetails.map((item, index) => (
            <Row key={index} className="mb-3">
              <Col sm={10} md={6} lg={4}>
                {/* <Form.Control
                  as="select"
                  name="poojaname"
                  value={item.poojaname}
                  onChange={(e) => handlePoojaChange(e, index)}
                >
                  <option value="">--Select Pooja--</option>
                  {poojaOptions.map((pooja) => (
                    <option key={pooja.pid} value={pooja.pname}>
                      {pooja.pname}
                    </option>
                  ))}
                </Form.Control> */}
                <Form.Group as={Row} controlId="formPoojaname">
                  <Form.Label column sm={4}>Pooja Name</Form.Label>
                  <Col sm={8} md={6} lg={4}>
                    <ReactSelect
                      options={poojaOptions.map(pooja => ({
                        value: pooja.pname,
                        label: pooja.pname
                      }))}
                      value={{ value: item.poojaname, label: item.poojaname }}
                      onChange={(selectedOption) => handlePoojaChange({
                        target: { name: "poojaname", value: selectedOption.value }
                      }, index)}
                      isSearchable={true}
                      styles={{
                        menu: (base) => ({
                        ...base,
                      
                       width:'215px',
                       height:'auto'
                      }),
                      control: (base) => ({
                        ...base,
                        width: '200px',  // Adjusts the width of the input field (the bar where text is entered)
                        fontSize: '14px',  // Optional: adjust the font size for the input field
                      }),
                    }}
                    
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={10} md={6} lg={4}>
                <Form.Control
                  type="text"
                  name="amount"
                  value={item.amount}
                  onChange={(e) => handlePoojaChange(e, index)}
                  placeholder="Amount"
                  disabled
                />
              </Col>
              <Col sm={3} className="d-flex justify-content-between">
                <Button variant="danger" onClick={() => handleRemoveOrder(index)}>
                  Remove
                </Button>
              </Col>
            </Row>
          ))}

          
          <Button variant="primary" onClick={handleAddOrder}>Add Order</Button>
          <br></br>
          {/* Submit Button */}
          <Button variant="success" className="mt-4 " onClick={handleSubmit}>Generate Receipt</Button>
        </Form>
      </Container>
    </>
  );
}
