// import logo from './logo.svg';
// import './App.css';
// import Home from './Components/Home/Home';
// import Sidebar from './Components/Sidebar/Sidebar';
// import Header from './Components/Header/Header';
// import { BrowserRouter, Routes, Route} from 'react-router-dom';
// import ProductCategory from './Components/Product Category/ProductCategory';
// import Footer from './Components/Header/Footer';
// import ProductUnit from './Components/Product Unit/ProductUnit';
// import PoojaManagement from './Components/PoojaManagement/PoojaManagement';
// import User from './Components/Users/User';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Order from './Components/Order/Order';
// import OrderDetail from './Components/Order/OrderDetails';
// import PoojaBooking from './Components/PoojaBooking/PoojaBooking';
// import ViewBooking from './Components/ViewBookings/ViewBooking';
// import PurchaseToStoke from './Components/PurchaseToStok/PurchaseToStoke';
// import StockDetails from './Components/ViewStoke/StokeDetails';
// import Report from './Components/Report/Report';
// import Login from './Components/Login/Login';


// function App() {
//   return (
//   <>
//     <BrowserRouter>
//     <Routes>
//     <Route path='/login' element={<Login/>}/>
//     <Route path='/' element={<Home/>}/>
   
//     <Route path='/Sidebar' element={<Sidebar/>}/>
//     <Route path='/productCategory' element={<ProductCategory/>}/>
//     <Route path='/productUnit' element={<ProductUnit/>}/>
//     <Route path='/poojaManagement' element={<PoojaManagement/>}/>

//     <Route path='/user' element={<User/>}/>
//     <Route path='/order' element={<Order/>}/>
//     <Route path='/order-details' element={<OrderDetail/>}/>

//     <Route path='/poojaBooking' element={<PoojaBooking/>}/>
//     <Route path='/viewBooking' element={<ViewBooking/>}/>
//     <Route path='/purchaseToStoke' element={<PurchaseToStoke/>}/>
    
//     <Route path='/stockDetails' element={<StockDetails/>}/>
    
//     <Route path='/report' element={<Report/>}/>
   
//      </Routes>
//   </BrowserRouter>
//   <Footer/>
// </>
   
//   );
// }

// export default App;



import './App.css';
import Home from './Components/Home/Home';
import Sidebar from './Components/Sidebar/Sidebar';
import Header from './Components/Header/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProductCategory from './Components/Product Category/ProductCategory';
import Footer from './Components/Header/Footer';
import ProductUnit from './Components/Product Unit/ProductUnit';
import PoojaManagement from './Components/PoojaManagement/PoojaManagement';
import User from './Components/Users/User';
import 'bootstrap/dist/css/bootstrap.min.css';
import Order from './Components/Order/Order';
import OrderDetail from './Components/Order/OrderDetails';
import PoojaBooking from './Components/PoojaBooking/PoojaBooking';
import ViewBooking from './Components/ViewBookings/ViewBooking';
import PurchaseToStoke from './Components/PurchaseToStok/PurchaseToStoke';
import StockDetails from './Components/ViewStoke/StokeDetails';
import Report from './Components/Report/Report';
import Login from './Components/Login/Login';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';  // Import ProtectedRoute
import Profile from './Components/Profile/Profile';
import OperatorDashboard from './Components/Home/OperatorDashboard';
import StockReport from './Components/Report/PanthulluReport';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Public Route for Login */}
          <Route path="/" element={<Login />} />
    
          <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
          <Route path="/operatorDashboard" element={<ProtectedRoute element={<OperatorDashboard />} />} />


          <Route path="/Sidebar" element={<ProtectedRoute element={<Sidebar />} />} />
          <Route path="/productCategory" element={<ProtectedRoute element={<ProductCategory />} />} />
          <Route path="/productUnit" element={<ProtectedRoute element={<ProductUnit />} />} />
          <Route path="/poojaManagement" element={<ProtectedRoute element={<PoojaManagement />} />} />
          <Route path="/user" element={<ProtectedRoute element={<User />} />} />
          <Route path="/order" element={<ProtectedRoute element={<Order />} />} />
          <Route path="/order-details" element={<ProtectedRoute element={<OrderDetail />} />} />
          <Route path="/poojaBooking" element={<ProtectedRoute element={<PoojaBooking />} />} />
          <Route path="/viewBooking" element={<ProtectedRoute element={<ViewBooking />} />} />
          <Route path="/purchaseToStoke" element={<ProtectedRoute element={<PurchaseToStoke />} />} />
          <Route path="/stockDetails" element={<ProtectedRoute element={<StockDetails />} />} />
          <Route path="/stockReport" element={<ProtectedRoute element={<StockReport />} />} />
          <Route path="/report" element={<ProtectedRoute element={<Report />} />} />
          <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
