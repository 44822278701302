// // src/StockDetails.js
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Table } from 'react-bootstrap';
// import Sidebar from '../Sidebar/Sidebar'


// const StockDetails = () => {
//     const [stockData, setStockData] = useState([]);
    
//     useEffect(() => {
//         // Fetch stock data from the back-end API
//         axios.get('https://posapi.emedha.in/api/api/stock')
//             .then(response => {
//                 setStockData(response.data);
//             })
//             .catch(error => {
//                 console.error('There was an error fetching the stock data!', error);
//             });
//     }, []);
    
//     return (
//         <>
//         <Sidebar />
//         <div id='main'>
//         <div className="container mt-5">
//             <h1>Stock Details</h1>
//             <hr />
//             <Table striped bordered hover responsive>
//                 <thead>
//                     <tr>
//                         <th>S.No</th>
//                         <th>Product Name</th>
//                         <th>Weight Per Quantity</th>
//                         <th>QR Code</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {stockData.map((item, index) => (
//                         <tr key={item.sno}>
//                             <td>{index + 1}</td>
//                             <td>{item.cat_name}</td>
//                             <td>{item.quantity}</td>
//                             <td>
//                                 <img src={item.qrimg} alt="QR Code" style={{ width: '40%' }} />
//                             </td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </Table>
//         </div>
//         </div>
//         </>
//     );
// }

// export default StockDetails;


// src/StockDetails.js




import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Pagination, DropdownButton, Dropdown } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar'

const StockDetails = () => {
    const [stockData, setStockData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10); // Default items per page
    const [totalCount, setTotalCount] = useState(0);

    // Fetch all stock data
    useEffect(() => {
        axios.get('https://posapi.emedha.in/api/api/stock')
            .then(response => {
                setStockData(response.data);
                setTotalCount(response.data.length); // Total items for pagination
            })
            .catch(error => {
                console.error('There was an error fetching the stock data!', error);
            });
    }, []);

    // Calculate the current page data to display
    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    const currentItems = stockData.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate the total number of pages
    const totalPages = Math.ceil(totalCount / perPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle items per page change
    const handlePerPageChange = (num) => {
        setPerPage(num);
        setCurrentPage(1); // Reset to the first page when perPage changes
    };

    // Pagination range logic
    const paginationRange = () => {
        const range = [];
        const maxPagesToShow = 5; // Maximum number of pages to show
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        // Adjust range if there are fewer than maxPagesToShow pages
        if (endPage - startPage + 1 < maxPagesToShow) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        // Collect the range of pages to display
        for (let i = startPage; i <= endPage; i++) {
            range.push(i);
        }

        return range;
    };

    const renderPagination = () => {
        const range = paginationRange();
        const paginationItems = [];

        // Previous button
        paginationItems.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            />
        );

        // Display first page if needed
        if (range[0] > 1) {
            paginationItems.push(
                <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
                    1
                </Pagination.Item>
            );
            paginationItems.push(<Pagination.Ellipsis key="ellipsis-left" />);
        }

        // Display the current page range
        range.forEach((page) => {
            paginationItems.push(
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        });

        // Display last page if needed
        if (range[range.length - 1] < totalPages) {
            paginationItems.push(<Pagination.Ellipsis key="ellipsis-right" />);
            paginationItems.push(
                <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        // Next button
        paginationItems.push(
            <Pagination.Next
                key="next"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            />
        );

        return paginationItems;
    };

    return (


        <>
        <Sidebar/>
        <div id='main'>
        <div className="container mt-5">
            <h1>Stock Details</h1>
            <hr />
            {/* Select items per page */}
            <div className="d-flex justify-content-between mb-3">
                <DropdownButton
                    id="dropdown-basic-button"
                    title={`Show ${perPage} items per page`}
                    onSelect={handlePerPageChange}
                >
                    <Dropdown.Item eventKey={10}>10</Dropdown.Item>
                    <Dropdown.Item eventKey={20}>20</Dropdown.Item>
                    <Dropdown.Item eventKey={30}>30</Dropdown.Item>
                    <Dropdown.Item eventKey={40}>40</Dropdown.Item>
                </DropdownButton>
            </div>

            {/* Table to display data */}
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Product Name</th>
                        <th>Weight Per Quantity</th>
                        <th>Price</th>
                        <th>QR Code</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item, index) => (
                        <tr key={item.sno}>
                            <td>{(currentPage - 1) * perPage + index + 1}</td>
                            <td>{item.cat_name}</td>
                            <td>{item.quantity}</td>
                            <td>{item.pricee}</td>
                            <td>
                                <img src={item.qrimg} alt="QR Code" style={{ width: '40%' }} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Pagination */}
            <Pagination>{renderPagination()}</Pagination>
        </div>
        </div>

        </>
    );
};

export default StockDetails;
