// import React, { useState } from "react";
// import "./Sidebar.css";
// import { FaHome, FaCog, FaUser, FaBars, FaTimes } from "react-icons/fa";
// import ganeshlogo from '../images/ganeshlogo.jpeg'
// import headertemple from '../images/headertemple.jpeg'

// const Sidebar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   // Toggle function
//   const toggleSidebar = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div>
//       <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
//         <ul>
// <img 
//     src={ganeshlogo} 
//     className="image" 
//     alt="Ganesh Logo"
//   />
//           <li>
//             <FaHome />
//             {isOpen && <span>Home</span>}
//           </li>
//           <li>
//             <FaCog />
//             {isOpen && <span>Settings</span>}
//           </li>
//           <li>
//             <FaUser />
//             {isOpen && <span>Profile</span>}
//           </li>
//         </ul>
//       </div>
//       <div className={`content ${isOpen ? "shifted" : ""}`}>
//         <button onClick={toggleSidebar} className="toggle-btn">
//           {isOpen ? <FaTimes /> : <FaBars />}
//         </button>
//         <header className="header">
//             <img src={headertemple} alt="Header" className="HeaderImag" />
//           </header>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;





// import React, { useState } from "react";
// import "./Sidebar.css";
// import headertemple from '../images/headertemple.jpeg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars, faHome, faChartLine, faUser, faEnvelope, faCog,faBox,faCreditCard, faTicketAlt} from '@fortawesome/free-solid-svg-icons';
// import ganeshlogo from '../images/ganeshlogo.jpeg'
// import { FaOm } from "react-icons/fa6";
// import { FaUnity } from "react-icons/fa";
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { GiTicket } from "react-icons/gi";
// import { faShoppingCart, faBoxOpen, faChartBar } from '@fortawesome/free-solid-svg-icons';


// function Sidebar() {
//   const [isOpen, setIsOpen] = useState(false); // State for managing the sidebar's open/closed state
//   const [isSidebarClosed, setIsSidebarClosed] = useState(false); // State for mobile/desktop sidebar view

//   const toggleNav = () => {
//     setIsOpen(!isOpen);
//     if (window.innerWidth <= 1768) {
//       setIsSidebarClosed(!isSidebarClosed);
//     }
//   };

//   return (
//     <>
//       <div id="mySidebar" className={`sidebar ${isSidebarClosed ? 'closed' : ''} ${isOpen ? 'open' : ''}`}>
//         <div className="sidebar-header">
//           <img
//             src={ganeshlogo}
//             className="h3"
//             alt="Ganesh Logo"

//           />
//           <button className="toggle-btn" onClick={toggleNav}>
//             <FaOm />
//           </button>
//         </div>
//         <a href="/home"><FontAwesomeIcon icon={faHome} style={{ margin: '10px' }} /> <span>Home</span></a>
//         <a href="/productCategory"><FontAwesomeIcon icon={faChartLine} style={{ margin: '10px' }} /> <span>Product Category</span></a>
//         <a href="/productUnit"><FontAwesomeIcon icon={faBox} style={{ margin: '10px' }} /> <span>Product Unit</span></a>
//         <a href="/poojaManagement"><FontAwesomeIcon icon={faEnvelope} style={{ margin: '10px' }} /> <span>Manage Pooja</span></a>
//         <a href="/user"><FontAwesomeIcon icon={faUser} style={{ margin: '10px' }} /> <span>Users</span></a>
//         <a href="/order"><FontAwesomeIcon icon={faCreditCard} style={{ margin: '10px' }} /> <span>Purchases</span></a>
//         <a href="/poojaBooking"><FontAwesomeIcon icon={faTicketAlt} style={{ margin: '10px' }} /> <span>Pooja Ticket Booking</span></a>
//         <a href="/viewBooking"><FontAwesomeIcon icon={faShoppingCart} style={{ margin: '10px' }} /> <span>View Bookings</span></a>
//         <a href="/purchaseToStoke"><FontAwesomeIcon icon={faBoxOpen} style={{ margin: '10px' }} /> <span>Purchase To Stoke</span></a>
//         <a href="/stockDetails"><FontAwesomeIcon icon={faChartBar} style={{ margin: '10px' }} /> <span>View Stock</span></a>
//         <a href="/report"><FontAwesomeIcon icon={faCog} style={{ margin: '10px' }} /> <span>Reports</span></a>

//       </div>

//       <div id="main" >

//         <img src={headertemple} alt="Header" className="HeaderImag" />

//       </div>



//     </>
//   );
// }

// export default Sidebar;




import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import headertemple from '../images/headertemple.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faChartLine, faUser, faEnvelope, faCog, faBox, faCreditCard, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import ganeshlogo from '../images/ganeshlogo.jpeg';
import { FaOm, FaUserCircle, FaSignOutAlt } from "react-icons/fa";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { GiTicket } from "react-icons/gi";
import { faShoppingCart, faBoxOpen, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import Swal from "sweetalert2";
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSidebarClosed, setIsSidebarClosed] = useState(false);
  const [role, setRole] = useState(null); // State to store the user's role

  useEffect(() => {
    // Fetch the role from sessionStorage on component mount
    const userRole = sessionStorage.getItem("role");
    setRole(userRole);  // Set the role state
  }, []);

  const toggleNav = () => {
    setIsOpen(!isOpen);
    if (window.innerWidth <= 1768) {
      setIsSidebarClosed(!isSidebarClosed);
    }
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();  // Clear all session storage data
    swal('Logged Out', 'You have been logged out successfully!', 'success')
      .then(() => {
        navigate('/');  // Redirect to the login page (or home page) using navigate
      });
  };

  const navigateToProfile = () => {
    navigate('/profile');  // Redirect to the profile page
  };

  return (
    <>
      <div id="mySidebar" className={`sidebar ${isSidebarClosed ? 'closed' : ''} ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <img src={ganeshlogo} className="h3" alt="Ganesh Logo" />
          <button className="toggle-btn" onClick={toggleNav}>
            <FaOm />
          </button>
        </div>



        {role === "Admin" && (
          <>
            <a href="/home"><FontAwesomeIcon icon={faHome} style={{ margin: '10px' }} /> <span>Home</span></a>
            <a href="/productCategory"><FontAwesomeIcon icon={faChartLine} style={{ margin: '10px' }} /> <span>Product Category</span></a>
            <a href="/productUnit"><FontAwesomeIcon icon={faBox} style={{ margin: '10px' }} /> <span>Product Unit</span></a>
            <a href="/poojaManagement"><FontAwesomeIcon icon={faEnvelope} style={{ margin: '10px' }} /> <span>Manage Pooja</span></a>
            <a href="/user"><FontAwesomeIcon icon={faUser} style={{ margin: '10px' }} /> <span>Users</span></a>
            <a href="/order"><FontAwesomeIcon icon={faCreditCard} style={{ margin: '10px' }} /> <span>Purchases</span></a>
            <a href="/poojaBooking"><FontAwesomeIcon icon={faTicketAlt} style={{ margin: '10px' }} /> <span>Pooja Ticket Booking</span></a>
            <a href="/viewBooking"><FontAwesomeIcon icon={faShoppingCart} style={{ margin: '10px' }} /> <span>View Bookings</span></a>
            <a href="/purchaseToStoke"><FontAwesomeIcon icon={faBoxOpen} style={{ margin: '10px' }} /> <span>Purchase To Stoke</span></a>
            <a href="/stockDetails"><FontAwesomeIcon icon={faChartBar} style={{ margin: '10px' }} /> <span>View Stock</span></a>
            <a href="/stockReport"><FontAwesomeIcon icon={faCog} style={{ margin: '10px' }} /> <span>Panthullu StockReports</span></a>
            <a href="/report"><FontAwesomeIcon icon={faCog} style={{ margin: '10px' }} /> <span>Reports</span></a>

          </>
        )}

        {role === "Operator" && (
          <> 
            <a href="/operatorDashboard"><FontAwesomeIcon icon={faHome} style={{ margin: '10px' }} /> <span>Dashboard</span></a>
       <a href="/order"><FontAwesomeIcon icon={faCreditCard} style={{ margin: '10px' }} /> <span>Purchases</span></a>

            <a href="/poojaBooking"><FontAwesomeIcon icon={faTicketAlt} style={{ margin: '10px' }} /> <span>Pooja Ticket Booking</span></a>
            <a href="/viewBooking"><FontAwesomeIcon icon={faShoppingCart} style={{ margin: '10px' }} /> <span>View Bookings</span></a>
            <a href="/purchaseToStoke"><FontAwesomeIcon icon={faBoxOpen} style={{ margin: '10px' }} /> <span>Purchase To Stoke</span></a>
            <a href="/stockDetails"><FontAwesomeIcon icon={faChartBar} style={{ margin: '10px' }} /> <span>View Stock</span></a>
            <a href="/stockReport"><FontAwesomeIcon icon={faCog} style={{ margin: '10px' }} /> <span>Panthullu StockReports</span></a>
            <a href="/report"><FontAwesomeIcon icon={faCog} style={{ margin: '10px' }} /> <span>Reports</span></a>
          </>
        )}
      </div>

      <div id="main" style={{ position: 'relative' }}>
        <img src={headertemple} alt="Header" className="HeaderImag" />

        <div style={{
          position: 'absolute',
          top: '10px',
          right: '20px',
          zIndex: 10,
        }}>
          <Dropdown align="end">
            <Dropdown.Toggle variant="link" id="dropdown-custom-components">
              <FaUserCircle size={30} color="#fff" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={navigateToProfile}>
                <FaUserCircle size={24} /> Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={handleLogout}>
                <FaSignOutAlt size={24} /> Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
