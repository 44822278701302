// // App.js (React Component)
// import React, { useState } from 'react';
// import axios from 'axios';
// import { Button, Table, Form, Container, Row, Col } from 'react-bootstrap';
// import Sidebar from '../Sidebar/Sidebar'
// function Report() {
//     const [date_1, setDate1] = useState('');
//     const [date_2, setDate2] = useState('');
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);

//     const handleDateSubmit = async () => {
//         if (date_1 && date_2) {
//             setLoading(true);
//             try {
//                 const response = await axios.post('https://posapi.emedha.in/api/api/filter', {
//                     date_1,
//                     date_2
//                 });
//                 setData(response.data);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//             setLoading(false);
//         } else {
//             alert('Please select both dates');
//         }
//     };

//     const handleGeneratePDF = async () => {
//         try {
//             const response = await axios.post('https://posapi.emedha.in/api/api/generate-pdf', {
//                 date_1,
//                 date_2
//             });

//             alert(response.data.message); // You can customize this to show a download link
//             console.log('PDF generated:', response.data.filePath);
//         } catch (error) {
//             console.error('Error generating PDF:', error);
//         }
//     };

//     return (
//         <>
//         <Sidebar />
//         <div id='main'>

       
//         <Container>
//             <Row className="mt-3">
//                 <Col md={5}>
//                     <Form.Group>
//                         <Form.Label>From Date</Form.Label>
//                         <Form.Control
//                             type="date"
//                             value={date_1}
//                             onChange={(e) => setDate1(e.target.value)}
//                         />
//                     </Form.Group>
//                 </Col>
//                 <Col md={5}>
//                     <Form.Group>
//                         <Form.Label>To Date</Form.Label>
//                         <Form.Control
//                             type="date"
//                             value={date_2}
//                             onChange={(e) => setDate2(e.target.value)}
//                         />
//                     </Form.Group>
//                 </Col>
//                 <Col md={2} className="d-flex align-items-end">
//                     <Button variant="success" onClick={handleDateSubmit}>Submit</Button>
//                 </Col>
//             </Row>

//             <Row className="mt-3">
//                 <Col>
//                     <Table striped bordered hover>
//                         <thead>
//                             <tr>
//                                 <th>Customer Name</th>
//                                 <th>Mobile Number</th>
//                                 <th>Amount</th>
//                                 <th>Pooja Name</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {loading ? (
//                                 <tr><td colSpan="4">Loading...</td></tr>
//                             ) : (
//                                 data.map((row, index) => (
//                                     <tr key={index}>
//                                         <td>{row.customerName}</td>
//                                         <td>{row.mobileNumber}</td>
//                                         <td>{row.amount}</td>
//                                         <td>{row.poojaname}</td>
//                                     </tr>
//                                 ))
//                             )}
//                         </tbody>
//                     </Table>

//                     <div className="text-center">
//                         <Button variant="primary" onClick={handleGeneratePDF}>Generate PDF</Button>
//                     </div>
//                 </Col>
//             </Row>
//         </Container>

//         </div>
//         </>
//     );
// }

// export default Report;


import React, { useState } from 'react';
import axios from 'axios';
import { Button, Table, Form, Container, Row, Col } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';

function Report() {
    const [date_1, setDate1] = useState('');
    const [date_2, setDate2] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleDateSubmit = async () => {
        if (date_1 && date_2) {
            setLoading(true);
            try {
                const response = await axios.post('https://posapi.emedha.in/api/api/filter', {
                    date_1,
                    date_2
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            setLoading(false);
        } else {
            alert('Please select both dates');
        }
    };

    const handleGeneratePDF = () => {
        const printContent = document.getElementById('printable-table').innerHTML;  // Get the table HTML content
        const printWindow = window.open('', '', 'height=600,width=800');  // Open a new print window
        printWindow.document.write('<html><head><title>Print</title><style>');
        printWindow.document.write(`
            body { font-family: Arial, sans-serif; }
            table { width: 100%; border-collapse: collapse; }
            table, th, td { border: 1px solid black; }
            th, td { padding: 8px; text-align: left; }
        `);
        printWindow.document.write('</style></head><body>');
        printWindow.document.write(printContent);  // Insert the table HTML into the print window
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();  // Trigger the print dialog
    };

    return (
        <>
            <Sidebar />
            <div id="main">
                <Container>
                <h3>Pooja Ticket Booking Report</h3>
                    <Row className="mt-3">
                   
                        <Col md={5}>
                            <Form.Group>
                                <Form.Label>From Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={date_1}
                                    onChange={(e) => setDate1(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={5}>
                            <Form.Group>
                                <Form.Label>To Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={date_2}
                                    onChange={(e) => setDate2(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2} className="d-flex align-items-end">
                            <Button variant="success" onClick={handleDateSubmit}>
                                Submit
                            </Button>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            {/* Wrap the table with the ID 'printable-table' */}
                            <div id="printable-table">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Customer Name</th>
                                            <th>Mobile Number</th>
                                            <th>Amount</th>
                                            <th>Pooja Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan="4">Loading...</td>
                                            </tr>
                                        ) : (
                                            data.map((row, index) => (
                                                <tr key={index}>
                                                    <td>{row.customerName}</td>
                                                    <td>{row.mobileNumber}</td>
                                                    <td>{row.amount}</td>
                                                    <td>{row.poojaname}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </Table>
                            </div>

                            <div className="text-center">
                                <Button variant="primary" onClick={handleGeneratePDF}>
                                    Print
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Report;
