// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const PurchaseToStoke = () => {
//   const [products, setProducts] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState('');
//   const [quantity, setQuantity] = useState('');
//   const [units, setUnits] = useState('');
//   const [qrCodes, setQrCodes] = useState([]);

//   // Fetch product data on component mount
//   useEffect(() => {
//     axios.get('https://posapi.emedha.in/api/get/products')
//       .then(response => {
//         setProducts(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching products:', error);
//       });
//   }, []);

//   const handleProductChange = (event) => {
//     setSelectedProduct(event.target.value);

//     if (event.target.value) {
//       axios.get(`https://posapi.emedha.in/api/api/product-details/${event.target.value}`)
//         .then(response => {
//           setQuantity(response.data.total);
//           setUnits(response.data.units);
//         })
//         .catch(error => {
//           console.error('Error fetching product details:', error);
//         });
//     }
//   };

//   // Handle QR code generation
//   const generateQrCodes = () => {
//     axios.post('https://posapi.emedha.in/api/api/generate-qrcodes', { productId: selectedProduct, quantity, units })
//       .then(response => {
//         setQrCodes(response.data.qrCodes);
//       })
//       .catch(error => {
//         console.error('Error generating QR codes:', error);
//       });
//   };

//   return (
//     <div>
//       <h1>Purchase to Stock</h1>
//       <div>
//         <label>Select Product:</label>
//         <select value={selectedProduct} onChange={handleProductChange}>
//           <option value="">-- Select Product --</option>
//           {products.map(product => (
//             <option key={product.cat_id} value={product.cat_id}>
//               {product.cat_name}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div>
//         <label>Quantity:</label>
//         <input type="text" value={quantity} readOnly />
//       </div>

//       <div>
//         <label>No of Packets:</label>
//         <input type="number" value={units} onChange={(e) => setUnits(e.target.value)} />
//       </div>

//       <div>
//         <button onClick={generateQrCodes}>Generate QR Codes</button>
//       </div>

//       <div>
//         <h2>Generated QR Codes:</h2>
//         {qrCodes.length > 0 && (
//           <table>
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Product Name</th>
//                 <th>Weight Per Quantity</th>
//                 <th>QR Code</th>
//               </tr>
//             </thead>
//             <tbody>
//               {qrCodes.map((qr, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{qr.productName}</td>
//                   <td>{qr.quantity}</td>
//                   <td><img src={qr.qrCodePath} alt="QR" style={{ width: '40%' }} /></td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         )}
//       </div>
//     </div>
//   );
// };

// export default PurchaseToStoke;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const PurchaseToStock = () => {
//   const [products, setProducts] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState('');
//   const [quantity, setQuantity] = useState('');
//   const [units, setUnits] = useState('');
//   const [qrCodes, setQrCodes] = useState([]);

//   useEffect(() => {
//     // Fetch available products
//     axios.get('https://posapi.emedha.in/api/api/get/products')
//       .then(response => {
//         setProducts(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching products:', error);
//       });
//   }, []);

//   // Handle product selection
//   const handleProductChange = (event) => {
//     const productId = event.target.value;
//     setSelectedProduct(productId);

//     if (productId) {
//       // Fetch quantity and units for the selected product
//       axios.get(`https://posapi.emedha.in/api/api/product-details/${productId}`)
//         .then(response => {
//           console.log('API Response:', response);  // Log the full response to inspect the structure

//           // Check if data is an array and access the first element
//           if (Array.isArray(response.data) && response.data.length > 0) {
//             const productData = response.data[0];  // Access the first element if it's an array

//             setQuantity(productData.quantity);  // Set quantity
//             setUnits(productData.units);        // Set units
//           } else {
//             console.error('No product data found or response is not in expected format');
//           }
//         })
//         .catch(error => {
//           console.error('Error fetching product details:', error);
//         });
//     }
//   };

//   console.log('Selected Product:', selectedProduct);  // Check the selected product value
//   console.log('Quantity:', quantity);                  // Check the value of quantity
//   console.log('Units:', units);                        // Check the value of units


//   // Generate QR codes
//   const generateQrCodes = () => {
//     const qrData = {
//       productId: selectedProduct,
//       quantity,
//       units
//     };

//     axios.post('https://posapi.emedha.in/api/api/generate-qrcodes', qrData)
//       .then(response => {
//         setQrCodes(response.data.qrCodes);
//       })
//       .catch(error => {
//         console.error('Error generating QR codes:', error);
//       });
//   };

//   return (
//     <div>
//       <h1>Purchase to Stock</h1>

//       <div>
//         <label>Select Product:</label>
//         <select value={selectedProduct} onChange={handleProductChange}>
//           <option value="">-- Select Products --</option>
//           {products.map((product) => (
//             <option key={product.cat_id} value={product.cat_id}>
//               {product.cat_name}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div>
//         <label>Quantity:</label>
//         <input type="text" value={quantity} readOnly />
//       </div>

//       <div>
//         <label>Units:</label>
//         <input type="text" value={units} readOnly />
//       </div>

//       <div>
//         <label>No of Packets:</label>
//         <input
//           type="number"
//           value={units}
//           onChange={(e) => setUnits(e.target.value)}
//         />
//       </div>

//       <div>
//         <button onClick={generateQrCodes}>Generate QR Code</button>
//       </div>

//       <div>
//         <h2>Generated QR Codes:</h2>
//         {qrCodes.length > 0 && (
//           <table>
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Product Name</th>
//                 <th>Weight Per Quantity</th>
//                 <th>QR Code</th>
//               </tr>
//             </thead>
//             <tbody>
//               {qrCodes.map((qr, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{qr.productName}</td>
//                   <td>{qr.quantity}</td>
//                   <td><img src={qr.qrCodePath} alt="QR Code" style={{ width: '40%' }} /></td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         )}
//       </div>
//     </div>
//   );
// };

// export default PurchaseToStock;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const PurchaseToStock = () => {
//     const [products, setProducts] = useState([]);
//     const [selectedProduct, setSelectedProduct] = useState('');
//     const [quantity, setQuantity] = useState('');
//     const [units, setUnits] = useState('');
//     const [unit, setUnit] = useState('');

//     // Fetch products from the server
//     useEffect(() => {
//         axios.get('https://posapi.emedha.in/api/api/products')
//             .then((response) => {
//                 setProducts(response.data);
//             })
//             .catch((error) => {
//                 console.error('There was an error fetching the products:', error);
//             });
//     }, []);

//     // When the product is selected, fetch its quantity and unit
//     const handleProductChange = (event) => {
//       const productId = event.target.value;
//       setSelectedProduct(productId);

//       if (productId) {
//         // Fetch quantity and units for the selected product
//         axios.get(`https://posapi.emedha.in/api/api/product-details/${productId}`)
//           .then(response => {
//             console.log('API Response:', response);  // Log the full response to inspect the structure

//             // Check if the data object is empty
//             if (Object.keys(response.data).length === 0) {
//               console.error('No product data found for the selected product');
//               setQuantity('');  // Clear values
//               setUnits('');
//             } else {
//               // If data is present, set the values
//               setQuantity(response.data.quantity);
//               setUnits(response.data.units);
//             }
//           })
//           .catch(error => {
//             console.error('Error fetching product details:', error);
//           });
//       }
//     };


//     const handleSubmit = (e) => {
//         e.preventDefault();

//         // Send QR code generation request
//         const data = {
//             product: selectedProduct,
//             quantity: quantity,
//             units: units,
//         };

//         axios.post('https://posapi.emedha.in/api/api/generate-qr', data)
//             .then((response) => {
//                 alert('QR codes generated successfully');
//             })
//             .catch((error) => {
//                 console.error('There was an error generating the QR codes:', error);
//             });
//     };

//     return (
//         <div>
//             <h1>Generate QR Code</h1>
//             <form onSubmit={handleSubmit}>
//                 <label>
//                     Select Product:
//                     <select value={selectedProduct} onChange={handleProductChange}>
//                         <option value="">-- Select Product --</option>
//                         {products.map((product) => (
//                             <option key={product.cat_id} value={product.cat_id}>
//                                 {product.cat_name}
//                             </option>
//                         ))}
//                     </select>
//                 </label>
//                 <br />

//                 <label>
//                     Quantity:
//                     <input
//                         type="text"
//                         value={quantity}
//                         readOnly
//                     />
//                 </label>
//                 <br />

//                 <label>
//                     Unit:
//                     <input
//                         type="text"
//                         value={unit}
//                         readOnly
//                     />
//                 </label>
//                 <br />

//                 <label>
//                     No. of Packets:
//                     <input
//                         type="text"
//                         value={units}
//                         onChange={(e) => setUnits(e.target.value)}
//                     />
//                 </label>
//                 <br />

//                 <button type="submit">Generate QR Codes</button>
//             </form>
//         </div>
//     );
// };

// export default PurchaseToStock;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';


// function PurchaseToStock() {
//   const [products, setProducts] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState('');
//   const [quantity, setQuantity] = useState('');
//   const [unit, setUnit] = useState('');
//   const [units, setUnits] = useState('');
//   const [qrCodes, setQrCodes] = useState([]);

//   useEffect(() => {
//     // Fetch products from the API
//     axios.get('https://posapi.emedha.in/api/api/products') 
//       .then((response) => setProducts(response.data))
//       .catch((error) => console.error('Error fetching products:', error));
//   }, []);

//   const handleProductChange = (e) => {
//     const productId = e.target.value;
//     setSelectedProduct(productId);

//     // Fetch product data (quantity and unit)
//     if (productId) {
//       axios.get(`https://posapi.emedha.in/api/api/product/${productId}`)
//         .then((response) => {
//           const { total, nm_unit } = response.data;
//           setQuantity(total);
//           setUnit(nm_unit);
//         })
//         .catch((error) => console.error('Error fetching product data:', error));
//     }
//   };

//   const handleGenerateQrCode = () => {
//     const data = {
//       productId: selectedProduct,
//       quantity,
//       units: units,
//     };

//     axios.post('https://posapi.emedha.in/api/api/generate-qrcode', data)
//       .then((response) => {
//         console.log(response.data.message);
//         // Optionally fetch QR code data after generation
//         setQrCodes([...qrCodes, ...Array.from({ length: units }).map((_, index) => ({
//           productName: selectedProduct,
//           quantity,
//           qrCodeImage: `https://posapi.emedha.in/api/qrimg/${Math.random().toString(36).substring(2, 15)}.png`,
//         }))]);
//       })
//       .catch((error) => console.error('Error generating QR codes:', error));
//   };

//   return (
//     <div className="App">
//       <h1>Purchase to Stock</h1>
//       <hr />
//       <form onSubmit={(e) => e.preventDefault()}>
//         <div>
//           <label>Select Product</label>
//           <select onChange={handleProductChange} value={selectedProduct}>
//             <option value="">-- Select Products --</option>
//             {products.map((product) => (
//               <option key={product.cat_id} value={product.cat_id}>
//                 {product.cat_name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div>
//           <label>Quantity</label>
//           <input type="text" value={quantity} readOnly />
//         </div>
//         <div>
//           <label>Unit</label>
//           <input type="text" value={unit} readOnly />
//         </div>
//         <div>
//           <label>No of packets</label>
//           <input type="number" value={units} onChange={(e) => setUnits(e.target.value)} />
//         </div>
//         <button onClick={handleGenerateQrCode}>Generate QR Code</button>
//       </form>

//       <hr />

//       <h3>Generated QR Codes</h3>
//       <table>
//         <thead>
//           <tr>
//             <th>S.No</th>
//             <th>Product Name</th>
//             <th>Quantity</th>
//             <th>QR Code</th>
//           </tr>
//         </thead>
//         <tbody>
//           {qrCodes.map((qr, index) => (
//             <tr key={index}>
//               <td>{index + 1}</td>
//               <td>{qr.productName}</td>
//               <td>{qr.quantity}</td>
//               <td><img src={qr.qrCodeImage} alt="QR Code" width="100" /></td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export default PurchaseToStock;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Sidebar from '../Sidebar/Sidebar'

// function PurchaseToStock() {
//   const [products, setProducts] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState('');
//   const [quantity, setQuantity] = useState('');
//   const [unit, setUnit] = useState('');
//   const [units, setUnits] = useState('');
//   const [qrCodes, setQrCodes] = useState([]);

//   useEffect(() => {
//     // Fetch products from the API
//     axios.get('https://posapi.emedha.in/api/api/products')
//       .then((response) => setProducts(response.data))
//       .catch((error) => console.error('Error fetching products:', error));
//   }, []);

//   const handleProductChange = (e) => {
//     const productId = e.target.value;
//     setSelectedProduct(productId);

//     // Fetch product data (quantity and unit) when a product is selected
//     if (productId) {
//       axios.get(`https://posapi.emedha.in/api/api/product/${productId}`)
//         .then((response) => {
//           const { total, nm_unit } = response.data;
//           setQuantity(total); // Set the quantity for the selected product
//           setUnit(nm_unit);   // Set the unit for the selected product
//         })
//         .catch((error) => console.error('Error fetching product data:', error));
//     }
//   };

//   const handleGenerateQrCode = () => {
//     const data = {
//       productId: selectedProduct,
//       quantity,
//       units: units,
//     };

//     axios.post('https://posapi.emedha.in/api/api/generate-qrcode', data)
//       .then((response) => {
//         console.log(response.data.message);
//         // Optionally fetch QR code data after generation
//         setQrCodes([...qrCodes, ...Array.from({ length: units }).map((_, index) => ({
//           productName: selectedProduct,
//           quantity,
//           qrCodeImage: `https://posapi.emedha.in/api/qrimg/${Math.random().toString(36).substring(2, 15)}.png`,
//         }))]);
//       })
//       .catch((error) => console.error('Error generating QR codes:', error));
//   };

//   return (

//     <>
//     <Sidebar />
//     <div id='main'>


//     <div className="App">
//       <h1>Purchase to Stock</h1>
//       <hr />
//       <form onSubmit={(e) => e.preventDefault()}>
//         <div>
//           <label>Select Product</label>
//           <select onChange={handleProductChange} value={selectedProduct}>
//             <option value="">-- Select Products --</option>
//             {products.map((product) => (
//               <option key={product.cat_id} value={product.cat_id}>
//                 {product.cat_name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div>
//           <label>Quantity</label>
//           <input 
//             type="text" 
//             value={quantity} 
//             readOnly // Disable editing, but still show the value
//           />
//         </div>

//         <div>
//           <label>Unit</label>
//           <input 
//             type="text" 
//             value={unit} 
//             readOnly // Disable editing, but still show the value
//           />
//         </div>

//         <div>
//           <label>No of packets</label>
//           <input 
//             type="number" 
//             value={units} 
//             onChange={(e) => setUnits(e.target.value)} 
//           />
//         </div>

//         <button onClick={handleGenerateQrCode}>Generate QR Code</button>
//       </form>

//       <hr />

//       <h3>Generated QR Codes</h3>
//       <table>
//         <thead>
//           <tr>
//             <th>S.No</th>
//             <th>Product Name</th>
//             <th>Quantity</th>
//             <th>QR Code</th>
//           </tr>
//         </thead>
//         <tbody>
//           {qrCodes.map((qr, index) => (
//             <tr key={index}>
//               <td>{index + 1}</td>
//               <td>{qr.productName}</td>
//               <td>{qr.quantity}</td>
//               <td><img src={qr.qrCodeImage} alt="QR Code" width="100" /></td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>


//     </div>
//     </>
//   );
// }

// export default PurchaseToStock;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Sidebar from '../Sidebar/Sidebar';
// import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';

// function PurchaseToStock() {
//   const [products, setProducts] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState('');
//   const [quantity, setQuantity] = useState('');
//   const [unit, setUnit] = useState('');
//   const [units, setUnits] = useState('');
//   const [qrCodes, setQrCodes] = useState([]);

//   useEffect(() => {
//     // Fetch products from the API
//     axios.get('https://posapi.emedha.in/api/api/products')
//       .then((response) => setProducts(response.data))
//       .catch((error) => console.error('Error fetching products:', error));
//   }, []);

//   // const handleProductChange = (e) => {
//   //   const productId = e.target.value;
//   //   setSelectedProduct(productId);

//   //   // Fetch product data (quantity and unit) when a product is selected
//   //   if (productId) {
//   //     axios.get(`https://posapi.emedha.in/api/api/product/${productId}`)
//   //       .then((response) => {
//   //         const { total, nm_unit } = response.data;
//   //         setQuantity(total); // Set the quantity for the selected product
//   //         setUnit(nm_unit);   // Set the unit for the selected product
//   //       })
//   //       .catch((error) => console.error('Error fetching product data:', error));
//   //   }
//   // };

//   const handleProductChange = (event) => {
//     const productId = event.target.value;
//     setSelectedProduct(productId);

//     // Fetch quantity and unit
//     axios.get('https://posapi.emedha.in/api/product-details', { params: { productId } })
//       .then((response) => {
//         setQuantity(response.data.total);
//         setUnit(response.data.nm_unit);
//       })
//       .catch((error) => {
//         console.error('Error fetching product details', error);
//       });
//   };

//   // const handleGenerateQrCode = () => {
//   //   const data = {
//   //     productId: selectedProduct,
//   //     quantity,
//   //     units: units,
//   //   };

//   //   axios.post('https://posapi.emedha.in/api/api/generate-qrcode', data)
//   //     .then((response) => {
//   //       console.log(response.data.message);
//   //       // Optionally fetch QR code data after generation
//   //       setQrCodes([...qrCodes, ...Array.from({ length: units }).map((_, index) => ({
//   //         productName: selectedProduct,
//   //         quantity,
//   //         qrCodeImage: `https://posapi.emedha.in/api/qrimg/${Math.random().toString(36).substring(2, 15)}.png`,
//   //       }))]);
//   //     })
//   //     .catch((error) => console.error('Error generating QR codes:', error));
//   // };


//   const handleGenerateQrCode = () => {
//     const product = selectedProduct;
//     const productQuantity = quantity;
//     const productUnits = units;

//     axios.post('http://localhost:5000/api/generate-qrcode', {
//       product,
//       quantity: productQuantity,
//       units: productUnits,
//     })
//     .then((response) => {
//       setQrCodes(response.data.qrImages);
//     })
//     .catch((error) => {
//       console.error('Error generating QR codes', error);
//     });
//   };

//   return (
//     <>
//       <Sidebar />
//       <div id="main">
//         <Container>
//           <h1 className="my-4">Purchase to Stock</h1>
//           <hr />
//           <Form onSubmit={(e) => e.preventDefault()}>
//             <Row className="mb-3">
//               <Col md={4}>
//                 <Form.Group controlId="productSelect">
//                   <Form.Label>Select Product</Form.Label>
//                   <Form.Control as="select" onChange={handleProductChange} value={selectedProduct}>
//                     <option value="">-- Select Products --</option>
//                     {products.map((product) => (
//                       <option key={product.cat_id} value={product.cat_id}>
//                         {product.cat_name}
//                       </option>
//                     ))}
//                   </Form.Control>
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Form.Group controlId="quantity">
//                   <Form.Label>Quantity</Form.Label>
//                   <Form.Control 
//                     type="text" 
//                     value={quantity} 
//                     readOnly
//                   />
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Form.Group controlId="unit">
//                   <Form.Label>Unit</Form.Label>
//                   <Form.Control 
//                     type="text" 
//                     value={unit} 
//                     readOnly 
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             <Row className="mb-3">
//               <Col md={4}>
//                 <Form.Group controlId="units">
//                   <Form.Label>No of packets</Form.Label>
//                   <Form.Control 
//                     type="number" 
//                     value={units} 
//                     onChange={(e) => setUnits(e.target.value)} 
//                   />
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Button 
//                   variant="primary" 
//                   onClick={handleGenerateQrCode} 
//                   className="mt-4"
//                 >
//                   Generate QR Code
//                 </Button>
//               </Col>
//             </Row>
//           </Form>

//           <hr />
//           <h3>Generated QR Codes</h3>
//           <Table striped bordered hover responsive>
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Product Name</th>
//                 <th>Quantity</th>
//                 <th>QR Code</th>
//               </tr>
//             </thead>
//             <tbody>
//               {qrCodes.map((qr, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{qr.productName}</td>
//                   <td>{qr.quantity}</td>
//                   <td><img src={qr.qrCodeImage} alt="QR Code" width="100" /></td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </Container>
//       </div>
//     </>
//   );
// }

// export default PurchaseToStock;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Sidebar from '../Sidebar/Sidebar';
// import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';

// function PurchaseToStock() {
//   const [products, setProducts] = useState([]);
//   const [unitList, setUnitList] = useState([]); // To store the list of units
//   const [selectedProduct, setSelectedProduct] = useState('');
//   const [quantity, setQuantity] = useState('');
//   const [unit, setUnit] = useState(''); // Unit state to display the correct unit name
//   const [units, setUnits] = useState('');
//   const [qrCodes, setQrCodes] = useState([]);
//   const [error, setError] = useState('');

//   // Fetch products from the API
//   useEffect(() => {
//     axios.get('https://posapi.emedha.in/api/api/products')
//       .then((response) => setProducts(response.data))
//       .catch((error) => console.error('Error fetching products:', error));
//   }, []);

//   // Fetch unit list from the API
//   const fetchUnit = async () => {
//     try {
//       const response = await axios.get('https://posapi.emedha.in/api/unit');
//       setUnitList(response.data); // Store unit list in the state
//     } catch (err) {
//       setError('Error fetching Unit');
//     }
//   };

//   useEffect(() => {
//     fetchUnit();
//   }, []);

//  const handleProductChange = (event) => {
//   const productId = event.target.value;
//   setSelectedProduct(productId);

//   if (productId) {
//     axios.get('https://posapi.emedha.in/api/product-details', { params: { productId } })
//       .then((response) => {
//         const { quantity, units, prodname, id_unit } = response.data;

//         // Set quantity from product details
//         setQuantity(quantity);

//         // Set the units (id_unit) from the response
//         setUnits(units);

//         // Find the matching unit name (nm_unit) based on units (id_unit) using filter
//         const selectedUnit = unitList.filter((unit) => unit.id_unit === units)[0];  // Use filter to match the id_unit (18 -> Nos, etc.)
        
//         if (selectedUnit) {
//           setUnit(selectedUnit.nm_unit); // Set the correct unit name (nm_unit)
//         } else {
//           setUnit(''); // Fallback if no matching unit is found
//         }
//       })
//       .catch((error) => console.error('Error fetching product details:', error));
//   } else {
//     // Reset fields if no product is selected
//     setQuantity('');
//     setUnit('');
//     setUnits('');
//   }
// };

//   return (
//     <>
//       <Sidebar />
//       <div id="main">
//         <Container>
//           <h1 className="my-4">Purchase to Stock</h1>
//           <hr />
//           <Form onSubmit={(e) => e.preventDefault()}>
//             <Row className="mb-3">
//               <Col md={4}>
//                 <Form.Group controlId="productSelect">
//                   <Form.Label>Select Product</Form.Label>
//                   <Form.Control as="select" onChange={handleProductChange} value={selectedProduct}>
//                     <option value="">-- Select Products --</option>
//                     {products.map((product) => (
//                       <option key={product.cat_id} value={product.cat_id}>
//                         {product.cat_name}
//                       </option>
//                     ))}
//                   </Form.Control>
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Form.Group controlId="quantity">
//                   <Form.Label>Quantity</Form.Label>
//                   <Form.Control
//                     type="text"
//                     value={quantity}
//                     readOnly
//                   />
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Form.Group controlId="units">
//                   <Form.Label>Unit</Form.Label>
//                   <Form.Control
//                     type="text"
//                     value={units} // Display the nm_unit (unit name)
//                     readOnly
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             <Row className="mb-3">
//               <Col md={4}>
//                 <Form.Group controlId="unit">
//                   <Form.Label>No of packets</Form.Label>
//                   <Form.Control
//                     type="number"
//                     value={unit} // Display the total units
//                     onChange={(e) => setUnit(e.target.value)}
//                   />
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Button
//                   variant="primary"
//                   className="mt-4"
//                 >
//                   Generate QR Code
//                 </Button>
//               </Col>
//             </Row>
//           </Form>

//           <hr />
//           <h3>Generated QR Codes</h3>
//           <Table striped bordered hover responsive>
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Product Name</th>
//                 <th>Quantity</th>
//                 <th>QR Code</th>
//               </tr>
//             </thead>
//             <tbody>
//               {qrCodes.map((qr, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{qr.productName}</td>
//                   <td>{qr.quantity}</td>
//                   <td><img src={qr.qrCodeImage} alt="QR Code" width="100" /></td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </Container>
//       </div>
//     </>
//   );
// }

// export default PurchaseToStock;





// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Sidebar from '../Sidebar/Sidebar';
// import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';

// function PurchaseToStock() {
//   const [products, setProducts] = useState([]);
//   const [unitList, setUnitList] = useState([]); // To store the list of units
//   const [selectedProduct, setSelectedProduct] = useState('');
//   const [quantity, setQuantity] = useState('');
//   const [unit, setUnit] = useState(''); // Unit state to display the correct unit name
//   const [units, setUnits] = useState('');
//   const [qrCodes, setQrCodes] = useState([]);
//   const [error, setError] = useState('');

//   // Fetch products from the API
//   useEffect(() => {
//     axios.get('https://posapi.emedha.in/api/api/products')
//       .then((response) => setProducts(response.data))
//       .catch((error) => console.error('Error fetching products:', error));
//   }, []);
// console.log(products)
//   // Fetch unit list from the API
//   const fetchUnit = async () => {
//     try {
//       const response = await axios.get('https://posapi.emedha.in/api/unit');
//       setUnitList(response.data); // Store unit list in the state
//     } catch (err) {
//       setError('Error fetching Unit');
//     }
//   };

//   useEffect(() => {
//     fetchUnit();
//   }, []);

//   // Function to fetch quantity data from backend
//   const fetchQuantityData = (productId) => {
//     axios.get(`https://posapi.emedha.in/api/getPurchaseData?productId=${productId}`)
//       .then((response) => {
//         const data = response.data;
//         if (data && data.length > 0) {
//           setQuantity(data[0].total);  // Set quantity from response
//           setUnit(data[0].nm_unit);    // Set unit from response

//           // Set the selected unit based on the response
//           const selectedUnit = unitList.find((unit) => unit.id_unit === data[0].nm_unit);
//           if (selectedUnit) {
//             setUnits(selectedUnit.id_unit); // Set the unit ID to match the response
//           } else {
//             setUnits('');
//           }
//         } else {
//           setQuantity('');
//           setUnit('');
//           setUnits('');
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching data:', error);
//       });
//   };

//   const handleProductChange = (event) => {
//     const productId = event.target.value;
//     setSelectedProduct(productId);

//     if (productId) {
     
//       fetchQuantityData(productId);
//     } else {
//       // Reset fields if no product is selected
//       setQuantity('');
//       setUnit('');
//       setUnits('');
//     }
//   };
//   const handleGenerateQRCode = () => {
//     // Ensure product, quantity, and units are valid
//     if (!selectedProduct || !quantity || !units) {
//       setError('Please fill in all fields before generating QR codes.');
//       return;
//     }

//     // Prepare data for the API request
//     const data = {
//       productId: selectedProduct,
//       quantity: quantity,
//       units: units,
//     };

//     // Send POST request to generate QR codes
//     axios.post('https://posapi.emedha.in/api/api/generate-qrcode', data)
//       .then((response) => {
//         if (response.data.message) {
//           // If the response contains a message, we assume QR codes are generated successfully
//           // You may need to update the table with the QR code image URLs
//           const generatedQRCodes = response.data.qrCodes || [];
//           setQrCodes(generatedQRCodes);  // Update the state with generated QR codes
//         }
//       })
//       .catch((error) => {
//         console.error('Error generating QR codes:', error);
//         setError('Error generating QR codes');
//       });
//   };
//   return (
//     <>
//       <Sidebar />
//       <div id="main">
//         <Container>
//           <h1 className="my-4">Purchase to Stock</h1>
//           <hr />
//           <Form onSubmit={(e) => e.preventDefault()}>
//             <Row className="mb-3">
//               <Col md={4}>
//                 <Form.Group controlId="productSelect">
//                   <Form.Label>Select Product</Form.Label>
//                   <Form.Control as="select" onChange={handleProductChange} value={selectedProduct}>
//                     <option value="">-- Select Products --</option>
//                     {products.map((product) => (
//                       <option key={product.cat_id} value={product.cat_id}>
//                         {product.cat_name}
//                       </option>
//                     ))}
//                   </Form.Control>
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Form.Group controlId="quantity">
//                   <Form.Label>Quantity</Form.Label>
//                   <Form.Control
//                     type="text"
//                     value={quantity}
//                     readOnly
//                   />
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Form.Group controlId="units">
//                   <Form.Label>Unit</Form.Label>
//                   <Form.Control
//                     type="text"
//                     value={unit} // Display the nm_unit (unit name)
//                     readOnly
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             <Row className="mb-3">
//               <Col md={4}>
//                 <Form.Group controlId="unit">
//                   <Form.Label>No of packets</Form.Label>
//                   <Form.Control
//                     type="number"
//                     value={units} // Display the unit ID (it will change when fetching product data)
//                     onChange={(e) => setUnits(e.target.value)}
//                   />
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Button
//                   variant="primary"
//                   className="mt-4"  
//                   onClick={handleGenerateQRCode} 
//                 >
//                   Generate QR Code
//                 </Button>
//               </Col>
//             </Row>
//           </Form>

//           <hr />
//           {/* <h3>Generated QR Codes</h3>
//           <Table striped bordered hover responsive>
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Product Name</th>
//                 <th>Quantity</th>
//                 <th>QR Code</th>
//               </tr>
//             </thead>
//             <tbody>
//               {qrCodes.map((qr, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{qr.productName}</td>
//                   <td>{qr.quantity}</td>
//                   <td><img src={qr.qrCodeImage} alt="QR Code" width="100" /></td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table> */}
//         </Container>
//       </div>
//     </>
//   );
// }

// export default PurchaseToStock;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function PurchaseToStock() {
  const [products, setProducts] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unit, setUnit] = useState('');
  const [units, setUnits] = useState('');
  const [price, setPrice] = useState('');  // Ensure price is part of the state
  const [qrCodes, setQrCodes] = useState([]);
  const [error, setError] = useState('');

  // Fetch products from the API
  useEffect(() => {
    axios.get('https://posapi.emedha.in/api/api/products')
      .then((response) => setProducts(response.data))
      .catch((error) => console.error('Error fetching products:', error));
  }, []);

  // Fetch unit list from the API
  const fetchUnit = async () => {
    try {
      const response = await axios.get('https://posapi.emedha.in/api/unit');
      setUnitList(response.data); // Store unit list in the state
    } catch (err) {
      setError('Error fetching Unit');
    }
  };

  useEffect(() => {
    fetchUnit();
  }, []);

  // Fetch quantity and unit data when a product is selected
  const fetchQuantityData = (productId) => {
    axios.get(`https://posapi.emedha.in/api/getPurchaseData?productId=${productId}`)
      .then((response) => {
        const data = response.data;
        if (data && data.length > 0) {
          setQuantity(data[0].total);  // Set quantity from response
          setUnit(data[0].nm_unit);    // Set unit from response
          // Set the selected unit based on the response
          const selectedUnit = unitList.find((unit) => unit.id_unit === data[0].nm_unit);
          if (selectedUnit) {
            setUnits(selectedUnit.id_unit); 
          } else {
            setUnits('');
          }
        } else {
          setQuantity('');
          setUnit('');
          setUnits('');
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleProductChange = (event) => {
    const productId = event.target.value;  // This is the cat_id of the selected product
    setSelectedProduct(productId);

    // Find the selected product in the products list
    const selectedProductData = products.find((product) => product.cat_id === parseInt(productId));

    if (selectedProductData) {
      setPrice(selectedProductData.price);  // Set the price directly from the selected product
    } else {
      setPrice('');  // Reset price if no product is selected
    }

    // Fetch the quantity and unit data for the selected product
    if (productId) {
      fetchQuantityData(productId);
    } else {
      // Reset fields if no product is selected
      setQuantity('');
      setUnit('');
      setUnits('');
    }
  };

  // const handleGenerateQRCode = () => {
  //   if (!selectedProduct || !quantity || !unit) {
  //     setError('Please fill in all fields before generating QR codes.');
  //     return;
  //   }

  //   // Convert quantity and units to numbers
  //   const totalQuantity = parseInt(quantity, 10);
  //   const numberOfPackets = parseInt(units, 10);

  //   // Ensure number of packets is a valid number and not zero
  //   if (numberOfPackets <= 0) {
  //     setError('Number of packets must be greater than 0.');
  //     return;
  //   }

  //   // Calculate the quantity per QR code
  //   const quantityPerQRCode = totalQuantity / numberOfPackets;

  //   // If division isn't even, handle it (for example, rounding down)
  //   if (quantityPerQRCode <= 0) {
  //     setError('Invalid quantity calculation. Please check the inputs.');
  //     return;
  //   }

  //   const data = {
  //     productId: selectedProduct, // Send the productId (cat_id) to the backend
  //     quantity: quantityPerQRCode,
  //     unit: unit,
  //     units: units,
  //   };

  //   axios.post('https://posapi.emedha.in/api/api/generate-qrcode', data)
  //     .then((response) => {
  //       if (response.data.message) {
  //         const generatedQRCodes = response.data.qrCodes || [];
  //         setQrCodes(generatedQRCodes);
  //         setError(''); // Clear any previous error messages

  //         // Clear all input fields after successful QR generation
  //         setSelectedProduct(''); // Clear the selected product
  //         setQuantity(''); // Clear the quantity
  //         setUnit(''); // Clear the unit
  //         setUnits(''); // Clear the units
  //         setPrice(''); // Clear the price
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error generating QR codes:', error);
  //       setError('Error generating QR codes');
  //     });
  // };

  const handleGenerateQRCode = () => {
    if (!selectedProduct || !quantity || !unit) {
      setError('Please fill in all fields before generating QR codes.');
      return;
    }
  
    // Convert quantity and units to numbers
    const totalQuantity = parseInt(quantity, 10);
    const numberOfPackets = parseInt(units, 10);
  
    // Ensure number of packets is a valid number and not zero
    if (numberOfPackets <= 0) {
      setError('Number of packets must be greater than 0.');
      return;
    }
  
    // Calculate the quantity per QR code
    const quantityPerQRCode = totalQuantity / numberOfPackets;
  
    // If division isn't even, handle it (for example, rounding down)
    if (quantityPerQRCode <= 0) {
      setError('Invalid quantity calculation. Please check the inputs.');
      return;
    }
  
    // Calculate the price per unit
    const pricePerUnit = price / numberOfPackets;  // Dividing total price by the number of units
  
    // If price division isn't valid, handle it
    if (pricePerUnit <= 0) {
      setError('Invalid price calculation. Please check the inputs.');
      return;
    }
  
    const data = {
      productId: selectedProduct, // Send the productId (cat_id) to the backend
      quantity: quantityPerQRCode,
      unit: unit,
      units: units,
      price: pricePerUnit,  // Send the divided price for each unit
    };
  
    axios.post('https://posapi.emedha.in/api/api/generate-qrcode', data)
      .then((response) => {
        if (response.data.message) {
          const generatedQRCodes = response.data.qrCodes || [];
          setQrCodes(generatedQRCodes);
          setError(''); // Clear any previous error messages
  
          // Clear all input fields after successful QR generation
          setSelectedProduct(''); // Clear the selected product
          setQuantity(''); // Clear the quantity
          setUnit(''); // Clear the unit
          setUnits(''); // Clear the units
          setPrice(''); // Clear the price
        }
      })
      .catch((error) => {
        console.error('Error generating QR codes:', error);
        setError('Error generating QR codes');
      });
  };
  
  return (
    <>
      <Sidebar />
      <div id="main">
        <Container>
          <h1 className="my-4">Purchase to Stock</h1>
          <hr />
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row className="mb-3">
              <Col md={4}>
                <Form.Group controlId="productSelect">
                  <Form.Label>Select Product</Form.Label>
                  <Form.Control as="select" onChange={handleProductChange} value={selectedProduct}>
                    <option value="">-- Select Products --</option>
                    {products.map((product) => (
                      <option key={product.cat_id} value={product.cat_id}> {/* Value is cat_id, display cat_name */}
                        {product.cat_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="quantity">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="text"
                    value={quantity}
                    readOnly
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="units">
                  <Form.Label>Unit</Form.Label>
                  <Form.Control
                    type="text"
                    value={unit}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <Form.Group controlId="price">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="text"
                    value={price}
                    readOnly
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="unit">
                  <Form.Label>No of Packets</Form.Label>
                  <Form.Control
                    type="number"
                    value={units}
                    onChange={(e) => setUnits(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Button
                  variant="primary"
                  className="mt-4"
                  onClick={handleGenerateQRCode}
                >
                  Generate QR Code
                </Button>
              </Col>
            </Row>
          </Form>

          <hr />
        </Container>
      </div>
    </>
  );
}

export default PurchaseToStock;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Sidebar from '../Sidebar/Sidebar';
// import { Container, Row, Col, Form, Button } from 'react-bootstrap';

// function PurchaseToStock() {
//   const [products, setProducts] = useState([]);
//   const [unitList, setUnitList] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState('');
//   const [quantity, setQuantity] = useState('');
//   const [unit, setUnit] = useState('');
//   const [units, setUnits] = useState('');
//   const [price, setPrice] = useState('');
//   const [qrCodes, setQrCodes] = useState([]);
//   const [error, setError] = useState('');

//   // Fetch products from the API
//   useEffect(() => {
//     axios.get('https://posapi.emedha.in/api/api/products')
//       .then((response) => setProducts(response.data))
//       .catch((error) => console.error('Error fetching products:', error));
//   }, []);

//   // Fetch unit list from the API
//   const fetchUnit = async () => {
//     try {
//       const response = await axios.get('https://posapi.emedha.in/api/unit');
//       setUnitList(response.data); // Store unit list in the state
//     } catch (err) {
//       setError('Error fetching Unit');
//     }
//   };

//   useEffect(() => {
//     fetchUnit();
//   }, []);

//   const fetchQuantityData = (productId) => {
//     axios.get(`https://posapi.emedha.in/api/getPurchaseData?productId=${productId}`)
//       .then((response) => {
//         const data = response.data;
//         if (data && data.length > 0) {
//           setQuantity(data[0].total);  // Set quantity from response
//           setUnit(data[0].nm_unit);    // Set unit from response
//           setPrice(data[0].price);     // Set price from response

//           // Set the selected unit based on the response
//           const selectedUnit = unitList.find((unit) => unit.id_unit === data[0].nm_unit);
//           if (selectedUnit) {
//             setUnits(selectedUnit.id_unit); 
//           } else {
//             setUnits('');
//           }
//         } else {
//           setQuantity('');
//           setUnit('');
//           setPrice('');
//           setUnits('');
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching data:', error);
//       });
//   };

//   const handleProductChange = (event) => {
//     const productId = event.target.value; // This will now be the cat_id
//     setSelectedProduct(productId);

//     if (productId) {
//       fetchQuantityData(productId);
//     } else {
//       // Reset fields if no product is selected
//       setQuantity('');
//       setUnit('');
//       setPrice('');
//       setUnits('');
//     }
//   };

//   const handleGenerateQRCode = () => {
//     if (!selectedProduct || !quantity || !unit || !price) {
//       setError('Please fill in all fields before generating QR codes.');
//       return;
//     }

//     // Ensure price is a valid number
//     const priceValue = parseFloat(price);
//     if (isNaN(priceValue)) {
//       setError('Invalid price value. Please check the price input.');
//       return;
//     }

//     // Convert quantity and units to numbers
//     const totalQuantity = parseInt(quantity, 10);
//     const numberOfPackets = parseInt(units, 10);

//     // Ensure number of packets is a valid number and not zero
//     if (numberOfPackets <= 0) {
//       setError('Number of packets must be greater than 0.');
//       return;
//     }

//     // Calculate the quantity per QR code
//     const quantityPerQRCode = totalQuantity / numberOfPackets;

//     // Calculate the price per QR code
//     const pricePerQRCode = priceValue / numberOfPackets;
// console.log(price)
//     // If division isn't even, handle it (for example, rounding down)
//     // if (quantityPerQRCode <= 0 || pricePerQRCode <= 0) {
//     //   setError('Invalid quantity or price calculation. Please check the inputs.');
//     //   return;
//     // }

//     // Log the price per QR code to the console
//     console.log('Price per QR Code:', pricePerQRCode.toFixed(2));

//     const data = {
//       productId: selectedProduct, // Send the productId (cat_id) to the backend
//       quantity: quantityPerQRCode,
//       price: pricePerQRCode.toFixed(2), // Price per QR code, rounded to two decimal places
//       unit: unit,
//       units: units,
//     };

//     axios.post('https://posapi.emedha.in/api/api/generate-qrcode', data)
//       .then((response) => {
//         if (response.data.message) {
//           const generatedQRCodes = response.data.qrCodes || [];
//           setQrCodes(generatedQRCodes);
//           setError(''); // Clear any previous error messages
        
//           // Clear all input fields after successful QR generation
//           setSelectedProduct(''); // Clear the selected product
//           setQuantity(''); // Clear the quantity
//           setUnit(''); // Clear the unit
//           setUnits(''); // Clear the units
//         }
//       })
//       .catch((error) => {
//         console.error('Error generating QR codes:', error);
//         setError('Error generating QR codes');
//       });
//   };

//   return (
//     <>
//       <Sidebar />
//       <div id="main">
//         <Container>
//           <h1 className="my-4">Purchase to Stock</h1>
//           <hr />
//           <Form onSubmit={(e) => e.preventDefault()}>
//             <Row className="mb-3">
//               <Col md={4}>
//                 <Form.Group controlId="productSelect">
//                   <Form.Label>Select Product</Form.Label>
//                   <Form.Control as="select" onChange={handleProductChange} value={selectedProduct}>
//                     <option value="">-- Select Products --</option>
//                     {products.map((product) => (
//                       <option key={product.cat_id} value={product.cat_id}>
//                         {product.cat_name}
//                       </option>
//                     ))}
//                   </Form.Control>
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Form.Group controlId="quantity">
//                   <Form.Label>Quantity</Form.Label>
//                   <Form.Control
//                     type="text"
//                     value={quantity}
//                     readOnly
//                   />
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Form.Group controlId="units">
//                   <Form.Label>Unit</Form.Label>
//                   <Form.Control
//                     type="text"
//                     value={unit}
//                     readOnly
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>

//             <Row className="mb-3">
//               <Col md={4}>
//                 <Form.Group controlId="unit">
//                   <Form.Label>No of Packets</Form.Label>
//                   <Form.Control
//                     type="number"
//                     value={units}
//                     onChange={(e) => setUnits(e.target.value)}
//                   />
//                 </Form.Group>
//               </Col>

//               <Col md={4}>
//                 <Button
//                   variant="primary"
//                   className="mt-4"  
//                   onClick={handleGenerateQRCode} 
//                 >
//                   Generate QR Code
//                 </Button>
//               </Col>
//             </Row>
//           </Form>

//           <hr />
//           {qrCodes.length > 0 && (
//             <div>
//               <h4>Generated QR Codes</h4>
//               <ul>
//                 {qrCodes.map((qrCode, index) => (
//                   <li key={index}>
//                     <img src={qrCode} alt={`QR Code ${index + 1}`} />
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           )}
//         </Container>
//       </div>
//     </>
//   );
// }

// export default PurchaseToStock;
