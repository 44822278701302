// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Table, Button } from 'react-bootstrap';
// import Sidebar from '../Sidebar/Sidebar'
// const Order = () => {
//   const [purchases, setPurchases] = useState([]);

//   useEffect(() => {
//     // Fetch purchase data from the API
//     axios.get('https://posapi.emedha.in/api/purchases')
//       .then(response => {
//         setPurchases(response.data);
//       })
//       .catch(error => console.error('Error fetching purchase data:', error));
//   }, []);

//   return (
//     <>
//     <Sidebar/>
//     <div id='main'>
//     <div className="container mt-5">
//       <h1>Purchases</h1>
//       <hr />
//       <Button href="/order-details" variant="success" size="sm" className="mb-3">
//         Add Purchase
//       </Button>
//       <div style={{ overflowX: 'auto' }}>
//         <Table striped bordered hover>
//           <thead>
//             <tr>
//               <th>S.No</th>
//               <th>Bill Number</th>
//               <th>Bill Date</th>
//               <th>Supplier Name</th>
//               <th>Bill Amount</th>
//             </tr>
//           </thead>
//           <tbody>
//             {purchases.map((purchase, index) => (
//               <tr key={purchase.psid}>
//                 <td>{index + 1}</td>
//                 <td><a href={`/order-detail/${purchase.billno}`}>{purchase.billno}</a></td>
//                 <td>{new Date(purchase.billdate).toLocaleDateString()}</td>
//                 <td>{purchase.spname}</td>
//                 <td>Rs. {purchase.billamount.toLocaleString()}</td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       </div>
//     </div>
//     </div>
//     </>
//   );
// };

// export default Order;



// PurchaseTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import Sidebar from '../Sidebar/Sidebar'
import { Button } from 'react-bootstrap';

const Order = () => {
  const [purchases, setPurchases] = useState([]);

  useEffect(() => {
    // Fetch purchases data from the backend
    axios.get('https://posapi.emedha.in/api/order')
      .then(response => {
        setPurchases(response.data);
      })
      .catch(error => {
        console.error('Error fetching purchases:', error);
      });
  }, []);

  // Define the columns for the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'S.No',
        accessor: 'psid', // Column accessor
      },
      {
        Header: 'Bill Number',
        accessor: 'billno',
      },
      {
        Header: 'Bill Date',
        accessor: 'billdate',
        Cell: ({ value }) => new Date(value).toLocaleDateString('en-GB'), // Format date
      },
      {
        Header: 'Supplier Name',
        accessor: 'spname',
      },
      {
        Header: 'Bill Amount',
        accessor: 'billamount',
        Cell: ({ value }) => `Rs. ${new Intl.NumberFormat().format(value)}`, // Format number
      },
    ],
    []
  );

  // Use react-table hooks
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: purchases,
  });

  return (
    <>
    <Sidebar/>
    <div id='main'>
    <div>
      <h1>Purchases</h1>
      <Button onClick={() => window.location.href = '/order-details'}>Add Purchase</Button>

      <table {...getTableProps()} className="table table-striped">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    </div>
    </>
  );
};

export default Order;
