// import React, { useEffect, useState } from 'react';
// import './home.css';
// import Sidebar from '../Sidebar/Sidebar';
// import Header from '../Header/Header';
// import { FaUser, FaCog, FaChartLine, FaRegFileAlt } from 'react-icons/fa'; // Import icons from react-icons
// import ganeshlogo from '../images/ganeshlogo.jpeg'
// import axios from 'axios';



// const Home = () => {

//     const [users, setUsers] = useState([]);
 
//     const [todaysBooking, setTodaysBooking] = useState(0);
//     const [todaysAmount, setTodaysAmount] = useState(0);
//     const [todaysBookingCount, setTodaysBookingCount] = useState(0);
//     const [monthlyBookingCount, setMonthlyBookingCount] = useState(0);
//     const [yearlyBookingCount, setYearlyBookingCount] = useState(0);
//     const [todaysPurchase, setTodaysPurchase] = useState(0);
//     const [monthlyPurchase, setMonthlyPurchase] = useState(0);
//     const [yearlyPurchase, setYearlyPurchase] = useState(0);
//     const [totalProducts, setTotalProducts] = useState(0);
    
//     const [loading, setLoading] = useState(true); 
//     const [error, setError] = useState(null); 


//         const fetchTodaysBooking = async () => {
//             try {
//               const response = await axios.get('https://posapi.emedha.in/api/todays-booking');
//               setTodaysBooking(response.data);  // Set users data from API response
//               setLoading(false);         // Stop loading once data is fetched
//             } catch (err) {
//               setError('Error fetching users');  // Handle errors
//               setLoading(false);
//             }
//           };

//           const fetchTodaysAmount = async () => {
//             try {
//               const response = await axios.get('https://posapi.emedha.in/apicount-todays-booking');
//               setTodaysAmount(response.data);  // Set users data from API response
//               setLoading(false);         // Stop loading once data is fetched
//             } catch (err) {
//               setError('Error fetching users');  // Handle errors
//               setLoading(false);
//             }
//           };
//           const fetchTodaysBookingCount = async () => {
//             try {
//               const response = await axios.get('https://posapi.emedha.in/api/monthly-booking');
//               setTodaysBookingCount(response.data);  // Set users data from API response
//               setLoading(false);         // Stop loading once data is fetched
//             } catch (err) {
//               setError('Error fetching users');  // Handle errors
//               setLoading(false);
//             }
//           };
//           const fetchMonthlyBookingCount = async () => {
//             try {
//               const response = await axios.get('https://posapi.emedha.in/api/yearly-booking');
//               setMonthlyBookingCount(response.data);  // Set users data from API response
//               setLoading(false);         // Stop loading once data is fetched
//             } catch (err) {
//               setError('Error fetching users');  // Handle errors
//               setLoading(false);
//             }
//           };
//           const fetchYearlyBookingCount = async () => {
//             try {
//               const response = await axios.get('https://posapi.emedha.in/api/todays-purchase');
//               setYearlyBookingCount(response.data);  // Set users data from API response
//               setLoading(false);         // Stop loading once data is fetched
//             } catch (err) {
//               setError('Error fetching users');  // Handle errors
//               setLoading(false);
//             }
//           };
//           const fetchTodaysPurchase = async () => {
//             try {
//               const response = await axios.get('https://posapi.emedha.in/api/monthly-purchase');
//               setTodaysPurchase(response.data);  // Set users data from API response
//               setLoading(false);         // Stop loading once data is fetched
//             } catch (err) {
//               setError('Error fetching users');  // Handle errors
//               setLoading(false);
//             }
//           };
//           const fetchMonthlyPurchase = async () => {
//             try {
//               const response = await axios.get('https://posapi.emedha.in/api/yearly-purchase');
//               setMonthlyPurchase(response.data);  // Set users data from API response
//               setLoading(false);         // Stop loading once data is fetched
//             } catch (err) {
//               setError('Error fetching users');  // Handle errors
//               setLoading(false);
//             }
//           };
//           const fetchYearlyPurchase = async () => {
//             try {
//               const response = await axios.get('https://posapi.emedha.in/api/total-products');
//               setYearlyPurchase(response.data);  // Set users data from API response
//               setLoading(false);         // Stop loading once data is fetched
//             } catch (err) {
//               setError('Error fetching users');  // Handle errors
//               setLoading(false);
//             }
//           };
//         //   const fetchTotalProducts = async () => {
//         //     try {
//         //       const response = await axios.get('https://posapi.emedha.in/api//monthly-booking');
//         //       setTotalProducts(response.data);  // Set users data from API response
//         //       setLoading(false);         // Stop loading once data is fetched
//         //     } catch (err) {
//         //       setError('Error fetching users');  // Handle errors
//         //       setLoading(false);
//         //     }
//           //};
//           const fetchUsers = async () => {
//             try {
//               const response = await axios.get('https://posapi.emedha.in/api/users');
//               setMonthlyBookingCount(response.data);  // Set users data from API response
//               setLoading(false);         // Stop loading once data is fetched
//             } catch (err) {
//               setError('Error fetching users');  // Handle errors
//               setLoading(false);
//             }
//           };





//     useEffect(() => {
//         fetchTodaysPurchase()
        
//         fetchMonthlyPurchase();
//         fetchYearlyPurchase();
//         //fetchTotalProducts();
//         fetchYearlyBookingCount();
//         fetchTodaysBookingCount();
//         fetchTodaysBooking();
//         fetchTodaysAmount();
//         fetchMonthlyBookingCount();
//         fetchUsers();
//     }, []);




//     console.log(users)

//     return (
//         <>
//             <Sidebar />
//             <div id="main">




//                 {/* <div className="dashboard-container">
//             <div className="card">
//                 <FaUser className="icon"  style={{fontSize:'40px',color:'#4CAF50',marginBottom:'5px'}}/>
//                 <h3 style={{fontSize:'1.2em',margin:'10px 0'}}>Todays Booking</h3>
//                 <p style={{color:'#777',fontSize:'1em',margin:'0'}}>View and manage your profile.</p>
//             </div>
//             <div className="card">
//                 <FaCog className="icon" style={{fontSize:'40px',color:'#4CAF50',marginBottom:'5px'}}/>
//                 <h3 style={{fontSize:'1.2em',margin:'10px 0'}}>Total today's purchase</h3>
//                 <p style={{color:'#777',fontSize:'1em',margin:'0'}}>Customize your preferences.</p>
//             </div>
//             <div className="card">
//                 <FaChartLine className="icon"  style={{fontSize:'40px',color:'#4CAF50',marginBottom:'5px'}}/>
//                 <h3 style={{fontSize:'1.2em',margin:'10px 0'}}>STOCK</h3>
//                 <p style={{color:'#777',fontSize:'1em',margin:'0'}}>View performance metrics.</p>
//             </div>
//             <div className="card">
//                 <FaRegFileAlt className="icon"  style={{fontSize:'40px',color:'#4CAF50',marginBottom:'5px'}}/>
//                 <h3 style={{fontSize:'1.2em',margin:'10px 0'}}>USERS</h3>
//                 <p style={{color:'#777',fontSize:'1em',margin:'0'}}>Generate and view reports.</p>
//             </div>
            
//         </div> */}

//                 <div className="dashboard-container">
//                     <div className="left-cards">
//                         <div className="card">
//                             <FaUser className="icon" style={{ fontSize: '40px', color: '#4CAF50', marginBottom: '5px' }} />
//                             <h3 style={{ fontSize: '1.2em', margin: '10px 0' }}>Today's Booking</h3>
//                             <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL BOOKINGS:</p>
//                             <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL AMOUNT RS:</p>
//                             <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL BOOKINGS OF THE MONTH:</p>
//                             <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL BOOKINGS OF THE YEAR:</p>

//                         </div>
//                         <div className="card">
//                             <FaChartLine className="icon" style={{ fontSize: '40px', color: '#4CAF50', marginBottom: '5px' }} />
//                             <h3 style={{ fontSize: '1.2em', margin: '10px 0' }}>STOCK</h3>
//                             <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>View performance metrics.</p>
//                         </div>

//                     </div>

//                     <div className="image-container">
//                         <img src={ganeshlogo} alt="Dashboard Image" className="dashboard-image" />
//                     </div>

//                     <div className="right-cards">
//                         <div className="card">
//                             <FaCog className="icon" style={{ fontSize: '40px', color: '#4CAF50', marginBottom: '5px' }} />
//                             <h3 style={{ fontSize: '1.2em', margin: '10px 0' }}>Total Today's Purchase</h3>
//                             <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL: </p>
//                             <p style={{ color: '#777', fontSize: '1em', margin: '0' }}> TOTAL PURCHSES OF THE MONTH:   </p>
//                             <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL PURCHSES OF THE YEAR:    0</p>
//                         </div>

//                         <div className="card">
//                             <FaRegFileAlt className="icon" style={{ fontSize: '40px', color: '#4CAF50', marginBottom: '5px' }} />
//                             <h3 style={{ fontSize: '1.2em', margin: '10px 0' }}>USERS</h3>
//                             {users.map((user) => (
//                                 <div key={user.id}>

//                                     {/* Only one p tag to display Name and Role */}
//                                     <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>
//                                         <strong>Name:</strong> {user.username} <strong>Role:</strong> {user.role}
//                                     </p>
//                                 </div>
//                             ))}
//                         </div>


//                     </div>
//                 </div>



//             </div>
//         </>
//     );
// };

// export default Home;




import React, { useEffect, useState } from 'react';
import './home.css';
import Sidebar from '../Sidebar/Sidebar';
import { FaUser, FaCog, FaChartLine, FaRegFileAlt } from 'react-icons/fa'; // Import icons from react-icons
import ganeshlogo from '../images/ganeshlogo.jpeg';
import axios from 'axios';

const Home = () => {
    const [users, setUsers] = useState([]);
    const [todaysBooking, setTodaysBooking] = useState(0);
    const [todaysAmount, setTodaysAmount] = useState(0);
    const [todaysBookingCount, setTodaysBookingCount] = useState(0);
    const [monthlyBookingCount, setMonthlyBookingCount] = useState(0);
    const [yearlyBookingCount, setYearlyBookingCount] = useState(0);
    const [todaysPurchase, setTodaysPurchase] = useState(0);
    const [monthlyPurchase, setMonthlyPurchase] = useState(0);
    const [yearlyPurchase, setYearlyPurchase] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 

    // API fetch functions
    const fetchTodaysBooking = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/todays-booking');
            setTodaysBooking(response.data.total);  // Set total amount for today's bookings
        } catch (err) {
            setError('Error fetching today\'s booking');
        }
    };

    const fetchTodaysBookingCount = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/count-todays-booking');
            setTodaysBookingCount(response.data.t); // Set today's booking count
        } catch (err) {
            setError('Error fetching today\'s booking count');
        }
    };

    const fetchMonthlyBookingCount = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/monthly-booking');
            setMonthlyBookingCount(response.data.tt); // Set total monthly bookings
        } catch (err) {
            setError('Error fetching monthly booking count');
        }
    };

    const fetchYearlyBookingCount = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/yearly-booking');
            setYearlyBookingCount(response.data.ttt); // Set total yearly bookings
        } catch (err) {
            setError('Error fetching yearly booking count');
        }
    };

    const fetchTodaysPurchase = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/todays-purchase');
            setTodaysPurchase(response.data.total); // Set today's total purchases
        } catch (err) {
            setError('Error fetching today\'s purchase');
        }
    };

    const fetchMonthlyPurchase = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/monthly-purchase');
            setMonthlyPurchase(response.data.mp); // Set monthly purchases
        } catch (err) {
            setError('Error fetching monthly purchase');
        }
    };

    const fetchYearlyPurchase = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/yearly-purchase');
            setYearlyPurchase(response.data.yp); // Set yearly purchases
        } catch (err) {
            setError('Error fetching yearly purchase');
        }
    };

    const fetchTotalProducts = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/total-products');
            setTotalProducts(response.data.t); // Set total products
        } catch (err) {
            setError('Error fetching total products');
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://posapi.emedha.in/api/users');
            setUsers(response.data); // Assuming this returns the user data
        } catch (err) {
            setError('Error fetching users');
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchTodaysBooking();
        fetchTodaysBookingCount();
        fetchMonthlyBookingCount();
        fetchYearlyBookingCount();
        fetchTodaysPurchase();
        fetchMonthlyPurchase();
        fetchYearlyPurchase();
        fetchTotalProducts();
        fetchUsers();
        setLoading(false); // Ensure loading state is stopped after fetching all data
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <>
            <Sidebar />
            <div id="main">
                <div className="dashboard-container">
                    <div className="left-cards">
                        <div className="card">
                            <FaUser className="icon" style={{ fontSize: '40px', color: '#4CAF50', marginBottom: '5px' }} />
                            <h3 style={{ fontSize: '1.2em', margin: '10px 0' }}>Today's Booking</h3>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL BOOKINGS: {todaysBookingCount}</p>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL AMOUNT RS: {todaysBooking}</p>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL BOOKINGS OF THE MONTH: {monthlyBookingCount}</p>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL BOOKINGS OF THE YEAR: {yearlyBookingCount}</p>
                        </div>

                        <div className="card">
                            <FaChartLine className="icon" style={{ fontSize: '40px', color: '#4CAF50', marginBottom: '5px' }} />
                            <h3 style={{ fontSize: '1.2em', margin: '10px 0' }}>STOCK</h3>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>View performance metrics.</p>
                        </div>
                    </div>

                    <div className="image-container">
                        <img src={ganeshlogo} alt="Dashboard Image" className="dashboard-image" />
                    </div>

                    <div className="right-cards">
                        <div className="card">
                            <FaCog className="icon" style={{ fontSize: '40px', color: '#4CAF50', marginBottom: '5px' }} />
                            <h3 style={{ fontSize: '1.2em', margin: '10px 0' }}>Total Today's Purchase</h3>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL: {todaysPurchase}</p>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL PURCHASES OF THE MONTH: {monthlyPurchase}</p>
                            <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>TOTAL PURCHASES OF THE YEAR: {yearlyPurchase}</p>
                        </div>

                        <div className="card">
                            <FaRegFileAlt className="icon" style={{ fontSize: '40px', color: '#4CAF50', marginBottom: '5px' }} />
                            <h3 style={{ fontSize: '1.2em', margin: '10px 0' }}>USERS</h3>
                            {users.map((user) => (
                                <div key={user.id}>
                                    <p style={{ color: '#777', fontSize: '1em', margin: '0' }}>
                                        <strong>Name:</strong> {user.username} <strong>Role:</strong> {user.role}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const Home = () => {
//   // State for today's bookings, purchases, and stock
//   const [todayBooking, setTodayBooking] = useState({});
//   const [monthlyBooking, setMonthlyBooking] = useState({});
//   const [yearlyBooking, setYearlyBooking] = useState({});
//   const [todayPurchase, setTodayPurchase] = useState({});
//   const [monthlyPurchase, setMonthlyPurchase] = useState({});
//   const [yearlyPurchase, setYearlyPurchase] = useState({});
//   const [users, setUsers] = useState([]);

//   // Fetch data on component mount
//   useEffect(() => {
//     // API calls to get data
//     axios.get('http://localhost:3000/api/getTodayBooking').then((response) => setTodayBooking(response.data));
//     axios.get('http://localhost:3000/api/getMonthlyBooking').then((response) => setMonthlyBooking(response.data));
//     axios.get('http://localhost:3000/api/getYearlyBooking').then((response) => setYearlyBooking(response.data));
//     axios.get('http://localhost:3000/api/getTodayPurchase').then((response) => setTodayPurchase(response.data));
//     axios.get('http://localhost:3000/api/getMonthlyPurchase').then((response) => setMonthlyPurchase(response.data));
//     axios.get('http://localhost:3000/api/getYearlyPurchase').then((response) => setYearlyPurchase(response.data));
//     axios.get('/api/getUsers').then((response) => setUsers(response.data));
//   }, []);

//   return (
//     <div className="content-wrapper">
//       <section className="content container-fluid">
//         <div className="row">
//           {/* Today Booking Info */}
//           <div className="col-md-3 col-sm-6 col-xs-12">
//             <div className="info-box">
//               <span className="info-box-icon bg-aqua">
//                 <i className="fa fa-archive"></i>
//               </span>
//               <div className="info-box-content">
//                 <span className="info-box-text">Today's Booking</span>
//                 <span className="info-box-number">
//                   <small>Total Bookings: {todayBooking.totalBookings}</small>
//                 </span>
//                 <span className="info-box-number">
//                   <small>Total Amount: Rs {todayBooking.totalAmount}</small>
//                 </span>
//                 <span className="info-box-number">
//                   <small>Bookings This Month: {monthlyBooking.totalBookings}</small>
//                 </span>
//                 <span className="info-box-number">
//                   <small>Bookings This Year: {yearlyBooking.totalBookings}</small>
//                 </span>
//               </div>
//             </div>
//           </div>

//           {/* Today's Purchase Info */}
//           <div className="col-md-3 col-sm-6 col-xs-12">
//             <div className="info-box">
//               <span className="info-box-icon bg-aqua">
//                 <i className="fa fa-money"></i>
//               </span>
//               <div className="info-box-content">
//                 <span className="info-box-text">Today's Purchases</span>
//                 <span className="info-box-number">
//                   <small>Total Purchases: {todayPurchase.total}</small>
//                 </span>
//                 <span className="info-box-number">
//                   <small>Purchases This Month: {monthlyPurchase.total}</small>
//                 </span>
//                 <span className="info-box-number">
//                   <small>Purchases This Year: {yearlyPurchase.total}</small>
//                 </span>
//               </div>
//             </div>
//           </div>

//           {/* Users Info */}
//           <div className="col-md-3 col-sm-6 col-xs-12">
//             <div className="info-box">
//               <span className="info-box-icon bg-aqua">
//                 <i className="fa fa-users"></i>
//               </span>
//               <div className="info-box-content">
//                 <span className="info-box-text">Users</span>
//                 {users.map((user) => (
//                   <span key={user.id} className="info-box-number">
//                     <small>{user.username} - {user.role}</small>
//                   </span>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Home;
