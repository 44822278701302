// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import swal from 'sweetalert';
// import Sidebar from '../Sidebar/Sidebar';



// const User = () => {
//   const [users, setUsers] = useState([]);
//   const [username, setUsername] = useState('');
//   const [fullname, setFullname] = useState('');
//   const [password, setPassword] = useState('');
//   const [role, setRole] = useState('Admin');

//   // Fetch all users
//   useEffect(() => {
//     axios.get('https://posapi.emedha.in/api/users')
//       .then(response => {
//         setUsers(response.data);
//       })
//       .catch(error => {
//         console.error('There was an error fetching users!', error);
//       });
//   }, []);

//   // Handle user deletion
//   const handleDelete = (userId) => {
//     axios.delete(`https://posapi.emedha.in/api/users/${userId}`)
//       .then(() => {
//         swal('Info', 'User Has Been Deleted', 'info');
//         setUsers(users.filter(user => user.user_id !== userId));
//       })
//       .catch(error => {
//         console.error('There was an error deleting the user!', error);
//       });
//   };

//   // Handle user creation
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     axios.post('https://posapi.emedha.in/api/users', {
//       username,
//       fullname,
//       password,
//       role
//     })
//     .then(() => {
//       swal('Success', 'User added successfully', 'success');
//       setUsers([...users, { username, fullname, role }]);
//     })
//     .catch(error => {
//       swal('Warning', 'Username already exists', 'warning');
//       console.error('Error adding user:', error);
//     });
//   };

//   return (
//     <>
//     <Sidebar/>
//     <div id='main'>
//     <div className="container">
//       <h2>Register a New User</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Username</label>
//           <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
//         </div>
//         <div>
//           <label>Full Name</label>
//           <input type="text" value={fullname} onChange={(e) => setFullname(e.target.value)} required />
//         </div>
//         <div>
//           <label>Password</label>
//           <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
//         </div>
//         <div>
//           <label>Role</label>
//           <select value={role} onChange={(e) => setRole(e.target.value)} required>
//             <option>Admin</option>
//             <option>Operator</option>
//           </select>
//         </div>
//         <button type="submit">Submit</button>
//       </form>

//       <h3>List of Users</h3>
//       <table>
//         <thead>
//           <tr>
//             <th>No</th>
//             <th>Username</th>
//             <th>Full Name</th>
//             <th>Role</th>
//             <th>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {users.map((user, index) => (
//             <tr key={user.user_id}>
//               <td>{index + 1}</td>
//               <td>{user.username}</td>
//               <td>{user.fullname}</td>
//               <td>{user.role}</td>
//               <td>
//                 <button onClick={() => handleDelete(user.user_id)}>Delete</button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//     </div>
//     </>
//   );
// };

// export default User;




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Sidebar from '../Sidebar/Sidebar';

const User = () => {
    const [users, setUsers] = useState([]);
    const [username, setUsername] = useState('');
    const [fullname, setFullname] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('Admin');

    // Fetch all users
    useEffect(() => {
        axios.get('https://posapi.emedha.in/api/users')
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching users!', error);
            });
    }, []);

    // Handle user deletion
    const handleDelete = (userId) => {
        axios.delete(`https://posapi.emedha.in/api/users/${userId}`)
            .then(() => {
                swal('Info', 'User Has Been Deleted', 'info');
                setUsers(users.filter(user => user.user_id !== userId));
            })
            .catch(error => {
                console.error('There was an error deleting the user!', error);
            });
    };

    // Handle user creation
    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post('https://posapi.emedha.in/api/users', {
            username,
            fullname,
            password,
            role
        })
            .then(() => {
                swal('Success', 'User added successfully', 'success');
                setUsers([...users, { username, fullname, role }]);
                setUsername('');
                setFullname('');
                setPassword('');
                setRole('');
            })
            .catch(error => {
                swal('Warning', 'Username already exists', 'warning');
                console.error('Error adding user:', error);
            });
    };

    return (
        <>
            <Sidebar />
            <div id="main">
                <div className="row">


                    <div className="col-lg-10 col-md-9 col-sm-12">
                        <div className="container mt-5">
                            <h2>Register a New User</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">Username</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Full Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={fullname}
                                        onChange={(e) => setFullname(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Role</label>
                                    <select
                                        className="form-select"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        required
                                    >
                                        <option>Admin</option>
                                        <option>Operator</option>
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>

                            <h3 className="mt-5">List of Users</h3>
                            {/* <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Username</th>
                    <th>Full Name</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={user.user_id}>
                      <td>{index + 1}</td>
                      <td>{user.username}</td>
                      <td>{user.fullname}</td>
                      <td>{user.role}</td>
                      <td>
                        <button
                          onClick={() => handleDelete(user.user_id)}
                          className="btn btn-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Username</th>
                                            <th>Full Name</th>
                                            <th>Role</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user, index) => (
                                            <tr key={user.user_id}>
                                                <td>{index + 1}</td>
                                                <td>{user.username}</td>
                                                <td>{user.fullname}</td>
                                                <td>{user.role}</td>
                                                <td>
                                                    <button
                                                        onClick={() => handleDelete(user.user_id)}
                                                        className="btn btn-danger"
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default User;
